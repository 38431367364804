export default {
  navlinks: [
    {
      label: "Nav Link 1",
      checkbox: true,
      field: {
        Eng: "Products",
        Span: "Productos",
        ChS: "产品",
        Gr: "Produkte",
        Ru: "Продукция",
        Fr: "Produits",
        It: "Prodotti",
        Pl: "Produkty",
        Port: "Produtos",
        Viet: "Sản phẩm",
        Turk: "Ürünler",
        Ukrn: "Продукція",
        Thai: "ผลิตภัณฑ์",
        Kor: "제품",
        ChT: "產品",
        Jp: "製品",
      },
      block: 0,
    },
    {
      label: "Nav Link 2",
      checkbox: true,
      field: {
        Eng: "Solutions",
        Span: "Soluciones",
        ChS: "解决方案",
        Gr: "Lösungen",
        Ru: "Решения",
        Fr: "Solutions",
        It: "Soluzioni",
        Pl: "Rozwiązania",
        Port: "Soluções",
        Viet: "Giải pháp",
        Turk: "Çözümler",
        Ukrn: "Рішення",
        Thai: "ผลิตภัณฑ์และบริการ",
        Kor: "솔루션",
        ChT: "解決方案",
        Jp: "解決策",
      },
      block: 0,
    },
    {
      label: "Nav Link 3",
      checkbox: true,
      field: {
        Eng: "Support",
        Span: "Asistencia técnica",
        ChS: "技术支持",
        Gr: "Support",
        Ru: "Поддержка",
        Fr: "Assistance",
        It: "Supporto",
        Pl: "Pomoc techniczna",
        Port: "Suporte",
        Viet: "Hỗ trợ",
        Turk: "Destek",
        Ukrn: "Підтримка",
        Thai: "รองรับ",
        Kor: "지원",
        ChT: "技術支援",
        Jp: "サポート",
      },
      block: 2,
    },
    {
      label: "Nav Link 4",
      checkbox: true,
      field: {
        Eng: "Company",
        // Span: "Compañía",
        Span: "Empresa",
        ChS: "公司",
        Gr: "Firma",
        Ru: "Организация",
        Fr: "Entreprise",
        It: "Azienda",
        Pl: "Firma",
        Port: "Empresa",
        Viet: "Công ty",
        Turk: "Şirket",
        Ukrn: "Компанія",
        Thai: "บริษัท",
        Kor: "회사",
        ChT: "公司",
        Jp: "企業情報",
      },
      block: 0,
    },
    {
      label: "Nav Link 5",
      checkbox: true,
      field: {
        Eng: "Blog",
        Span: "Blog",
        ChS: "博客",
        Gr: "Blog",
        Ru: "Блог",
        Fr: "Blog",
        It: "Blog",
        Pl: "Blog",
        Port: "Blogue",
        Viet: "Blog",
        Turk: "Blog",
        Ukrn: "Блог",
        Thai: "บล็อก",
        Kor: "블로그",
        ChT: "部落格",
        Jp: "ブログ",
      },
      block: 0,
    },
    {
      label: "Nav Link 6",
      checkbox: false,
      field: {
        Eng: "Loremfdsfafdsfsfsfs",
        Span: "Enlace",
        ChS: "关联",
        Gr: "Verknüpfung",
        Ru: "Блог",
        Fr: "Lien",
        It: "Collegamento",
        Pl: "Połączyć",
        Port: "Link",
        Viet: "liên kết",
        Turk: "Bağlantı",
        Ukrn: "Посилання",
        Thai: "ลิงค์",
        Kor: "링크",
        ChT: "關聯",
        Jp: "リンク",
      },
      block: 0,
    },
  ],

  openpanel: {
    label: "Open Panel",
    checkbox: null,
    field: [
      "Products",
      "Solutions",
      "Support",
      "Company",
      "Blog",
      "Other",
      "Search",
      "Languages",
      "Cart",
      "None",
    ],
    selected: "None",
  },

  numcategories: {
    label: "Number of Categories",
    checkbox: null,
    field: [1, 2, 3, 4, 5, 6, 10],
    selected: 6,
  },

  memory: {
    label: "Number of Memory",
    checkbox: null,
    field: [1, 2, 3, 4, 5, 6, 7, 8],
    selected: 6,
  },
  memorylinks: [
    {
      label: "Memory Category",
      checkbox: null,
      field: {
        Eng: "Memory",
        Span: "Memoria de escritorio/portátil",
        ChS: "台式机/笔记本电脑内存",
        Gr: "Desktop-/Laptop-Speicher",
        Ru: "Память настольного компьютера/ноутбука",
        Fr: "Mémoire de bureau/ordinateur portable",
        It: "Memoria del desktop/portatile",
        Pl: "Pamięć komputera stacjonarnego/laptopa",
        Port: "Memória de desktop/laptop",
        Viet: "Bộ nhớ máy tính để bàn/máy tính xách tay",
        Turk: "Masaüstü/Dizüstü belleği",
        Ukrn: "Пам'ять настільного комп'ютера/ноутбука",
        Thai: "หน่วยความจำเดสก์ท็อป/แล็ปท็อป",
        Kor: "데스크탑/노트북 메모리",
        ChT: "桌上型電腦/筆記型電腦內存",
        Jp: "デスクトップ/ラップトップのメモリ",
      },
      block: 1,
    },
    {
      label: "Memory Link 1",
      checkbox: null,
      field: {
        Eng: "Desktop/Laptop memory",
        Span: "Memoria de escritorio/portátil",
        ChS: "台式机/笔记本电脑内存",
        Gr: "Desktop-/Laptop-Speicher",
        Ru: "Память настольного компьютера/ноутбука",
        Fr: "Mémoire de bureau/ordinateur portable",
        It: "Memoria del desktop/portatile",
        Pl: "Pamięć komputera stacjonarnego/laptopa",
        Port: "Memória de desktop/laptop",
        Viet: "Bộ nhớ máy tính để bàn/máy tính xách tay",
        Turk: "Masaüstü/Dizüstü belleği",
        Ukrn: "Пам'ять настільного комп'ютера/ноутбука",
        Thai: "หน่วยความจำเดสก์ท็อป/แล็ปท็อป",
        Kor: "데스크탑/노트북 메모리",
        ChT: "桌上型電腦/筆記型電腦內存",
        Jp: "デスクトップ/ラップトップのメモリ",
      },
      block: 1,
    },
    {
      label: "Memory Link 2",
      checkbox: null,
      field: {
        Eng: "Server memory",
        Span: "Memoria del servidor",
        ChS: "服务器内存",
        Gr: "Serverspeicher",
        Ru: "Серверная память",
        Fr: "Mémoire du serveur",
        It: "Memoria del server",
        Pl: "Pamięć serwera",
        Port: "Memória do servidor",
        Viet: "Bộ nhớ máy chủ",
        Turk: "Sunucu belleği",
        Ukrn: "Пам'ять сервера",
        Thai: "หน่วยความจำเซิร์ฟเวอร์",
        Kor: "서버 메모리",
        ChT: "伺服器記憶體",
        Jp: "サーバーメモリ",
      },
      block: 1,
    },
    {
      label: "Memory Link 3",
      checkbox: null,
      field: {
        Eng: "High-performance memory",
        Span: "Memoria de alto rendimiento",
        ChS: "高性能/游戏内存",
        Gr: "Hochleistungs-Speicher",
        Ru: "Высокопроизводительная",
        Fr: "Mémoire haute performance",
        It: "Memoria ad alte prestazioni",
        Pl: "Pamięć o wysokiej wydajności/do gier",
        Port: "Memória de alto desempenho",
        Viet: "Bộ nhớ hiệu suất cao",
        Turk: "Yüksek performanslı bellek",
        Ukrn: "Високопродуктивна пам'ять",
        Thai: "หน่วยความจำประสิทธิภาพสูง",
        Kor: "고성능 메모리",
        ChT: "高效能記憶體",
        Jp: "高性能メモリ",
      },
      block: 1,
    },
    {
      label: "Memory Link 4",
      checkbox: null,
      field: {
        Eng: "Design-in DRAM modules",
        Span: "Módulos DRAM de diseño",
        ChS: "设计内置 DRAM 模块",
        Gr: "Design-in-DRAM-Module",
        Ru: "Встроенные модули DRAM",
        Fr: "Modules DRAM intégrés",
        It: "Moduli DRAM integrati",
        Pl: "Zaprojektowane moduły DRAM",
        Port: "Módulos DRAM integrados",
        Viet: "Các mô-đun DRAM thiết kế sẵn",
        Turk: "Tasarımda DRAM modülleri",
        Ukrn: "Проектування модулів DRAM",
        Thai: "การออกแบบโมดูล DRAM",
        Kor: "디자인 인 DRAM 모듈",
        ChT: "設計內建 DRAM 模組",
        Jp: "デザインインDRAMモジュール",
      },
      block: 1,
    },
    {
      label: "Memory Link 5",
      checkbox: null,
      field: {
        Eng: "System-specific memory",
        Span: "Memoria específica del sistema",
        ChS: "系统特定内存",
        Gr: "Systemspezifischer Speicher",
        Ru: "Системная память",
        Fr: "Mémoire spécifique au système",
        It: "Memoria specifica del sistema",
        Pl: "Pamięć specyficzna dla systemu",
        Port: "Memória específica do sistema",
        Viet: "Bộ nhớ dành riêng cho hệ thống",
        Turk: "Sisteme özel bellek",
        Ukrn: "Системна пам'ять",
        Thai: "หน่วยความจำเฉพาะระบบ",
        Kor: "시스템별 메모리",
        ChT: "系統特定記憶體",
        Jp: "システム固有のメモリ",
      },
      block: 1,
    },
    {
      label: "Memory Link 6",
      checkbox: null,
      field: {
        Eng: "Memory overview",
        Span: "Descripción general de la memoria",
        ChS: "内存概览",
        Gr: "Speicherübersicht",
        Ru: "Обзор памяти",
        Fr: "Aperçu de la mémoire",
        It: "Panoramica della memoria",
        Pl: "Przegląd pamięci",
        Port: "Visão geral da memória",
        Viet: "Tổng quan về bộ nhớ",
        Turk: "Belleğe genel bakış",
        Ukrn: "Огляд пам'яті",
        Thai: "ดภาพรวมของหน่วยความจำ",
        Kor: "메모리 개요",
        ChT: "記憶體概覽",
        Jp: "メモリの概要",
      },
      block: 1,
    },
  ],
  memorycards: {
    label: "Number of Memory cards",
    checkbox: null,
    field: [1, 2, 3, 4, 5, 6, 7, 8],
    selected: 4,
  },
  memorycardlinks: [
    {
      label: "Memory Cards Category",
      checkbox: null,
      field: {
        Eng: "Memory cards",
        Span: "Tarjetas de memoria",
        ChS: "存储卡",
        Gr: "Speicherkarten",
        Ru: "Карты памяти",
        Fr: "Cartes mémoire",
        It: "Schede di memoria",
        Pl: "Karty pamięci",
        Port: "Cartões de memória",
        Viet: "Thẻ nhớ",
        Turk: "Hafıza kartları",
        Ukrn: "Карти пам'яті",
        Thai: "การ์ดหน่วยความจำ",
        Kor: "메모리 카드",
        ChT: "記憶卡",
        Jp: "メモリーカード",
      },
      block: 2,
    },
    {
      label: "Memory Card Link 1",
      checkbox: null,
      field: {
        Eng: "SD cards",
        Span: "Tarjetas SD",
        ChS: "SD卡",
        Gr: "SD-Karten",
        Ru: "SD-карты",
        Fr: "Cartes SD",
        It: "Schede SD",
        Pl: "Karty SD",
        Port: "Cartões SD",
        Viet: "thẻ SD",
        Turk: "SD kartlar",
        Ukrn: "SD карти",
        Thai: "การ์ด SD",
        Kor: "SD 카드",
        ChT: "SD卡",
        Jp: "SDカード",
      },
      block: 2,
    },
    {
      label: "Memory Card Link 2",
      checkbox: null,
      field: {
        Eng: "MicroSD cards",
        Span: "Tarjetas microSD",
        ChS: "MicroSD卡",
        Gr: "MicroSD-Karten",
        Ru: "Карты MicroSD",
        Fr: "Cartes MicroSD",
        It: "Schede MicroSD",
        Pl: "Karty microSD",
        Port: "Cartões MicroSD",
        Viet: "Thẻ MicroSD",
        Turk: "MikroSD kartlar",
        Ukrn: "Карти MicroSD",
        Thai: "การ์ดไมโครเอสดี",
        Kor: "MicroSD 카드",
        ChT: "MicroSD卡",
        Jp: "microSDカード",
      },
      block: 2,
    },
    {
      label: "Memory Card Link 3",
      checkbox: null,
      field: {
        Eng: "Industrial temp cards",
        Span: "Tarjetas temporales industriales",
        ChS: "工业临时卡",
        Gr: "Industrielle temporäre Karten",
        Ru: "Промышленные временные карты",
        Fr: "Cartes temporaires industrielles",
        It: "Carte temporanee industriali",
        Pl: "Przemysłowe karty tymczasowe",
        Port: "Cartões temporários industriais",
        Viet: "Thẻ tạm thời công nghiệp",
        Turk: "Endüstriyel geçici kartlar",
        Ukrn: "Промислові тимчасові карти",
        Thai: "การ์ดอุณหภูมิอุตสาหกรรม",
        Kor: "산업용 임시 카드",
        ChT: "工業臨時卡",
        Jp: "工業用一時カード",
      },
      block: 2,
    },
    {
      label: "Memory Card Link 4",
      checkbox: null,
      field: {
        Eng: "All cards",
        Span: "Todas las tarjetas",
        ChS: "所有卡",
        Gr: "Alle Karten",
        Ru: "Все карты",
        Fr: "Toutes les cartes",
        It: "Tutte le carte",
        Pl: "Wszystkie karty",
        Port: "Todos os cartões",
        Viet: "Tất cả các thẻ",
        Turk: "Tüm kartlar",
        Ukrn: "Всі картки",
        Thai: "การ์ดทั้งหมด",
        Kor: "모든 카드",
        ChT: "所有卡",
        Jp: "すべてのカード",
      },
      block: 2,
    },
  ],
  ssds: {
    label: "Number of SSDs",
    checkbox: null,
    field: [1, 2, 3, 4, 5, 6, 7, 8],
    selected: 7,
  },
  ssdslinks: [
    {
      label: "SSDs Category",
      checkbox: null,
      field: {
        Eng: "SSDs",
        Span: "SSD",
        ChS: "SSD",
        Gr: "SSDs",
        Ru: "SSD",
        Fr: "SSD",
        It: "SSD",
        Pl: "SSD",
        Port: "SSDs",
        Viet: "SSD",
        Turk: "SSD",
        Ukrn: "SSD",
        Thai: "SSD",
        Kor: "SSD",
        ChT: "SSD",
        Jp: "SSSD",
      },
      block: 3,
    },
    {
      label: "SSDs Link 1",
      checkbox: null,
      field: {
        Eng: "Desktop/Laptop SSDs",
        Span: "SSD de escritorio/portátil",
        ChS: "台式机/笔记本电脑 SSD",
        Gr: "Desktop-/Laptop-SSDs",
        Ru: "SSD-накопители для настольных компьютеров/ноутбуков",
        Fr: "SSD de bureau/ordinateur portable",
        It: "SSD per desktop/laptop",
        Pl: "Dyski SSD do komputerów stacjonarnych/laptopów",
        Port: "SSDs para desktops/laptops",
        Viet: "SSD máy tính để bàn/máy tính xách tay",
        Turk: "Masaüstü/Dizüstü Bilgisayar SSD'leri",
        Ukrn: "Настільні/ноутбук SSD",
        Thai: "SSD เดสก์ท็อป/แล็ปท็อป",
        Kor: "데스크탑/노트북 SSD",
        ChT: "桌上型電腦/筆記型電腦 SSD",
        Jp: "デスクトップ/ラップトップ SSD",
      },
      block: 3,
    },
    {
      label: "SSDs Link 2",
      checkbox: null,
      field: {
        Eng: "Server SSDs",
        Span: "SSD de servidor",
        ChS: "服务器SSD",
        Gr: "Server-SSDs",
        Ru: "Серверные твердотельные накопители",
        Fr: "SSD de serveur",
        It: "SSD del server",
        Pl: "Serwerowe dyski SSD",
        Port: "SSDs de servidor",
        Viet: "SSD máy chủ",
        Turk: "Sunucu SSD'leri",
        Ukrn: "Серверні SSD",
        Thai: "เซิร์ฟเวอร์ SSD",
        Kor: "서버 SSD",
        ChT: "伺服器SSD",
        Jp: "高性能SSD",
      },
      block: 3,
    },
    {
      label: "SSDs Link 3",
      checkbox: null,
      field: {
        Eng: "High-performance SSDs",
        Span: "SSD de alto rendimiento",
        ChS: "高性能SSD",
        Gr: "Hochleistungs-SSDs",
        Ru: "Высокопроизводительные твердотельные накопители",
        Fr: "SSD hautes performances",
        It: "SSD ad alte prestazioni",
        Pl: "Wysokowydajne dyski SSD",
        Port: "SSDs de alto desempenho",
        Viet: "SSD hiệu suất cao",
        Turk: "Yüksek performanslı SSD'ler",
        Ukrn: "Високопродуктивні SSD",
        Thai: "SSD ประสิทธิภาพสูง",
        Kor: "고성능 SSD",
        ChT: "高效能SSD",
        Jp: "高性能/ゲーミングSSD",
      },
      block: 3,
    },
    {
      label: "SSDs Link 4",
      checkbox: null,
      field: {
        Eng: "Encrypted SSDs",
        Span: "SSD cifrados",
        ChS: "加密 SSD",
        Gr: "Verschlüsselte SSDs",
        Ru: "Зашифрованные твердотельные накопители",
        Fr: "SSD cryptés",
        It: "SSD crittografati",
        Pl: "Szyfrowane dyski SSD",
        Port: "SSDs criptografados",
        Viet: "SSD được mã hóa",
        Turk: "Şifrelenmiş SSD'ler",
        Ukrn: "Зашифровані SSD",
        Thai: "SSD ที่เข้ารหัส",
        Kor: "암호화된 SSD",
        ChT: "加密 SSD",
        Jp: "暗号化されたSSD",
      },
      block: 3,
    },
    {
      label: "SSDs Link 5",
      checkbox: null,
      field: {
        Eng: "External SSDs",
        Span: "SSD externos",
        ChS: "外部 SSD",
        Gr: "Externe SSDs",
        Ru: "Внешние твердотельные накопители",
        Fr: "SSD externes",
        It: "SSD esterni",
        Pl: "Zewnętrzne dyski SSD",
        Port: "SSDs externos",
        Viet: "SSD ngoài",
        Turk: "Harici SSD'ler",
        Ukrn: "Зовнішні SSD",
        Thai: "SSD ภายนอก",
        Kor: "외장 SSD",
        ChT: "外部 SSD",
        Jp: "外付けSSD",
      },
      block: 3,
    },
    {
      label: "SSDs Link 6",
      checkbox: null,
      field: {
        Eng: "Industrial SSDs",
        Span: "SSD industriales",
        ChS: "工业SSD",
        Gr: "Industrielle SSDs",
        Ru: "Промышленные твердотельные накопители",
        Fr: "SSD industriels",
        It: "SSD industriali",
        Pl: "Przemysłowe dyski SSD",
        Port: "SSDs industriais",
        Viet: "SSD công nghiệp",
        Turk: "Endüstriyel SSD'ler",
        Ukrn: "Промислові SSD",
        Thai: "SSD อุตสาหกรรม",
        Kor: "산업용 SSD",
        ChT: "工業SSD",
        Jp: "産業用SSD",
      },
      block: 3,
    },
    {
      label: "SSDs Link 7",
      checkbox: null,
      field: {
        Eng: "All solid-state drives",
        Span: "Todas las unidades de estado sólido",
        ChS: "所有固态硬盘",
        Gr: "Alle Solid-State-Laufwerke",
        Ru: "Все твердотельные накопители",
        Fr: "Tous les disques SSD",
        It: "Tutte le unità a stato solido",
        Pl: "Wszystkie dyski półprzewodnikowe",
        Port: "Todas as unidades de estado sólido",
        Viet: "Tất cả các ổ đĩa thể rắn",
        Turk: "Tüm katı hal sürücüleri",
        Ukrn: "Усі твердотільні накопичувачі",
        Thai: "ไดรฟ์โซลิดสเตตทั้งหมด",
        Kor: "모든 솔리드 스테이트 드라이브",
        ChT: "所有固態硬碟",
        Jp: "すべてのソリッド ステート ドライブ",
      },
      block: 3,
    },
  ],
  readers: {
    label: "Number of Readers",
    checkbox: null,
    field: [1, 2, 3, 4, 5, 6, 7, 8],
    selected: 3,
  },
  readerlinks: [
    {
      label: "Readers Category",
      checkbox: null,
      field: {
        Eng: "Readers",
        Span: "Lectores",
        ChS: "读者",
        Gr: "Leser",
        Ru: "Читатели",
        Fr: "Lecteurs",
        It: "Lettori",
        Pl: "Czytelnicy",
        Port: "Leitores",
        Viet: "Độc giả",
        Turk: "Okuyucular",
        Ukrn: "Читачі",
        Thai: "ผู้อ่าน",
        Kor: "독자",
        ChT: "讀者",
        Jp: "読者",
      },
      block: 4,
    },
    {
      label: "Readers Link 1",
      checkbox: null,
      field: {
        Eng: "USB-A",
        Span: "USB-A",
        ChS: "USB-A",
        Gr: "USB-A",
        Ru: "USB-A",
        Fr: "USB-A",
        It: "USB-A",
        Pl: "USB-A",
        Port: "USB-A",
        Viet: "USB-A",
        Turk: "USB-A",
        Ukrn: "USB-A",
        Thai: "USB-A",
        Kor: "USB-A",
        ChT: "USB-A",
        Jp: "USB-A",
      },
      block: 4,
    },
    {
      label: "Readers Link 2",
      checkbox: null,
      field: {
        Eng: "USB-C",
        Span: "USB-C",
        ChS: "USB-C",
        Gr: "USB-C",
        Ru: "USB-C",
        Fr: "USB-C",
        It: "USB-C",
        Pl: "USB-C",
        Port: "USB-C",
        Viet: "USB-C",
        Turk: "USB-C",
        Ukrn: "USB-C",
        Thai: "USB-C",
        Kor: "USB-C",
        ChT: "USB-C",
        Jp: "USB-C",
      },
      block: 4,
    },
    {
      label: "Readers Link 3",
      checkbox: null,
      field: {
        Eng: "All readers",
        Span: "Todos los lectores",
        ChS: "所有读者",
        Gr: "Alle Leser",
        Ru: "Все читатели",
        Fr: "Tous les lecteurs",
        It: "Tutti i lettori",
        Pl: "Wszyscy czytelnicy",
        Port: "Todos os leitores",
        Viet: "Tất cả độc giả",
        Turk: "Tüm okuyucular",
        Ukrn: "Всі читачі",
        Thai: "ผู้อ่านทุกท่าน",
        Kor: "모든 독자",
        ChT: "所有讀者",
        Jp: "読者の皆様",
      },
      block: 4,
    },
  ],
  usbstorage: {
    label: "Number of USB storage",
    checkbox: null,
    field: [1, 2, 3, 4, 5, 6, 7, 8],
    selected: 2,
  },
  usbstoragelinks: [
    {
      label: "USB Storage Category",
      checkbox: null,
      field: {
        Eng: "USB Storage",
        Span: "almacenamiento USB",
        ChS: "USB存储",
        Gr: "USB Speicher",
        Ru: "флешка",
        Fr: "stockage USB",
        It: "Memoria USB",
        Pl: "Pamięć USB",
        Port: "armazenamento USB",
        Viet: "Bộ lưu trữ USB",
        Turk: "USB depolama",
        Ukrn: "USB накопичувач",
        Thai: "ที่เก็บข้อมูลยูเอสบี",
        Kor: "USB 저장소",
        ChT: "USB儲存",
        Jp: "USBストレージ",
      },
      block: 5,
    },
    {
      label: "USB Storage Link 1",
      checkbox: null,
      field: {
        Eng: "USB flash drives",
        Span: "unidades flash USB",
        ChS: "USB 闪存驱动器",
        Gr: "USB-Sticks",
        Ru: "USB-флешки",
        Fr: "Clés USB",
        It: "Unità flash USB",
        Pl: "Pamięci flash USB",
        Port: "Unidades flash USB",
        Viet: "Ổ đĩa flash USB",
        Turk: "USB flash sürücüler",
        Ukrn: "USB флешки",
        Thai: "แฟลชไดรฟ์ USB",
        Kor: "USB 플래시 드라이브",
        ChT: "USB 隨身碟",
        Jp: "USBフラッシュドライブ",
      },
      block: 5,
    },
    {
      label: "USB Storage Link 2",
      checkbox: null,
      field: {
        Eng: "Encrypted drives",
        Span: "Unidades cifradas",
        ChS: "加密驱动器",
        Gr: "Verschlüsselte Laufwerke",
        Ru: "Зашифрованные диски",
        Fr: "Disques chiffrés",
        It: "Unità crittografate",
        Pl: "Szyfrowane dyski",
        Port: "Unidades criptografadas",
        Viet: "Ổ đĩa được mã hóa",
        Turk: "Şifreli sürücüler",
        Ukrn: "Зашифровані диски",
        Thai: "ไดรฟ์ที่เข้ารหัส",
        Kor: "암호화된 드라이브",
        ChT: "加密磁碟機",
        Jp: "暗号化されたドライブ",
      },
      block: 5,
    },
  ],
  embeddedindustrial: {
    label: "Number of Embed/Indust",
    checkbox: null,
    field: [1, 2, 3, 4, 5, 6, 7, 8],
    selected: 8,
  },
  embeddedindustriallinks: [
    {
      label: "Embed/Indust Category",
      checkbox: null,
      field: {
        Eng: "Embedded / Industrial",
        Span: "Integrado / Industrial",
        ChS: "嵌入式/工业",
        Gr: "Eingebettet / Industriell",
        Ru: "Встраиваемый/Промышленный",
        Fr: "Embarqué / Industriel",
        It: "Incorporato/Industriale",
        Pl: "Wbudowane / przemysłowe",
        Port: "Incorporado / Industrial",
        Viet: "Nhúng / Công nghiệp",
        Turk: "Gömülü / Endüstriyel",
        Ukrn: "Вбудовувані / промислові",
        Thai: "ฝังตัว / อุตสาหกรรม",
        Kor: "임베디드/산업용",
        ChT: "嵌入式/工業",
        Jp: "組み込み / 産業用",
      },
      block: 6,
    },
    {
      label: "Embed/Indust Link 1",
      checkbox: null,
      field: {
        Eng: "eMMC",
        Span: "eMMC",
        ChS: "eMMC",
        Gr: "eMMC",
        Ru: "eMMC",
        Fr: "eMMC",
        It: "eMMC",
        Pl: "eMMC",
        Port: "eMMC",
        Viet: "eMMC",
        Turk: "eMMC",
        Ukrn: "eMMC",
        Thai: "eMMC",
        Kor: "eMMC",
        ChT: "eMMC",
        Jp: "eMMC",
      },
      block: 6,
    },
    {
      label: "Embed/Indust Link 2",
      checkbox: null,
      field: {
        Eng: "eMCP",
        Span: "eMCP",
        ChS: "eMCP",
        Gr: "eMCP",
        Ru: "eMCP",
        Fr: "eMCP",
        It: "eMCP",
        Pl: "eMCP",
        Port: "eMCP",
        Viet: "eMCP",
        Turk: "eMCP",
        Ukrn: "eMCP",
        Thai: "eMCP",
        Kor: "eMCP",
        ChT: "eMCP",
        Jp: "eMCP",
      },
      block: 6,
    },
    {
      label: "Embed/Indust Link 3",
      checkbox: null,
      field: {
        Eng: "ePoP",
        Span: "ePoP",
        ChS: "ePoP",
        Gr: "ePoP",
        Ru: "ePoP",
        Fr: "ePoP",
        It: "ePoP",
        Pl: "ePoP",
        Port: "ePoP",
        Viet: "ePoP",
        Turk: "ePoP",
        Ukrn: "ePoP",
        Thai: "ePoP",
        Kor: "ePoP",
        ChT: "ePoP",
        Jp: "ePoP",
      },
      block: 6,
    },
    {
      label: "Embed/Indust Link 4",
      checkbox: null,
      field: {
        Eng: "DRAM components",
        Span: "Componentes de memoria RAM",
        ChS: "动态随机存取存储器组件",
        Gr: "DRAM-Komponenten",
        Ru: "Компоненты DRAM",
        Fr: "Composants DRAM",
        It: "Componenti DRAM",
        Pl: "Komponenty DRAM-u",
        Port: "Componentes DRAM",
        Viet: "Linh kiện DRAM",
        Turk: "DRAM bileşenleri",
        Ukrn: "Компоненти DRAM",
        Thai: "ส่วนประกอบ DRAM",
        Kor: "DRAM 부품",
        ChT: "動態隨機存取記憶體組件",
        Jp: "DRAM コンポーネント",
      },
      block: 6,
    },
    {
      label: "Embed/Indust Link 5",
      checkbox: null,
      field: {
        Eng: "UFS",
        Span: "UFS",
        ChS: "UFS",
        Gr: "UFS",
        Ru: "UFS",
        Fr: "UFS",
        It: "UFS",
        Pl: "UFS",
        Port: "UFS",
        Viet: "UFS",
        Turk: "UFS",
        Ukrn: "UFS",
        Thai: "UFS",
        Kor: "UFS",
        ChT: "UFS",
        Jp: "UFS",
      },
      block: 6,
    },
    {
      label: "Embed/Indust Link 6",
      checkbox: null,
      field: {
        Eng: "Design-in DRAM modules",
        Span: "Módulos DRAM de diseño",
        ChS: "设计内置 DRAM 模块",
        Gr: "Design-in-DRAM-Module",
        Ru: "Встроенные модули DRAM",
        Fr: "Modules DRAM intégrés",
        It: "Moduli DRAM integrati",
        Pl: "Zaprojektowane moduły DRAM",
        Port: "Módulos DRAM integrados",
        Viet: "Các mô-đun DRAM thiết kế sẵn",
        Turk: "Tasarımda DRAM modülleri",
        Ukrn: "Проектування модулів DRAM",
        Thai: "การออกแบบโมดูล DRAM",
        Kor: "디자인 인 DRAM 모듈",
        ChT: "設計內建 DRAM 模組",
        Jp: "デザインインDRAMモジュール",
      },
      block: 6,
    },
    {
      label: "Embed/Indust Link 7",
      checkbox: null,
      field: {
        Eng: "Industrial temp cards",
        Span: "Tarjetas temporales industriales",
        ChS: "工业临时卡",
        Gr: "Industrielle temporäre Karten",
        Ru: "Промышленные временные карты",
        Fr: "Cartes temporaires industrielles",
        It: "Carte temporanee industriali",
        Pl: "Przemysłowe karty tymczasowe",
        Port: "Cartões temporários industriais",
        Viet: "Thẻ tạm thời công nghiệp",
        Turk: "Endüstriyel geçici kartlar",
        Ukrn: "Промислові тимчасові карти",
        Thai: "การ์ดอุณหภูมิอุตสาหกรรม",
        Kor: "산업용 임시 카드",
        ChT: "工業臨時卡",
        Jp: "工業用一時カード",
      },
      block: 6,
    },
    {
      label: "Embed/Indust Link 8",
      checkbox: null,
      field: {
        Eng: "All embedded solutions",
        Span: "Todas las soluciones integradas",
        ChS: "所有嵌入式解决方案",
        Gr: "Alle eingebetteten Lösungen",
        Ru: "Все встраиваемые решения",
        Fr: "Toutes les solutions embarquées",
        It: "Tutte le soluzioni embedded",
        Pl: "Wszystkie rozwiązania wbudowane",
        Port: "Todas as soluções incorporadas",
        Viet: "Tất cả các giải pháp nhúng",
        Turk: "Tüm gömülü çözümler",
        Ukrn: "Усі вбудовані рішення",
        Thai: "โซลูชั่นแบบฝังทั้งหมด",
        Kor: "모든 임베디드 솔루션",
        ChT: "所有嵌入式解決方案",
        Jp: "すべての組み込みソリューション",
      },
      block: 6,
    },
  ],
  suggestedLinks: {
    label: "Enable Suggested Links",
    checkbox: true,
    field: null,
  },
  cart: {
    label: "Enable Cart",
    checkbox: true,
    field: null,
  },
  numberOfProducts: {
    label: "Number of Products",
    checkbox: null,
    field: [0, 1, 2, 3, 4, 5, 6],
    selected: 6,
  },
  cartcopy: {
    label: "Empty Cart Copy",
    checkbox: null,
    field: {
      Eng: "Your cart is empty",
      Span: "Tu carrito esta vacío",
      ChS: "您的购物车是空的",
      Gr: "Ihr Warenkorb ist leer",
      Ru: "Ваша корзина пуста",
      Fr: "Votre panier est vide",
      It: "il tuo carrello è vuoto",
      Pl: "Twój koszyk jest pusty",
      Port: "Seu carrinho está vazio",
      Viet: "Giỏ của bạn trống trơn",
      Turk: "Sepetiniz boş",
      Ukrn: "Ваш кошик порожній",
      Thai: "รถเข็นของคุณว่างเปล่า",
      Kor: "장바구니가 비어 있습니다.",
      ChT: "您的購物車是空的",
      Jp: "あなたのカートは空です",
    },
  },
  vat: {
    label: "Display VAT",
    checkbox: true,
    field: null,
  },
  apple: {
    label: "Enable ApplePay",
    checkbox: true,
    field: null,
  },
  currentLang: "Eng",
};
