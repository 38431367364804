/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useContext, useEffect, useRef } from "react";
import { AppContext } from "../../../../AppContext";
import megaMenuNavOpts from "./megaMenuNavOpts";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
// import C_CartItem from "../../2_components/cartItem/C_CartItem";

const S_MegaMenuNavigation = () => {
  // These are needed for all sections
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const [activeMainContainer, setActiveMainContainer] = useState(false);
  const [activeMainLink, setActiveMainLink] = useState("");

  const [activeSubContainer, setActiveSubContainer] = useState(false);
  const [activeSubLink, setActiveSubLink] = useState("");

  const [activeDesktopHover, setDesktopHover] = useState(false);
  const [activeDesktopKeyDown, setDesktopKeyDown] = useState(false);

  const [activeSideMenu, setActiveSideMenu] = useState(false);

  // ref the scroller
  const searchRef = useRef();
  const [activeSearch, setSearch] = useState(false);

  const [searchResults, setSearchResultsOpen] = useState(false);

  const [recentSearch1, setRecentSearch1] = useState(true);
  const [recentSearch2, setRecentSearch2] = useState(true);

  const [activeLanguage, setActiveLanguage] = useState("");

  const [blurOn, setBlurOn] = useState(false);

  useEffect(() => {
    let megaMenuNavigation = {
      ...appState,
      currentPath: "/ui/mega-menu-navigation",
      outputName: "MegaMenuNavigation",
      headerName: "Mega Menu Navigation",
      tags: null,
      description:
        "The Navigation Section organizes a list of links to other web pages. Note: To test search, type 'e' to show matched results.",
      related: null,
      htmlSaved: true,
      currentTheme: null,
      // hideView: "edit",
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/quantityCounter.component.js",
        "megaMenuNavigation.section.js",
        "../../js/inPageNav.component.js",

        "../../js/cuid.component.js",
        "../../js/quantityCounter.component.js",
        "../../js/dropDown.component.js",
        "../../js/accordion.component.js",
        "../../js/slideMenu.component.js",
        "../../js/tabs.layout.js",
        "../../js/productGallery3.section.js",
        "../../js/productCard4.component.js",
        "../../js/gridFlex.layout.js",
      ],
      specs: [
        ["Navigation Link", ["String text", "Recommend: 3 to 5 navigation links"]],
        ["Category", ["String text", "Number of categories: 1 to 10"]],
        ["Category Link", ["String text", "Number of category links: 1 to 8"]],
      ],
      cis: {
        cmsDoc:
          "https://docs.google.com/document/d/1CxF7HEZHBIAqUAfGbp-IsHefQ7Innva9/edit?usp=sharing&ouid=106921661368730647562&rtpof=true&sd=true",
        comments: null,
      },
    };
    setAppState(megaMenuNavigation);
    setContentOptions(megaMenuNavOpts);
    setDimensions({
      ...dimensions,
      viewHeight: "850",
      hardcodeHeight: true,
      width: window.innerWidth - 24,
    });
  }, []);

  function handleActiveLink(panel) {
    panel == activeMainLink ? setActiveMainLink("") : setActiveMainLink(panel);
    setActiveMainContainer(true);
  }

  function all() {
    setActiveMainLink("");
    setActiveMainContainer(false);
  }

  function handleActiveSubLink(subpanel) {
    subpanel == activeSubLink ? setActiveSubLink("") : setActiveSubLink(subpanel);
    setActiveSubContainer(true);
  }

  function handleActiveDesktopKD(panel) {
    panel == activeDesktopKeyDown ? setDesktopKeyDown("") : setDesktopKeyDown(panel);
  }

  function handleActiveSideMenu(sidemenupanel) {
    sidemenupanel == activeSideMenu ? setActiveSideMenu("") : setActiveSideMenu(sidemenupanel);
    console.log(window.innerWidth);
    activeSideMenu == "" && window.innerWidth > 800 ? setBlurOn(true) : setBlurOn(false);

    if (sidemenupanel == "sidemenu-search-panel-id-0") {
      if (activeSearch == false) {
        setSearch(true);
        searchRef.current.focus();
      } else setSearch(false);
    }
  }

  function handleActiveLanguage(language) {
    language == activeLanguage ? setActiveLanguage("") : setActiveLanguage(language);
  }

  function previous() {
    setActiveSubLink("");
    setActiveSubContainer(false);
  }

  function cartMenuBtn() {
    setHamburgerOpen(false);
    setActiveSideMenu("sidemenu-cart-panel-id-0");
    setBlurOn(false);
  }

  if (!contentOptions) return "...Loading Mega Menu Navigation";
  return (
    <>
      <nav
        className={`n-megaMenuNavigation ${hamburgerOpen ? "n-megaMenuNavigation--active" : ""}`}
      >
        <button
          className="n-megaMenuNavigation__hamburgerMenu"
          aria-label="Menu"
          aria-expanded={hamburgerOpen ? "true" : "false"}
          aria-controls="navigation-mobile-menu"
          onClick={() => {
            setHamburgerOpen(true);
            setBlurOn(true);
          }}
        >
          <svg viewBox="0 0 16 16">
            <path
              d="M1 1h14c.55 0 1 .45 1 1s-.45 1-1 1H1c-.55 0-1-.45-1-1s.45-1 1-1zm0 6h14c.55 0 1 .45 1 1s-.45 1-1 1H1c-.55 0-1-.45-1-1s.45-1 1-1zm0 6h14c.55 0 1 .45 1 1s-.45 1-1 1H1c-.55 0-1-.45-1-1s.45-1 1-1z"
              fillRule="evenodd"
              clipRule="evenodd"
            />
          </svg>
        </button>

        <div className="n-megaMenuNavigation__logo">
          <a href="#" className="n-megaMenuNavigation__logo__image">
            <img src="/images/kingston-logo-blkText.svg" alt="Kingston Technology" />
          </a>
        </div>

        <div className="n-megaMenuNavigation__mobile" inert="true">
          <div
            className={`n-megaMenuNavigation__mobile__mainContainer ${
              activeMainContainer == true
                ? " n-megaMenuNavigation__mobile__mainContainer--left"
                : ""
            }`}
          >
            <div className="n-megaMenuNavigation__mobile__mainContainer__heading">
              <button
                className="n-megaMenuNavigation__mobile__mainContainer__heading__close"
                aria-label="Close Menu"
                onClick={() => {
                  setHamburgerOpen(false);
                  setBlurOn(false);
                }}
              >
                <svg viewBox="0 0 24 24">
                  <path d="m17.614 16.194-4.25-4.19 3.54-3.58.71-.71a1.004 1.004 0 1 0-1.42-1.42l-.7.71-3.49 3.54-4.29-4.25a1.004 1.004 0 0 0-1.42 1.42l4.25 4.29-3.54 3.49-.71.7a1.004 1.004 0 0 0 1.42 1.42l.71-.71 3.58-3.54 4.24 4.25a1.004 1.004 0 1 0 1.42-1.42h-.05Z" />
                </svg>
              </button>
            </div>
            <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent">
              <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList">
                {contentOptions.navlinks &&
                contentOptions.navlinks[0] &&
                contentOptions.navlinks[0].checkbox ? (
                  <li
                    className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item ${
                      activeMainLink == "main-panel-id-0"
                        ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item--active"
                        : ""
                    }${
                      activeSubContainer == true
                        ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item--left"
                        : ""
                    }`}
                  >
                    <button
                      id="main-trigger-id-0"
                      aria-controls="main-panel-id-0"
                      data-triggerid="main-container"
                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__trigger"
                      onClick={() => handleActiveLink("main-panel-id-0")}
                    >
                      <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__trigger__copy">
                        {contentOptions.navlinks[0].field[contentOptions.currentLang]}
                      </span>
                      <span
                        className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__trigger__icon"
                        aria-hidden="true"
                      >
                        <svg viewBox="0 0 24 24">
                          <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                        </svg>
                      </span>
                    </button>
                    <div
                      id="main-panel-id-0"
                      className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel`}
                    >
                      <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer">
                        <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__heading">
                          <button
                            className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__heading__previous"
                            onClick={all}
                          >
                            <svg viewBox="0 0 24 24">
                              <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                            </svg>
                            All
                          </button>
                          <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__heading__category">
                            {contentOptions.navlinks[0].field[contentOptions.currentLang]}
                          </span>
                        </div>
                        <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent">
                          <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList">
                            {contentOptions.numcategories &&
                            contentOptions.numcategories.selected >= 1 ? (
                              <li
                                className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item${
                                  activeSubLink == "memory-panel-id-0"
                                    ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item--active"
                                    : ""
                                }`}
                              >
                                <button
                                  id="memory-trigger-id-0"
                                  aria-controls="memory-panel-id-0"
                                  data-triggerid="panel-id-0"
                                  className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                                  onClick={() => handleActiveSubLink("memory-panel-id-0")}
                                >
                                  <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                    {
                                      contentOptions.memorylinks[0].field[
                                        contentOptions.currentLang
                                      ]
                                    }
                                  </span>
                                  <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__icon">
                                    <svg viewBox="0 0 24 24">
                                      <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                    </svg>
                                  </span>
                                </button>
                                <div
                                  id="memory-panel-id-0"
                                  className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel`}
                                >
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container">
                                    <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading">
                                      <button
                                        className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__previous"
                                        onClick={previous}
                                      >
                                        <svg viewBox="0 0 24 24">
                                          <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                        </svg>
                                        {
                                          contentOptions.navlinks[0].field[
                                            contentOptions.currentLang
                                          ]
                                        }
                                      </button>
                                      <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__category">
                                        {
                                          contentOptions.memorylinks[0].field[
                                            contentOptions.currentLang
                                          ]
                                        }
                                      </span>
                                    </div>
                                    <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content">
                                      <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list">
                                        {contentOptions.memory &&
                                        contentOptions.memory.selected >= 1 ? (
                                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                            <a href="#">
                                              {
                                                contentOptions.memorylinks[1].field[
                                                  contentOptions.currentLang
                                                ]
                                              }
                                            </a>
                                          </li>
                                        ) : (
                                          ""
                                        )}
                                        {contentOptions.memory &&
                                        contentOptions.memory.selected >= 2 ? (
                                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                            <a href="#">
                                              {
                                                contentOptions.memorylinks[2].field[
                                                  contentOptions.currentLang
                                                ]
                                              }
                                            </a>
                                          </li>
                                        ) : (
                                          ""
                                        )}
                                        {contentOptions.memory &&
                                        contentOptions.memory.selected >= 3 ? (
                                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                            <a href="#">
                                              {
                                                contentOptions.memorylinks[3].field[
                                                  contentOptions.currentLang
                                                ]
                                              }
                                            </a>
                                          </li>
                                        ) : (
                                          ""
                                        )}
                                        {contentOptions.memory &&
                                        contentOptions.memory.selected >= 4 ? (
                                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                            <a href="#">
                                              {
                                                contentOptions.memorylinks[4].field[
                                                  contentOptions.currentLang
                                                ]
                                              }
                                            </a>
                                          </li>
                                        ) : (
                                          ""
                                        )}
                                        {contentOptions.memory &&
                                        contentOptions.memory.selected >= 5 ? (
                                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                            <a href="#">
                                              {
                                                contentOptions.memorylinks[5].field[
                                                  contentOptions.currentLang
                                                ]
                                              }
                                            </a>
                                          </li>
                                        ) : (
                                          ""
                                        )}
                                        {contentOptions.memory &&
                                        contentOptions.memory.selected >= 6 ? (
                                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                            <a href="#">
                                              {
                                                contentOptions.memorylinks[6].field[
                                                  contentOptions.currentLang
                                                ]
                                              }
                                            </a>
                                          </li>
                                        ) : (
                                          ""
                                        )}
                                        {contentOptions.memory &&
                                        contentOptions.memory.selected == 8 ? (
                                          <>
                                            <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                              <a href="#">Link</a>
                                            </li>
                                            <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                              <a href="#">Link</a>
                                            </li>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ) : (
                              ""
                            )}

                            {contentOptions.numcategories &&
                            contentOptions.numcategories.selected >= 2 ? (
                              <li
                                className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item${
                                  activeSubLink == "memorycard-panel-id-0"
                                    ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item--active"
                                    : ""
                                }`}
                              >
                                <button
                                  id="memorycard-trigger-id-0"
                                  aria-controls="memorycard-panel-id-0"
                                  data-triggerid="panel-id-0"
                                  className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                                  onClick={() => handleActiveSubLink("memorycard-panel-id-0")}
                                >
                                  <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                    {
                                      contentOptions.memorycardlinks[0].field[
                                        contentOptions.currentLang
                                      ]
                                    }
                                  </span>
                                  <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__icon">
                                    <svg viewBox="0 0 24 24">
                                      <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                    </svg>
                                  </span>
                                </button>
                                <div
                                  id="memorycard-panel-id-0"
                                  className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel`}
                                >
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container">
                                    <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading">
                                      <button
                                        className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__previous"
                                        onClick={previous}
                                      >
                                        <svg viewBox="0 0 24 24">
                                          <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                        </svg>
                                        {
                                          contentOptions.navlinks[0].field[
                                            contentOptions.currentLang
                                          ]
                                        }
                                      </button>
                                      <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__category">
                                        {
                                          contentOptions.memorycardlinks[0].field[
                                            contentOptions.currentLang
                                          ]
                                        }
                                      </span>
                                    </div>
                                    <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content">
                                      <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list">
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.memorycardlinks[1].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.memorycardlinks[2].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.memorycardlinks[3].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.memorycardlinks[4].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ) : (
                              ""
                            )}

                            {contentOptions.numcategories &&
                            contentOptions.numcategories.selected >= 3 ? (
                              <li
                                className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item${
                                  activeSubLink == "ssd-panel-id-0"
                                    ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item--active"
                                    : ""
                                }`}
                              >
                                <button
                                  id="ssd-trigger-id-0"
                                  aria-controls="ssd-panel-id-0"
                                  data-triggerid="panel-id-0"
                                  className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                                  onClick={() => handleActiveSubLink("ssd-panel-id-0")}
                                >
                                  <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                    {contentOptions.ssdslinks[0].field[contentOptions.currentLang]}
                                  </span>
                                  <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__icon">
                                    <svg viewBox="0 0 24 24">
                                      <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                    </svg>
                                  </span>
                                </button>
                                <div
                                  id="ssd-panel-id-0"
                                  className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel`}
                                >
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container">
                                    <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading">
                                      <button
                                        className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__previous"
                                        onClick={previous}
                                      >
                                        <svg viewBox="0 0 24 24">
                                          <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                        </svg>
                                        {
                                          contentOptions.navlinks[0].field[
                                            contentOptions.currentLang
                                          ]
                                        }
                                      </button>
                                      <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__category">
                                        {
                                          contentOptions.ssdslinks[0].field[
                                            contentOptions.currentLang
                                          ]
                                        }
                                      </span>
                                    </div>
                                    <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content">
                                      <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list">
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.ssdslinks[1].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.ssdslinks[2].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.ssdslinks[3].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.ssdslinks[4].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.ssdslinks[5].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.ssdslinks[6].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.ssdslinks[7].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ) : (
                              ""
                            )}

                            {contentOptions.numcategories &&
                            contentOptions.numcategories.selected >= 4 ? (
                              <li
                                className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item${
                                  activeSubLink == "readers-panel-id-0"
                                    ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item--active"
                                    : ""
                                }`}
                              >
                                <button
                                  id="readers-trigger-id-0"
                                  aria-controls="readers-panel-id-0"
                                  data-triggerid="panel-id-0"
                                  className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                                  onClick={() => handleActiveSubLink("readers-panel-id-0")}
                                >
                                  <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                    {
                                      contentOptions.readerlinks[0].field[
                                        contentOptions.currentLang
                                      ]
                                    }
                                  </span>
                                  <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__icon">
                                    <svg viewBox="0 0 24 24">
                                      <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                    </svg>
                                  </span>
                                </button>
                                <div
                                  id="readers-panel-id-0"
                                  className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel`}
                                >
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container">
                                    <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading">
                                      <button
                                        className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__previous"
                                        onClick={previous}
                                      >
                                        <svg viewBox="0 0 24 24">
                                          <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                        </svg>
                                        {
                                          contentOptions.navlinks[0].field[
                                            contentOptions.currentLang
                                          ]
                                        }
                                      </button>
                                      <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__category">
                                        {
                                          contentOptions.readerlinks[0].field[
                                            contentOptions.currentLang
                                          ]
                                        }
                                      </span>
                                    </div>
                                    <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content">
                                      <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list">
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.readerlinks[1].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.readerlinks[2].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.readerlinks[3].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ) : (
                              ""
                            )}

                            {contentOptions.numcategories &&
                            contentOptions.numcategories.selected >= 5 ? (
                              <li
                                className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item${
                                  activeSubLink == "flashdrives-panel-id-0"
                                    ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item--active"
                                    : ""
                                }`}
                              >
                                <button
                                  id="flashdrives-trigger-id-0"
                                  aria-controls="flashdrives-panel-id-0"
                                  data-triggerid="panel-id-0"
                                  className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                                  onClick={() => handleActiveSubLink("flashdrives-panel-id-0")}
                                >
                                  <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                    {
                                      contentOptions.usbstoragelinks[0].field[
                                        contentOptions.currentLang
                                      ]
                                    }
                                  </span>
                                  <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__icon">
                                    <svg viewBox="0 0 24 24">
                                      <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                    </svg>
                                  </span>
                                </button>
                                <div
                                  id="flashdrives-panel-id-0"
                                  className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel`}
                                >
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container">
                                    <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading">
                                      <button
                                        className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__previous"
                                        onClick={previous}
                                      >
                                        <svg viewBox="0 0 24 24">
                                          <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                        </svg>
                                        {
                                          contentOptions.navlinks[0].field[
                                            contentOptions.currentLang
                                          ]
                                        }
                                      </button>
                                      <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__category">
                                        {
                                          contentOptions.usbstoragelinks[0].field[
                                            contentOptions.currentLang
                                          ]
                                        }
                                      </span>
                                    </div>
                                    <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content">
                                      <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list">
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.usbstoragelinks[1].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.usbstoragelinks[2].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ) : (
                              ""
                            )}

                            {contentOptions.numcategories &&
                            contentOptions.numcategories.selected >= 6 ? (
                              <li
                                className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item${
                                  activeSubLink == "gaming-panel-id-0"
                                    ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item--active"
                                    : ""
                                }`}
                              >
                                <button
                                  id="gaming-trigger-id-0"
                                  aria-controls="gaming-panel-id-0"
                                  data-triggerid="panel-id-0"
                                  className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                                  onClick={() => handleActiveSubLink("gaming-panel-id-0")}
                                >
                                  <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                    {
                                      contentOptions.embeddedindustriallinks[0].field[
                                        contentOptions.currentLang
                                      ]
                                    }
                                  </span>
                                  <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__icon">
                                    <svg viewBox="0 0 24 24">
                                      <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                    </svg>
                                  </span>
                                </button>
                                <div
                                  id="gaming-panel-id-0"
                                  className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel`}
                                >
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container">
                                    <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading">
                                      <button
                                        className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__previous"
                                        onClick={previous}
                                      >
                                        <svg viewBox="0 0 24 24">
                                          <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                        </svg>
                                        {
                                          contentOptions.navlinks[0].field[
                                            contentOptions.currentLang
                                          ]
                                        }
                                      </button>
                                      <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__category">
                                        {
                                          contentOptions.embeddedindustriallinks[0].field[
                                            contentOptions.currentLang
                                          ]
                                        }
                                      </span>
                                    </div>
                                    <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content">
                                      <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list">
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.embeddedindustriallinks[1].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.embeddedindustriallinks[2].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.embeddedindustriallinks[3].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.embeddedindustriallinks[4].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.embeddedindustriallinks[5].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.embeddedindustriallinks[6].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.embeddedindustriallinks[7].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                        <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                          <a href="#">
                                            {
                                              contentOptions.embeddedindustriallinks[8].field[
                                                contentOptions.currentLang
                                              ]
                                            }
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ) : (
                              ""
                            )}

                            {contentOptions.numcategories &&
                            contentOptions.numcategories.selected == 10 ? (
                              <>
                                <li
                                  className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item`}
                                >
                                  <a href="#">Link</a>
                                </li>
                                <li
                                  className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item`}
                                >
                                  <a href="#">Link</a>
                                </li>
                              </>
                            ) : (
                              ""
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  ""
                )}

                {contentOptions.navlinks &&
                contentOptions.navlinks[1] &&
                contentOptions.navlinks[1].checkbox ? (
                  <li
                    className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item ${
                      activeMainLink == "main-panel-id-1"
                        ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item--active"
                        : ""
                    }${
                      activeSubContainer == true
                        ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item--left"
                        : ""
                    }`}
                  >
                    <button
                      id="main-trigger-id-1"
                      aria-controls="main-panel-id-1"
                      data-triggerid="main-container"
                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__trigger"
                      onClick={() => handleActiveLink("main-panel-id-1")}
                    >
                      <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__trigger__copy">
                        {contentOptions.navlinks[1].field[contentOptions.currentLang]}
                      </span>
                      <span
                        className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__trigger__icon"
                        aria-hidden="true"
                      >
                        <svg viewBox="0 0 24 24">
                          <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                        </svg>
                      </span>
                    </button>
                    <div
                      id="main-panel-id-1"
                      className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel`}
                    >
                      <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer">
                        <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__heading">
                          <button
                            className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__heading__previous"
                            onClick={all}
                          >
                            <svg viewBox="0 0 24 24">
                              <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                            </svg>
                            All
                          </button>
                          <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__heading__category">
                            {contentOptions.navlinks[1].field[contentOptions.currentLang]}
                          </span>
                        </div>
                        <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent">
                          <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList">
                            <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item${
                                activeSubLink == "personal-panel-id-1"
                                  ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item--active"
                                  : ""
                              }`}
                            >
                              <button
                                id="personal-trigger-id-1"
                                aria-controls="personal-panel-id-1"
                                data-triggerid="panel-id-1"
                                className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                                onClick={() => handleActiveSubLink("personal-panel-id-1")}
                              >
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                  Personal
                                </span>
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__icon">
                                  <svg viewBox="0 0 24 24">
                                    <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                  </svg>
                                </span>
                              </button>
                              <div
                                id="personal-panel-id-1"
                                className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel`}
                              >
                                <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container">
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading">
                                    <button
                                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__previous"
                                      onClick={previous}
                                    >
                                      <svg viewBox="0 0 24 24">
                                        <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                      </svg>
                                      Solutions
                                    </button>
                                    <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__category">
                                      Personal
                                    </span>
                                  </div>
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content">
                                    <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list">
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Mobile lifestyle</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Personal storage</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">PC performance</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Gaming</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item${
                                activeSubLink == "business-panel-id-1"
                                  ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item--active"
                                  : ""
                              }`}
                            >
                              <button
                                id="business-trigger-id-1"
                                aria-controls="business-panel-id-1"
                                data-triggerid="panel-id-1"
                                className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                                onClick={() => handleActiveSubLink("business-panel-id-1")}
                              >
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                  Business
                                </span>
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__icon">
                                  <svg viewBox="0 0 24 24">
                                    <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                  </svg>
                                </span>
                              </button>
                              <div
                                id="business-panel-id-1"
                                className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel`}
                              >
                                <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container">
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading">
                                    <button
                                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__previous"
                                      onClick={previous}
                                    >
                                      <svg viewBox="0 0 24 24">
                                        <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                      </svg>
                                      Solutions
                                    </button>
                                    <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__category">
                                      Business
                                    </span>
                                  </div>
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content">
                                    <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list">
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Servers / Data center</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Data security</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">System builders</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Embedded/Industrial</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  ""
                )}

                {contentOptions.navlinks &&
                contentOptions.navlinks[2] &&
                contentOptions.navlinks[2].checkbox ? (
                  <li
                    className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item${
                      activeMainLink == "main-panel-id-2"
                        ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item--active"
                        : ""
                    }${
                      activeSubContainer == true
                        ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item--left"
                        : ""
                    }`}
                  >
                    <button
                      id="main-trigger-id-2"
                      aria-controls="main-panel-id-2"
                      data-triggerid="main-container"
                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__trigger"
                      onClick={() => handleActiveLink("main-panel-id-2")}
                    >
                      <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__trigger__copy">
                        {contentOptions.navlinks[2].field[contentOptions.currentLang]}
                      </span>
                      <span
                        className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__trigger__icon"
                        aria-hidden="true"
                      >
                        <svg viewBox="0 0 24 24">
                          <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                        </svg>
                      </span>
                    </button>
                    <div
                      id="main-panel-id-2"
                      className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel`}
                    >
                      <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer">
                        <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__heading">
                          <button
                            className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__heading__previous"
                            onClick={all}
                          >
                            <svg viewBox="0 0 24 24">
                              <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                            </svg>
                            All
                          </button>
                          <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__heading__category">
                            {contentOptions.navlinks[2].field[contentOptions.currentLang]}
                          </span>
                        </div>
                        <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent">
                          <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList">
                            <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item${
                                activeSubLink == "support-panel-id-2"
                                  ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item--active"
                                  : ""
                              }`}
                            >
                              <button
                                id="support-trigger-id-2"
                                aria-controls="support-panel-id-2"
                                data-triggerid="panel-id-2"
                                className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                                onClick={() => handleActiveSubLink("support-panel-id-2")}
                              >
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                  Product support
                                </span>
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__icon">
                                  <svg viewBox="0 0 24 24">
                                    <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                  </svg>
                                </span>
                              </button>
                              <div
                                id="support-panel-id-2"
                                className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel`}
                              >
                                <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container">
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading">
                                    <button
                                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__previous"
                                      onClick={previous}
                                    >
                                      <svg viewBox="0 0 24 24">
                                        <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                      </svg>
                                      Support
                                    </button>
                                    <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__category">
                                      Product support
                                    </span>
                                  </div>
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content">
                                    <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list">
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Get support</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Warranty</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Product verification</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">RMA (end user)</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item${
                                activeSubLink == "where-panel-id-2"
                                  ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item--active"
                                  : ""
                              }`}
                            >
                              <button
                                id="where-trigger-id-2"
                                aria-controls="where-panel-id-2"
                                data-triggerid="panel-id-2"
                                className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                                onClick={() => handleActiveSubLink("where-panel-id-2")}
                              >
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                  Where to buy
                                </span>
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__icon">
                                  <svg viewBox="0 0 24 24">
                                    <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                  </svg>
                                </span>
                              </button>
                              <div
                                id="where-panel-id-2"
                                className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel`}
                              >
                                <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container">
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading">
                                    <button
                                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__previous"
                                      onClick={previous}
                                    >
                                      <svg viewBox="0 0 24 24">
                                        <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                      </svg>
                                      Support
                                    </button>
                                    <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__category">
                                      Where to buy
                                    </span>
                                  </div>
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content">
                                    <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list">
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Where to buy</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item${
                                activeSubLink == "partner-panel-id-2"
                                  ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item--active"
                                  : ""
                              }`}
                            >
                              <button
                                id="partner-trigger-id-2"
                                aria-controls="partner-panel-id-2"
                                data-triggerid="panel-id-2"
                                className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                                onClick={() => handleActiveSubLink("partner-panel-id-2")}
                              >
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                  Partner support
                                </span>
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__icon">
                                  <svg viewBox="0 0 24 24">
                                    <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                  </svg>
                                </span>
                              </button>
                              <div
                                id="partner-panel-id-2"
                                className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel`}
                              >
                                <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container">
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading">
                                    <button
                                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__previous"
                                      onClick={previous}
                                    >
                                      <svg viewBox="0 0 24 24">
                                        <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                      </svg>
                                      Support
                                    </button>
                                    <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__category">
                                      Partner support
                                    </span>
                                  </div>
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content">
                                    <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list">
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">KingstonCare</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Reseller RMA</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">EMEA learning center</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  ""
                )}

                {contentOptions.navlinks &&
                contentOptions.navlinks[3] &&
                contentOptions.navlinks[3].checkbox ? (
                  <li
                    className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item${
                      activeMainLink == "main-panel-id-3"
                        ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item--active"
                        : ""
                    }${
                      activeSubContainer == true
                        ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item--left"
                        : ""
                    }`}
                  >
                    <button
                      id="main-trigger-id-3"
                      aria-controls="main-panel-id-3"
                      data-triggerid="main-container"
                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__trigger"
                      onClick={() => handleActiveLink("main-panel-id-3")}
                    >
                      <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__trigger__copy">
                        {contentOptions.navlinks[3].field[contentOptions.currentLang]}
                      </span>
                      <span
                        className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__trigger__icon"
                        aria-hidden="true"
                      >
                        <svg viewBox="0 0 24 24">
                          <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                        </svg>
                      </span>
                    </button>
                    <div
                      id="main-panel-id-3"
                      className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel`}
                    >
                      <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer">
                        <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__heading">
                          <button
                            className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__heading__previous"
                            onClick={all}
                          >
                            <svg viewBox="0 0 24 24">
                              <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                            </svg>
                            All
                          </button>
                          <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__heading__category">
                            {contentOptions.navlinks[3].field[contentOptions.currentLang]}
                          </span>
                        </div>
                        <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent">
                          <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList">
                            <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item${
                                activeSubLink == "about-panel-id-3"
                                  ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item--active"
                                  : ""
                              }`}
                            >
                              <button
                                id="about-trigger-id-3"
                                aria-controls="about-panel-id-3"
                                data-triggerid="panel-id-3"
                                className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                                onClick={() => handleActiveSubLink("about-panel-id-3")}
                              >
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                  About Kingston
                                </span>
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__icon">
                                  <svg viewBox="0 0 24 24">
                                    <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                  </svg>
                                </span>
                              </button>
                              <div
                                id="about-panel-id-3"
                                className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel`}
                              >
                                <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container">
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading">
                                    <button
                                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__previous"
                                      onClick={previous}
                                    >
                                      <svg viewBox="0 0 24 24">
                                        <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                      </svg>
                                      Company
                                    </button>
                                    <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__category">
                                      About Kingston
                                    </span>
                                  </div>
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content">
                                    <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list">
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Who we are</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">
                                          Kingston is with you
                                          <sup className="u-txt-trademark">&trade;</sup>
                                          <sup className="u-txt-copyright">&copy;</sup>
                                          <sup className="u-txt-registered">&reg;</sup>
                                        </a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Divisions of Kingston</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Kingston worldwide</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">History of Kingston</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Kingston overview</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Awards & recognition</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Market share</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item${
                                activeSubLink == "compliance-panel-id-3"
                                  ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item--active"
                                  : ""
                              }`}
                            >
                              <button
                                id="compliance-trigger-id-3"
                                aria-controls="compliance-panel-id-3"
                                data-triggerid="panel-id-3"
                                className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                                onClick={() => handleActiveSubLink("compliance-panel-id-3")}
                              >
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                  Compliance
                                </span>
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__icon">
                                  <svg viewBox="0 0 24 24">
                                    <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                  </svg>
                                </span>
                              </button>
                              <div
                                id="compliance-panel-id-3"
                                className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel`}
                              >
                                <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container">
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading">
                                    <button
                                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__previous"
                                      onClick={previous}
                                    >
                                      <svg viewBox="0 0 24 24">
                                        <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                      </svg>
                                      Company
                                    </button>
                                    <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__category">
                                      Compliance
                                    </span>
                                  </div>
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content">
                                    <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list">
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Product compliance</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Management systems</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Environmental health & safety</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Compliance overview</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item${
                                activeSubLink == "legal-panel-id-3"
                                  ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item--active"
                                  : ""
                              }`}
                            >
                              <button
                                id="legal-trigger-id-3"
                                aria-controls="legal-panel-id-3"
                                data-triggerid="panel-id-3"
                                className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                                onClick={() => handleActiveSubLink("legal-panel-id-3")}
                              >
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                  Legal
                                </span>
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__icon">
                                  <svg viewBox="0 0 24 24">
                                    <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                  </svg>
                                </span>
                              </button>
                              <div
                                id="legal-panel-id-3"
                                className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel`}
                              >
                                <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container">
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading">
                                    <button
                                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__previous"
                                      onClick={previous}
                                    >
                                      <svg viewBox="0 0 24 24">
                                        <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                      </svg>
                                      Company
                                    </button>
                                    <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__category">
                                      Legal
                                    </span>
                                  </div>
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content">
                                    <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list">
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Legal statement</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Privacy policy</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Cookie policy</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item${
                                activeSubLink == "press-panel-id-3"
                                  ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item--active"
                                  : ""
                              }`}
                            >
                              <button
                                id="press-trigger-id-3"
                                aria-controls="press-panel-id-3"
                                data-triggerid="panel-id-3"
                                className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                                onClick={() => handleActiveSubLink("press-panel-id-3")}
                              >
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                  Press
                                </span>
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__icon">
                                  <svg viewBox="0 0 24 24">
                                    <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                  </svg>
                                </span>
                              </button>
                              <div
                                id="press-panel-id-3"
                                className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel`}
                              >
                                <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container">
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading">
                                    <button
                                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__previous"
                                      onClick={previous}
                                    >
                                      <svg viewBox="0 0 24 24">
                                        <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                      </svg>
                                      Company
                                    </button>
                                    <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__category">
                                      Press
                                    </span>
                                  </div>
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content">
                                    <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list">
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Press releases</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Press images</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">PR contacts</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item${
                                activeSubLink == "sustain-panel-id-3"
                                  ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item--active"
                                  : ""
                              }`}
                            >
                              <button
                                id="sustain-trigger-id-3"
                                aria-controls="sustain-panel-id-3"
                                data-triggerid="panel-id-3"
                                className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                                onClick={() => handleActiveSubLink("sustain-panel-id-3")}
                              >
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                  Sustainability
                                </span>
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__icon">
                                  <svg viewBox="0 0 24 24">
                                    <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                  </svg>
                                </span>
                              </button>
                              <div
                                id="sustain-panel-id-3"
                                className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel`}
                              >
                                <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container">
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading">
                                    <button
                                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__previous"
                                      onClick={previous}
                                    >
                                      <svg viewBox="0 0 24 24">
                                        <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                      </svg>
                                      Company
                                    </button>
                                    <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__category">
                                      Sustainability
                                    </span>
                                  </div>
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content">
                                    <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list">
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Management systems</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Transparency reporting</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Kingston Supplier Code of Conduct</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Sustainability overview</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item${
                                activeSubLink == "careers-panel-id-3"
                                  ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item--active"
                                  : ""
                              }`}
                            >
                              <button
                                id="careers-trigger-id-3"
                                aria-controls="careers-panel-id-3"
                                data-triggerid="panel-id-3"
                                className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                                onClick={() => handleActiveSubLink("careers-panel-id-3")}
                              >
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                  Careers
                                </span>
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__icon">
                                  <svg viewBox="0 0 24 24">
                                    <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                  </svg>
                                </span>
                              </button>
                              <div
                                id="careers-panel-id-3"
                                className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel`}
                              >
                                <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container">
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading">
                                    <button
                                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__previous"
                                      onClick={previous}
                                    >
                                      <svg viewBox="0 0 24 24">
                                        <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                      </svg>
                                      Company
                                    </button>
                                    <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__category">
                                      Careers
                                    </span>
                                  </div>
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content">
                                    <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list">
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Careers at Kingston</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item${
                                activeSubLink == "contacts-panel-id-3"
                                  ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item--active"
                                  : ""
                              }`}
                            >
                              <button
                                id="contacts-trigger-id-3"
                                aria-controls="contacts-panel-id-3"
                                data-triggerid="panel-id-3"
                                className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                                onClick={() => handleActiveSubLink("contacts-panel-id-3")}
                              >
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                  Contacts
                                </span>
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__icon">
                                  <svg viewBox="0 0 24 24">
                                    <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                  </svg>
                                </span>
                              </button>
                              <div
                                id="contacts-panel-id-3"
                                className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel`}
                              >
                                <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container">
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading">
                                    <button
                                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__previous"
                                      onClick={previous}
                                    >
                                      <svg viewBox="0 0 24 24">
                                        <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                      </svg>
                                      Company
                                    </button>
                                    <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__category">
                                      Contacts
                                    </span>
                                  </div>
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content">
                                    <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list">
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Contact info</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item${
                                activeSubLink == "choose-panel-id-3"
                                  ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item--active"
                                  : ""
                              }`}
                            >
                              <button
                                id="choose-trigger-id-3"
                                aria-controls="choose-panel-id-3"
                                data-triggerid="panel-id-3"
                                className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                                onClick={() => handleActiveSubLink("choose-panel-id-3")}
                              >
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                  Why choose Kingston?
                                </span>
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__icon">
                                  <svg viewBox="0 0 24 24">
                                    <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                  </svg>
                                </span>
                              </button>
                              <div
                                id="contacts-panel-id-3"
                                className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel`}
                              >
                                <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container">
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading">
                                    <button
                                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__previous"
                                      onClick={previous}
                                    >
                                      <svg viewBox="0 0 24 24">
                                        <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                      </svg>
                                      Company
                                    </button>
                                    <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__category">
                                      Why choose Kingston?
                                    </span>
                                  </div>
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content">
                                    <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list">
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Why choose Kingston?</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Testing</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  ""
                )}

                {contentOptions.navlinks &&
                contentOptions.navlinks[4] &&
                contentOptions.navlinks[4].checkbox ? (
                  <li
                    className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item${
                      activeMainLink == "main-panel-id-4"
                        ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item--active"
                        : ""
                    }${
                      activeSubContainer == true
                        ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item--left"
                        : ""
                    }`}
                  >
                    <button
                      id="main-trigger-id-4"
                      aria-controls="main-panel-id-4"
                      data-triggerid="main-container"
                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__trigger"
                      onClick={() => handleActiveLink("main-panel-id-4")}
                    >
                      <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__trigger__copy">
                        {contentOptions.navlinks[4].field[contentOptions.currentLang]}
                      </span>
                      <span
                        className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__trigger__icon"
                        aria-hidden="true"
                      >
                        <svg viewBox="0 0 24 24">
                          <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                        </svg>
                      </span>
                    </button>
                    <div
                      id="main-panel-id-4"
                      className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel`}
                    >
                      <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer">
                        <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__heading">
                          <button
                            className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__heading__previous"
                            onClick={all}
                          >
                            <svg viewBox="0 0 24 24">
                              <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                            </svg>
                            All
                          </button>
                          <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__heading__category">
                            {contentOptions.navlinks[4].field[contentOptions.currentLang]}
                          </span>
                        </div>
                        <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent">
                          <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList">
                            <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item${
                                activeSubLink == "article-panel-id-4"
                                  ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item--active"
                                  : ""
                              }`}
                            >
                              <button
                                id="article-trigger-id-4"
                                aria-controls="article-panel-id-4"
                                data-triggerid="panel-id-4"
                                className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                                onClick={() => handleActiveSubLink("article-panel-id-4")}
                              >
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                  Knowledge center
                                </span>
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__icon">
                                  <svg viewBox="0 0 24 24">
                                    <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                  </svg>
                                </span>
                              </button>
                              <div
                                id="article-panel-id-4"
                                className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel`}
                              >
                                <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container">
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading">
                                    <button
                                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__previous"
                                      onClick={previous}
                                    >
                                      <svg viewBox="0 0 24 24">
                                        <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                                      </svg>
                                      Blog
                                    </button>
                                    <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__heading__category">
                                      Knowledge center
                                    </span>
                                  </div>
                                  <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content">
                                    <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list">
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">All articles</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Gaming</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Servers / Data centers</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Data security</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Mobile lifestyle</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">Personal storage</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">PC performance</a>
                                      </li>
                                      <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__panel__container__content__list__item">
                                        <a href="#">System builders</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </li>

                            {/* If a standalone link item is an option in the future */}
                            {/* <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item`}
                            >
                              <a
                                href="#"
                                className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                              >
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                  Link
                                </span>
                              </a>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  ""
                )}

                {contentOptions.navlinks &&
                contentOptions.navlinks[5] &&
                contentOptions.navlinks[5].checkbox ? (
                  <li
                    className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item${
                      activeMainLink == "main-panel-id-5"
                        ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item--active"
                        : ""
                    }${
                      activeSubContainer == true
                        ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item--left"
                        : ""
                    }`}
                  >
                    <button
                      id="main-trigger-id-5"
                      aria-controls="main-panel-id-5"
                      data-triggerid="main-container"
                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__trigger"
                      onClick={() => handleActiveLink("main-panel-id-5")}
                    >
                      <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__trigger__copy">
                        {contentOptions.navlinks[5].field[contentOptions.currentLang]}
                      </span>
                      <span
                        className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__trigger__icon"
                        aria-hidden="true"
                      >
                        <svg viewBox="0 0 24 24">
                          <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                        </svg>
                      </span>
                    </button>
                    <div
                      id="main-panel-id-5"
                      className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel`}
                    >
                      <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer">
                        <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__heading">
                          <button
                            className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__heading__previous"
                            onClick={all}
                          >
                            <svg viewBox="0 0 24 24">
                              <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                            </svg>
                            All
                          </button>
                          <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__heading__category">
                            {contentOptions.navlinks[5].field[contentOptions.currentLang]}
                          </span>
                        </div>
                        <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent">
                          <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList">
                            <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item`}
                            >
                              <a href="#">Link 1</a>
                            </li>
                            <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item`}
                            >
                              <a href="#">Link 2</a>
                            </li>
                            <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item`}
                            >
                              <a href="#">Link 3</a>
                            </li>
                            <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item`}
                            >
                              <a href="#">Link 4</a>
                            </li>
                            <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item`}
                            >
                              <a href="#">Link 5</a>
                            </li>

                            {/* If a standalone link item is an option in the future */}
                            {/* <li
                              className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item`}
                            >
                              <a
                                href="#"
                                className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger"
                              >
                                <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__panel__subContainer__subContent__subList__item__trigger__copy">
                                  Link
                                </span>
                              </a>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  ""
                )}

                {/* If a standalone link item is an option in the future */}

                {/* {contentOptions.navlinks &&
              contentOptions.navlinks[5] &&
              contentOptions.navlinks[5].checkbox ? (
                <li
                  className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList`}
                >
                  <a
                    href="#"
                    className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__trigger"
                  >
                    <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__mainList__item__trigger__copy">
                      {contentOptions.navlinks[5].field[contentOptions.currentLang]}
                    </span>
                  </a>
                </li>
              ) : (
                ""
              )} */}
              </ul>
              <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList">
                {contentOptions.cart && contentOptions.cart.checkbox ? (
                  <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item">
                    <a
                      href="#cart"
                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__trigger"
                      data-secondary="cart"
                    >
                      <svg
                        viewBox="0 0 16 16"
                        className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__trigger__icon"
                        aria-hidden="true"
                      >
                        <path
                          d="M5 16c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm8 0c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zM4.33 0l.15.82L4.7 2h11.28l-.25 1.2-1.25 6-.17.8H6.15l.18 1H14c.51 0 .94.39.99.88L15 12c0 .55-.45 1-1 1H4.67l-.15-.82L2.66 2H1c-.51 0-.94-.39-.99-.88L0 1c0-.55.45-1 1-1h3.33zm1.46 8h6.9l.83-4H5.06l.73 4z"
                          fillRule="evenodd"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__trigger__copy">
                        Cart
                      </span>
                    </a>
                  </li>
                ) : (
                  ""
                )}
                <li
                  className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item${
                    activeMainLink == "language-panel-id-0"
                      ? " n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item--active"
                      : ""
                  }`}
                >
                  <button
                    id="language-trigger-id-0"
                    aria-controls="language-panel-id-0"
                    data-triggerid="main-container"
                    className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__trigger"
                    onClick={() => handleActiveLink("language-panel-id-0")}
                  >
                    <svg
                      viewBox="0 0 16 16"
                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__trigger__icon"
                      aria-hidden="true"
                    >
                      <path d="M8 0c4.08 0 7.45 3.05 7.94 7H16v2h-.06c-.49 3.95-3.86 7-7.94 7-4.42 0-8-3.58-8-8s3.58-8 8-8zm.99 9H7.01c.08 2.84.49 5 .99 5s.91-2.16.99-5zM5.01 9H2.08a6.003 6.003 0 0 0 3.32 4.41c-.26-1.12-.37-2.6-.39-4.41zm8.91 0H11c-.03 1.81-.13 3.29-.39 4.41A6.039 6.039 0 0 0 13.92 9zM5.4 2.59l-.12.06A6.032 6.032 0 0 0 2.08 7H5c.03-1.81.14-3.29.4-4.41zM8 2c-.5 0-.91 2.16-.99 5h1.97c-.07-2.74-.46-4.86-.93-4.99L8 2zm2.6.59v.02c.26 1.12.37 2.59.39 4.39h2.92a5.991 5.991 0 0 0-3.31-4.41z" />
                    </svg>
                    <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__trigger__copy">
                      Language
                    </span>
                  </button>
                  <div
                    id="language-panel-id-0"
                    className={`n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel`}
                  >
                    <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer--language">
                      <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__heading">
                        <button
                          className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__heading__previous"
                          onClick={all}
                        >
                          <svg viewBox="0 0 24 24">
                            <path d="M20.235 7.298a1 1 0 0 0-1.41 0L12 13.875 5.255 7.344a1 1 0 1 0-1.4 1.42L12 16.574l8.215-7.866a1 1 0 0 0 .02-1.41Z" />
                          </svg>
                          All
                        </button>
                        <span className="u-h2 n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__heading__category">
                          Language
                        </span>
                      </div>
                      <div className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent">
                        <ul className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList">
                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item--active">
                            <a
                              href="#"
                              className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item__trigger"
                            >
                              English
                            </a>
                          </li>
                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item">
                            <a
                              href="#"
                              className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item__trigger"
                            >
                              Bahasa (Indonesia)
                            </a>
                          </li>
                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item">
                            <a
                              href="#"
                              className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item__trigger"
                            >
                              Deutsch
                            </a>
                          </li>
                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item">
                            <a
                              href="#"
                              className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item__trigger"
                            >
                              Español
                            </a>
                          </li>
                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item">
                            <a
                              href="#"
                              className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item__trigger"
                            >
                              Español (Latam)
                            </a>
                          </li>
                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item">
                            <a
                              href="#"
                              className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item__trigger"
                            >
                              Français
                            </a>
                          </li>
                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item">
                            <a
                              href="#"
                              className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item__trigger"
                            >
                              Italiano
                            </a>
                          </li>
                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item">
                            <a
                              href="#"
                              className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item__trigger"
                            >
                              Polski
                            </a>
                          </li>
                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item">
                            <a
                              href="#"
                              className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item__trigger"
                            >
                              Português (Brasil)
                            </a>
                          </li>
                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item">
                            <a
                              href="#"
                              className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item__trigger"
                            >
                              Pyccкий
                            </a>
                          </li>
                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item">
                            <a
                              href="#"
                              className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item__trigger"
                            >
                              Tiếng Việt
                            </a>
                          </li>
                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item">
                            <a
                              href="#"
                              className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item__trigger"
                            >
                              Türkçe
                            </a>
                          </li>
                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item">
                            <a
                              href="#"
                              className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item__trigger"
                            >
                              Українська
                            </a>
                          </li>
                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item">
                            <a
                              href="#"
                              className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item__trigger"
                            >
                              ไทย
                            </a>
                          </li>
                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item">
                            <a
                              href="#"
                              className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item__trigger"
                            >
                              한국어
                            </a>
                          </li>
                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item">
                            <a
                              href="#"
                              className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item__trigger"
                            >
                              中文 (简体)
                            </a>
                          </li>
                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item">
                            <a
                              href="#"
                              className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item__trigger"
                            >
                              中文 (繁體)
                            </a>
                          </li>

                          <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item">
                            <a
                              href="#"
                              className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__panel__subContainer__subContent__subList__item__trigger"
                            >
                              日本語
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item">
                  <button
                    className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__trigger"
                    data-secondary="chat"
                  >
                    <svg
                      viewBox="0 0 16 16"
                      className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__trigger__icon"
                      aria-hidden="true"
                    >
                      <path d="M8 0c4.42 0 8 3.13 8 7s-3.58 7-8 7c0 0-2.33.67-7 2 .5-2.17.86-3.6 1.07-4.3C.78 10.46 0 8.81 0 7c0-3.87 3.58-7 8-7zm0 2C4.63 2 2 4.3 2 7c0 1.2.51 2.35 1.46 3.26l.88.85-.35 1.17c-.05.18-.12.42-.2.71l-.04.15.1-.03c1.87-.53 2.47-.71 3.02-.86l.17-.05c.02-.01.04-.01.06-.02l.12-.03c.08-.02.16-.05.25-.07l.25-.08H8c3.37 0 6-2.3 6-5s-2.63-5-6-5z" />
                    </svg>
                    <span className="n-megaMenuNavigation__mobile__mainContainer__mainContent__secondaryList__item__trigger__copy">
                      Live Chat
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="n-megaMenuNavigation__desktop">
          <div className="n-megaMenuNavigation__desktop__mainList">
            <ul className="n-megaMenuNavigation__desktop__mainList__links">
              {contentOptions.navlinks &&
              contentOptions.navlinks[0] &&
              contentOptions.navlinks[0].checkbox ? (
                <li
                  id="navlink0"
                  className={`n-megaMenuNavigation__desktop__mainList__links__item${
                    contentOptions.openpanel && contentOptions.openpanel.selected == "Products"
                      ? " n-megaMenuNavigation__desktop__mainList__links__item--active"
                      : ""
                  }`}
                >
                  <a
                    href="#"
                    className="n-megaMenuNavigation__desktop__mainList__links__item__trigger"
                  >
                    <span className="n-megaMenuNavigation__desktop__mainList__links__item__trigger__copy">
                      {contentOptions.navlinks[0].field[contentOptions.currentLang]}
                    </span>
                  </a>
                </li>
              ) : (
                ""
              )}
              {contentOptions.navlinks &&
              contentOptions.navlinks[1] &&
              contentOptions.navlinks[1].checkbox ? (
                <li
                  id="navlink1"
                  className={`n-megaMenuNavigation__desktop__mainList__links__item${
                    contentOptions.openpanel && contentOptions.openpanel.selected == "Solutions"
                      ? " n-megaMenuNavigation__desktop__mainList__links__item--active"
                      : ""
                  }`}
                >
                  <a
                    href="#"
                    className="n-megaMenuNavigation__desktop__mainList__links__item__trigger"
                  >
                    <span className="n-megaMenuNavigation__desktop__mainList__links__item__trigger__copy">
                      {contentOptions.navlinks[1].field[contentOptions.currentLang]}
                    </span>
                  </a>
                </li>
              ) : (
                ""
              )}
              {contentOptions.navlinks &&
              contentOptions.navlinks[2] &&
              contentOptions.navlinks[2].checkbox ? (
                <li
                  id="navlink2"
                  className={`n-megaMenuNavigation__desktop__mainList__links__item${
                    contentOptions.openpanel && contentOptions.openpanel.selected == "Support"
                      ? " n-megaMenuNavigation__desktop__mainList__links__item--active"
                      : ""
                  }`}
                >
                  <a
                    href="#"
                    className="n-megaMenuNavigation__desktop__mainList__links__item__trigger"
                  >
                    <span className="n-megaMenuNavigation__desktop__mainList__links__item__trigger__copy">
                      {contentOptions.navlinks[2].field[contentOptions.currentLang]}
                    </span>
                  </a>
                </li>
              ) : (
                ""
              )}
              {contentOptions.navlinks &&
              contentOptions.navlinks[3] &&
              contentOptions.navlinks[3].checkbox ? (
                <li
                  id="navlink3"
                  className={`n-megaMenuNavigation__desktop__mainList__links__item${
                    contentOptions.openpanel && contentOptions.openpanel.selected == "Company"
                      ? " n-megaMenuNavigation__desktop__mainList__links__item--active"
                      : ""
                  }`}
                >
                  <a
                    href="#"
                    className="n-megaMenuNavigation__desktop__mainList__links__item__trigger"
                  >
                    <span className="n-megaMenuNavigation__desktop__mainList__links__item__trigger__copy">
                      {contentOptions.navlinks[3].field[contentOptions.currentLang]}
                    </span>
                  </a>
                </li>
              ) : (
                ""
              )}
              {contentOptions.navlinks &&
              contentOptions.navlinks[4] &&
              contentOptions.navlinks[4].checkbox ? (
                <li
                  id="navlink4"
                  className={`n-megaMenuNavigation__desktop__mainList__links__item${
                    contentOptions.openpanel && contentOptions.openpanel.selected == "Blog"
                      ? " n-megaMenuNavigation__desktop__mainList__links__item--active"
                      : ""
                  }`}
                >
                  <a
                    href="#"
                    className="n-megaMenuNavigation__desktop__mainList__links__item__trigger"
                  >
                    <span className="n-megaMenuNavigation__desktop__mainList__links__item__trigger__copy">
                      {contentOptions.navlinks[4].field[contentOptions.currentLang]}
                    </span>
                  </a>
                </li>
              ) : (
                ""
              )}

              {contentOptions.navlinks &&
              contentOptions.navlinks[5] &&
              contentOptions.navlinks[5].checkbox ? (
                <li
                  id="navlink5"
                  className={`n-megaMenuNavigation__desktop__mainList__links__item${
                    contentOptions.openpanel && contentOptions.openpanel.selected == "Other"
                      ? " n-megaMenuNavigation__desktop__mainList__links__item--active"
                      : ""
                  }`}
                >
                  <a
                    href="#"
                    className="n-megaMenuNavigation__desktop__mainList__links__item__trigger"
                  >
                    <span className="n-megaMenuNavigation__desktop__mainList__links__item__trigger__copy">
                      {contentOptions.navlinks[5].field[contentOptions.currentLang]}
                    </span>
                  </a>
                </li>
              ) : (
                ""
              )}
            </ul>
            <div
              className="n-megaMenuNavigation__desktop__mainList__panel"
              style={{
                height: `${
                  contentOptions.openpanel && contentOptions.openpanel.selected == "Products"
                    ? "553px"
                    : (contentOptions.openpanel &&
                          contentOptions.openpanel.selected == "Solutions") ||
                        (contentOptions.openpanel && contentOptions.openpanel.selected == "Support")
                      ? "229px"
                      : contentOptions.openpanel && contentOptions.openpanel.selected == "Company"
                        ? "433px"
                        : contentOptions.openpanel && contentOptions.openpanel.selected == "Blog"
                          ? "325px"
                          : contentOptions.openpanel && contentOptions.openpanel.selected == "Other"
                            ? "229px"
                            : contentOptions.openpanel &&
                                contentOptions.openpanel.selected == "Search"
                              ? "508px"
                              : contentOptions.openpanel &&
                                  contentOptions.openpanel.selected == "Languages"
                                ? "353px"
                                : contentOptions.openpanel &&
                                    contentOptions.openpanel.selected == "Cart"
                                  ? "1051px"
                                  : ""
                }`,
              }}
            >
              <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop">
                {contentOptions.navlinks &&
                contentOptions.navlinks[0] &&
                contentOptions.navlinks[0].checkbox ? (
                  <div
                    id="navcontent0"
                    className={`n-megaMenuNavigation__desktop__mainList__panel__desktop__content${
                      contentOptions.openpanel && contentOptions.openpanel.selected == "Products"
                        ? ""
                        : " u-hide"
                    }`}
                  >
                    {contentOptions.numcategories && contentOptions.numcategories.selected >= 1 ? (
                      <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                        <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                          {contentOptions.memorylinks &&
                            contentOptions.memorylinks[0] &&
                            contentOptions.memorylinks[0].field[contentOptions.currentLang]}
                        </span>
                        <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                          {contentOptions.memory.selected >= 1 ? (
                            <li>
                              <a href="#">
                                {contentOptions.memorylinks &&
                                  contentOptions.memorylinks[1] &&
                                  contentOptions.memorylinks[1].field[contentOptions.currentLang]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.memory.selected >= 2 ? (
                            <li>
                              <a href="#">
                                {contentOptions.memorylinks &&
                                  contentOptions.memorylinks[2] &&
                                  contentOptions.memorylinks[2].field[contentOptions.currentLang]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.memory.selected >= 3 ? (
                            <li>
                              <a href="#">
                                {contentOptions.memorylinks &&
                                  contentOptions.memorylinks[3] &&
                                  contentOptions.memorylinks[3].field[contentOptions.currentLang]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.memory.selected >= 4 ? (
                            <li>
                              <a href="#">
                                {contentOptions.memorylinks &&
                                  contentOptions.memorylinks[4] &&
                                  contentOptions.memorylinks[4].field[contentOptions.currentLang]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.memory.selected >= 5 ? (
                            <li>
                              <a href="#">
                                {contentOptions.memorylinks &&
                                  contentOptions.memorylinks[5] &&
                                  contentOptions.memorylinks[5].field[contentOptions.currentLang]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.memory.selected >= 6 ? (
                            <li>
                              <a href="#">
                                {contentOptions.memorylinks &&
                                  contentOptions.memorylinks[6] &&
                                  contentOptions.memorylinks[6].field[contentOptions.currentLang]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.memory.selected >= 7 ? (
                            <li>
                              <a href="#">Link</a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.memory.selected == 8 ? (
                            <li>
                              <a href="#">Link</a>
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}

                    {contentOptions.numcategories && contentOptions.numcategories.selected >= 2 ? (
                      <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                        <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                          {contentOptions.memorycardlinks &&
                            contentOptions.memorycardlinks[0] &&
                            contentOptions.memorycardlinks[0].field[contentOptions.currentLang]}
                        </span>
                        <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                          {contentOptions.memorycards.selected >= 1 ? (
                            <li>
                              <a href="#">
                                {contentOptions.memorycardlinks &&
                                  contentOptions.memorycardlinks[1] &&
                                  contentOptions.memorycardlinks[1].field[
                                    contentOptions.currentLang
                                  ]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.memorycards.selected >= 2 ? (
                            <li>
                              <a href="#">
                                {contentOptions.memorycardlinks &&
                                  contentOptions.memorycardlinks[2] &&
                                  contentOptions.memorycardlinks[2].field[
                                    contentOptions.currentLang
                                  ]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.memorycards.selected >= 3 ? (
                            <li>
                              <a href="#">
                                {contentOptions.memorycardlinks &&
                                  contentOptions.memorycardlinks[3] &&
                                  contentOptions.memorycardlinks[3].field[
                                    contentOptions.currentLang
                                  ]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.memorycards.selected >= 4 ? (
                            <li>
                              <a href="#">
                                {contentOptions.memorycardlinks &&
                                  contentOptions.memorycardlinks[4] &&
                                  contentOptions.memorycardlinks[4].field[
                                    contentOptions.currentLang
                                  ]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.memorycards.selected >= 5 ? (
                            <li>
                              <a href="#">Link</a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.memorycards.selected >= 6 ? (
                            <li>
                              <a href="#">Link</a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.memorycards.selected >= 7 ? (
                            <li>
                              <a href="#">Link</a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.memorycards.selected == 8 ? (
                            <li>
                              <a href="#">Link</a>
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}

                    {contentOptions.numcategories && contentOptions.numcategories.selected >= 3 ? (
                      <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                        <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                          {contentOptions.ssdslinks &&
                            contentOptions.ssdslinks[0] &&
                            contentOptions.ssdslinks[0].field[contentOptions.currentLang]}
                        </span>
                        <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                          {contentOptions.ssds.selected >= 1 ? (
                            <li>
                              <a href="#">
                                {contentOptions.ssdslinks &&
                                  contentOptions.ssdslinks[1] &&
                                  contentOptions.ssdslinks[1].field[contentOptions.currentLang]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.ssds.selected >= 2 ? (
                            <li>
                              <a href="#">
                                {contentOptions.ssdslinks &&
                                  contentOptions.ssdslinks[2] &&
                                  contentOptions.ssdslinks[2].field[contentOptions.currentLang]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.ssds.selected >= 3 ? (
                            <li>
                              <a href="#">
                                {contentOptions.ssdslinks &&
                                  contentOptions.ssdslinks[3] &&
                                  contentOptions.ssdslinks[3].field[contentOptions.currentLang]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.ssds.selected >= 4 ? (
                            <li>
                              <a href="#">
                                {contentOptions.ssdslinks &&
                                  contentOptions.ssdslinks[4] &&
                                  contentOptions.ssdslinks[4].field[contentOptions.currentLang]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.ssds.selected >= 5 ? (
                            <li>
                              <a href="#">
                                {contentOptions.ssdslinks &&
                                  contentOptions.ssdslinks[5] &&
                                  contentOptions.ssdslinks[5].field[contentOptions.currentLang]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.ssds.selected >= 6 ? (
                            <li>
                              <a href="#">
                                {contentOptions.ssdslinks &&
                                  contentOptions.ssdslinks[6] &&
                                  contentOptions.ssdslinks[6].field[contentOptions.currentLang]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.ssds.selected >= 7 ? (
                            <li>
                              <a href="#">
                                {contentOptions.ssdslinks &&
                                  contentOptions.ssdslinks[7] &&
                                  contentOptions.ssdslinks[7].field[contentOptions.currentLang]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.ssds.selected == 8 ? (
                            <li>
                              <a href="#">Link</a>
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}

                    {contentOptions.numcategories && contentOptions.numcategories.selected >= 4 ? (
                      <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                        <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                          {contentOptions.readerlinks &&
                            contentOptions.readerlinks[0] &&
                            contentOptions.readerlinks[0].field[contentOptions.currentLang]}
                        </span>
                        <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                          {contentOptions.readers.selected >= 1 ? (
                            <li>
                              <a href="#">
                                {contentOptions.readerlinks &&
                                  contentOptions.readerlinks[1] &&
                                  contentOptions.readerlinks[1].field[contentOptions.currentLang]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.readers.selected >= 2 ? (
                            <li>
                              <a href="#">
                                {contentOptions.readerlinks &&
                                  contentOptions.readerlinks[2] &&
                                  contentOptions.readerlinks[2].field[contentOptions.currentLang]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.readers.selected >= 3 ? (
                            <li>
                              <a href="#">
                                {contentOptions.readerlinks &&
                                  contentOptions.readerlinks[3] &&
                                  contentOptions.readerlinks[3].field[contentOptions.currentLang]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.readers.selected >= 4 ? (
                            <li>
                              <a href="#">Link</a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.readers.selected >= 5 ? (
                            <li>
                              <a href="#">Link</a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.readers.selected >= 6 ? (
                            <li>
                              <a href="#">Link</a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.readers.selected >= 7 ? (
                            <li>
                              <a href="#">Link</a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.readers.selected == 8 ? (
                            <li>
                              <a href="#">Link</a>
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}

                    {contentOptions.numcategories && contentOptions.numcategories.selected >= 5 ? (
                      <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                        <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                          {contentOptions.usbstoragelinks &&
                            contentOptions.usbstoragelinks[0] &&
                            contentOptions.usbstoragelinks[0].field[contentOptions.currentLang]}
                        </span>
                        <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                          {contentOptions.usbstorage.selected >= 1 ? (
                            <li>
                              <a href="#">
                                {contentOptions.usbstoragelinks &&
                                  contentOptions.usbstoragelinks[1] &&
                                  contentOptions.usbstoragelinks[1].field[
                                    contentOptions.currentLang
                                  ]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          <li>
                            {contentOptions.usbstorage.selected >= 2 ? (
                              <a href="#">
                                {contentOptions.usbstoragelinks &&
                                  contentOptions.usbstoragelinks[2] &&
                                  contentOptions.usbstoragelinks[2].field[
                                    contentOptions.currentLang
                                  ]}
                              </a>
                            ) : (
                              ""
                            )}
                          </li>
                          {contentOptions.usbstorage.selected >= 3 ? (
                            <li>
                              <a href="#">Link</a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.usbstorage.selected >= 4 ? (
                            <li>
                              <a href="#">Link</a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.usbstorage.selected >= 5 ? (
                            <li>
                              <a href="#">Link</a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.usbstorage.selected >= 6 ? (
                            <li>
                              <a href="#">Link</a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.usbstorage.selected >= 7 ? (
                            <li>
                              <a href="#">Link</a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.usbstorage.selected == 8 ? (
                            <li>
                              <a href="#">Link</a>
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}

                    {contentOptions.numcategories && contentOptions.numcategories.selected >= 6 ? (
                      <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                        <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                          {contentOptions.embeddedindustriallinks &&
                            contentOptions.embeddedindustriallinks[0] &&
                            contentOptions.embeddedindustriallinks[0].field[
                              contentOptions.currentLang
                            ]}
                        </span>
                        <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                          {contentOptions.embeddedindustrial.selected >= 1 ? (
                            <li>
                              <a href="#">
                                {contentOptions.embeddedindustriallinks &&
                                  contentOptions.embeddedindustriallinks[1] &&
                                  contentOptions.embeddedindustriallinks[1].field[
                                    contentOptions.currentLang
                                  ]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.embeddedindustrial.selected >= 2 ? (
                            <li>
                              <a href="#">
                                {contentOptions.embeddedindustriallinks &&
                                  contentOptions.embeddedindustriallinks[2] &&
                                  contentOptions.embeddedindustriallinks[2].field[
                                    contentOptions.currentLang
                                  ]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.embeddedindustrial.selected >= 3 ? (
                            <li>
                              <a href="#">
                                {contentOptions.embeddedindustriallinks &&
                                  contentOptions.embeddedindustriallinks[3] &&
                                  contentOptions.embeddedindustriallinks[3].field[
                                    contentOptions.currentLang
                                  ]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.embeddedindustrial.selected >= 4 ? (
                            <li>
                              <a href="#">
                                {contentOptions.embeddedindustriallinks &&
                                  contentOptions.embeddedindustriallinks[4] &&
                                  contentOptions.embeddedindustriallinks[4].field[
                                    contentOptions.currentLang
                                  ]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.embeddedindustrial.selected >= 5 ? (
                            <li>
                              <a href="#">
                                {contentOptions.embeddedindustriallinks &&
                                  contentOptions.embeddedindustriallinks[5] &&
                                  contentOptions.embeddedindustriallinks[5].field[
                                    contentOptions.currentLang
                                  ]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.embeddedindustrial.selected >= 6 ? (
                            <li>
                              <a href="#">
                                {contentOptions.embeddedindustriallinks &&
                                  contentOptions.embeddedindustriallinks[6] &&
                                  contentOptions.embeddedindustriallinks[6].field[
                                    contentOptions.currentLang
                                  ]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.embeddedindustrial.selected >= 7 ? (
                            <li>
                              <a href="#">
                                {contentOptions.embeddedindustriallinks &&
                                  contentOptions.embeddedindustriallinks[7] &&
                                  contentOptions.embeddedindustriallinks[7].field[
                                    contentOptions.currentLang
                                  ]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.embeddedindustrial.selected == 8 ? (
                            <li>
                              <a href="#">
                                {contentOptions.embeddedindustriallinks &&
                                  contentOptions.embeddedindustriallinks[8] &&
                                  contentOptions.embeddedindustriallinks[8].field[
                                    contentOptions.currentLang
                                  ]}
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}

                    {contentOptions.numcategories && contentOptions.numcategories.selected == 10 ? (
                      <>
                        <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                          <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                            Category 1
                          </span>
                          <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                          </ul>
                        </div>

                        <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                          <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                            Category 2
                          </span>
                          <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                          </ul>
                        </div>

                        <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                          <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                            Category 3
                          </span>
                          <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                          </ul>
                        </div>

                        <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                          <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                            Category 4
                          </span>
                          <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                            <li>
                              <a href="#">Link</a>
                            </li>
                          </ul>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                {contentOptions.navlinks &&
                contentOptions.navlinks[1] &&
                contentOptions.navlinks[1].checkbox ? (
                  <div
                    id="navcontent1"
                    className={`n-megaMenuNavigation__desktop__mainList__panel__desktop__content${
                      contentOptions.openpanel && contentOptions.openpanel.selected == "Solutions"
                        ? ""
                        : " u-hide"
                    }`}
                  >
                    <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                      <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                        Personal
                      </span>
                      <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                        <li>
                          <a href="#">Mobile lifestyle</a>
                        </li>
                        <li>
                          <a href="#">Personal storage</a>
                        </li>
                        <li>
                          <a href="#">PC performance</a>
                        </li>
                        <li>
                          <a href="#">Gaming</a>
                        </li>
                      </ul>
                    </div>

                    <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                      <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                        Business
                      </span>
                      <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                        <li>
                          <a href="#">Server/Data center</a>
                        </li>
                        <li>
                          <a href="#">Data security</a>
                        </li>
                        <li>
                          <a href="#">System builders</a>
                        </li>
                        <li>
                          <a href="#">Embedded/Industrial</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {contentOptions.navlinks &&
                contentOptions.navlinks[2] &&
                contentOptions.navlinks[2].checkbox ? (
                  <div
                    id="navcontent2"
                    className={`n-megaMenuNavigation__desktop__mainList__panel__desktop__content${
                      contentOptions.openpanel && contentOptions.openpanel.selected == "Support"
                        ? ""
                        : " u-hide"
                    }`}
                  >
                    <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                      <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                        Product support
                      </span>
                      <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                        <li>
                          <a href="#">Get support</a>
                        </li>
                        <li>
                          <a href="#">Warranty</a>
                        </li>
                        <li>
                          <a href="#">Product verification</a>
                        </li>
                        <li>
                          <a href="#">RMA (end users)</a>
                        </li>
                      </ul>
                    </div>

                    <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                      <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                        Where to buy
                      </span>
                      <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                        <li>
                          <a href="#">Where to buy</a>
                        </li>
                      </ul>
                    </div>

                    <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                      <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                        Partner support
                      </span>
                      <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                        <li>
                          <a href="#">KingstonCare</a>
                        </li>
                        <li>
                          <a href="#">Reseller RMA</a>
                        </li>
                        <li>
                          <a href="#">EMEA learning center</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {contentOptions.navlinks &&
                contentOptions.navlinks[3] &&
                contentOptions.navlinks[3].checkbox ? (
                  <div
                    id="navcontent3"
                    className={`n-megaMenuNavigation__desktop__mainList__panel__desktop__content${
                      contentOptions.openpanel && contentOptions.openpanel.selected == "Company"
                        ? ""
                        : " u-hide"
                    }`}
                  >
                    <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                      <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                        About Kingston
                      </span>
                      <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                        <li>
                          <a href="#">Who we are</a>
                        </li>
                        <li>
                          <a href="#">
                            Kingston is with you<sup className="u-txt-trademark">&trade;</sup>
                            <sup className="u-txt-copyright">&copy;</sup>
                            <sup className="u-txt-registered">&reg;</sup>
                          </a>
                        </li>
                        <li>
                          <a href="#">Divisions of Kingston</a>
                        </li>
                        <li>
                          <a href="#">Kingston worldwide</a>
                        </li>
                        <li>
                          <a href="#">History of Kingston</a>
                        </li>
                        <li>
                          <a href="#">Kingston overview</a>
                        </li>
                        <li>
                          <a href="#">Awards & recognition</a>
                        </li>
                        <li>
                          <a href="#">Market share</a>
                        </li>
                      </ul>
                    </div>

                    <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                      <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                        Compliance
                      </span>
                      <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                        <li>
                          <a href="#">Product compliance</a>
                        </li>
                        <li>
                          <a href="#">Management systems</a>
                        </li>
                        <li>
                          <a href="#">Environmental health & safety</a>
                        </li>
                        <li>
                          <a href="#">Compliance overview</a>
                        </li>
                      </ul>
                    </div>

                    <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                      <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                        Legal
                      </span>
                      <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                        <li>
                          <a href="#">Legal statement</a>
                        </li>
                        <li>
                          <a href="#">Privacy policy</a>
                        </li>
                        <li>
                          <a href="#">Cookie policy</a>
                        </li>
                      </ul>
                    </div>

                    <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                      <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                        Press
                      </span>
                      <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                        <li>
                          <a href="#">Press releases</a>
                        </li>
                        <li>
                          <a href="#">Press images</a>
                        </li>
                        <li>
                          <a href="#">PR contacts</a>
                        </li>
                      </ul>
                    </div>

                    <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                      <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                        Sustainability
                      </span>
                      <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                        <li>
                          <a href="#">Management systems</a>
                        </li>
                        <li>
                          <a href="#">Transparency reporting</a>
                        </li>
                        <li>
                          <a href="#">Kingston Supplier Code of Conduct</a>
                        </li>
                        <li>
                          <a href="#">Sustainability overview</a>
                        </li>
                      </ul>
                    </div>

                    <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                      <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                        Careers
                      </span>
                      <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                        <li>
                          <a href="#">Careers at Kingston</a>
                        </li>
                      </ul>
                    </div>

                    <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                      <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                        Contacts
                      </span>
                      <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                        <li>
                          <a href="#">Contact info</a>
                        </li>
                      </ul>
                    </div>

                    <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                      <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                        Why choose Kingston?
                      </span>
                      <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                        <li>
                          <a href="#">Why choose Kingston?</a>
                        </li>
                        <li>
                          <a href="#">Testing</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {contentOptions.navlinks &&
                contentOptions.navlinks[4] &&
                contentOptions.navlinks[4].checkbox ? (
                  <div
                    id="navcontent4"
                    className={`n-megaMenuNavigation__desktop__mainList__panel__desktop__content${
                      contentOptions.openpanel && contentOptions.openpanel.selected == "Blog"
                        ? ""
                        : " u-hide"
                    }`}
                  >
                    <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                      <span className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__category">
                        Knowledge center
                      </span>
                      <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                        <li>
                          <a href="#">All articles</a>
                        </li>
                        <li>
                          <a href="#">Gaming</a>
                        </li>
                        <li>
                          <a href="#">Servers/Data centers</a>
                        </li>
                        <li>
                          <a href="#">Data security</a>
                        </li>
                        <li>
                          <a href="#">Mobile lifestyle</a>
                        </li>
                        <li>
                          <a href="#">Personal storage</a>
                        </li>
                        <li>
                          <a href="#">PC performance</a>
                        </li>
                        <li>
                          <a href="#">System builders</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {contentOptions.navlinks &&
                contentOptions.navlinks[5] &&
                contentOptions.navlinks[5].checkbox ? (
                  <div
                    id="navcontent5"
                    className={`n-megaMenuNavigation__desktop__mainList__panel__desktop__content${
                      contentOptions.openpanel && contentOptions.openpanel.selected == "Other"
                        ? ""
                        : " u-hide"
                    }`}
                  >
                    <div className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group">
                      <ul className="n-megaMenuNavigation__desktop__mainList__panel__desktop__content__group__list">
                        <li>
                          <a href="#">Link 1</a>
                        </li>
                        <li>
                          <a href="#">Link 2</a>
                        </li>
                        <li>
                          <a href="#">Link 3</a>
                        </li>
                        <li>
                          <a href="#">Link 4</a>
                        </li>
                        <li>
                          <a href="#">Link 5</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                className={`n-megaMenuNavigation__desktop__mainList__panel__sideMenu${
                  (contentOptions.openpanel && contentOptions.openpanel.selected == "Products") ||
                  (contentOptions.openpanel && contentOptions.openpanel.selected == "Solutions") ||
                  (contentOptions.openpanel && contentOptions.openpanel.selected == "Support") ||
                  (contentOptions.openpanel && contentOptions.openpanel.selected == "Company") ||
                  (contentOptions.openpanel && contentOptions.openpanel.selected == "Blog") ||
                  (contentOptions.openpanel && contentOptions.openpanel.selected == "Other")
                    ? " u-hide"
                    : ""
                }`}
              >
                <div
                  id="sidemenucontent0"
                  className={`n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content--search${
                    contentOptions.openpanel && contentOptions.openpanel.selected == "Search"
                      ? ""
                      : " u-hide"
                  }`}
                >
                  <form
                    role="search"
                    action="/"
                    method="GET"
                    className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__search"
                  >
                    <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__search__heading">
                      <button
                        type="button"
                        className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__search__heading__close"
                        aria-label="Close Search"
                        tabIndex="-1"
                        onClick={() => setActiveSideMenu("")}
                      >
                        <svg viewBox="0 0 24 24">
                          <path d="m17.614 16.194-4.25-4.19 3.54-3.58.71-.71a1.004 1.004 0 1 0-1.42-1.42l-.7.71-3.49 3.54-4.29-4.25a1.004 1.004 0 0 0-1.42 1.42l4.25 4.29-3.54 3.49-.71.7a1.004 1.004 0 0 0 1.42 1.42l.71-.71 3.58-3.54 4.24 4.25a1.004 1.004 0 1 0 1.42-1.42h-.05Z" />
                        </svg>
                      </button>
                    </div>
                    <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__search__field">
                      <input
                        ref={searchRef}
                        className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__search__field__input"
                        placeholder="Search"
                        aria-label="Search"
                        autoCorrect="off"
                        autoCapitalize="off"
                        autoComplete="off"
                        onChange={() => {
                          setSearchResultsOpen(true);
                        }}
                      />
                      <button
                        aria-label="Clear search"
                        tabIndex="-1"
                        type="reset"
                        className={`n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__search__field__clear ${
                          searchResults ? "" : "u-hide"
                        }`}
                        onClick={() => {
                          setSearchResultsOpen(false);
                        }}
                      >
                        <svg viewBox="0 0 24 24">
                          <path d="M11.988 0C5.362.006-.004 5.382 0 12.008.004 18.634 5.378 24.002 12.004 24 18.63 23.998 24 18.626 24 12 23.987 5.373 18.615.006 11.988 0Zm0 22.326c-5.696 0-10.314-4.618-10.314-10.314 0-5.697 4.618-10.314 10.314-10.314 5.697 0 10.314 4.617 10.314 10.314-.006 5.693-4.62 10.307-10.314 10.314ZM16.953 8.86a.267.267 0 0 0 0-.383L15.36 6.884a.267.267 0 0 0-.383 0l-2.791 2.79a.267.267 0 0 1-.384 0l-2.79-2.79a.267.267 0 0 0-.384 0L7.07 8.442a.267.267 0 0 0 0 .384l2.79 2.802a.267.267 0 0 1 0 .384l-2.79 2.79a.267.267 0 0 0 0 .384l1.593 1.593a.267.267 0 0 0 .384 0l2.79-2.79a.267.267 0 0 1 .384 0l2.79 2.79a.267.267 0 0 0 .384 0l1.558-1.558a.267.267 0 0 0 0-.384l-2.79-2.79a.267.267 0 0 1 0-.384l2.79-2.803Z" />
                        </svg>
                      </button>
                      <button
                        aria-label="Submit search"
                        tabIndex="-1"
                        type="submit"
                        className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__search__field__submit"
                        aria-hidden="true"
                      >
                        <svg
                          viewBox="0 0 16 16"
                          className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__search__field__submit__icon"
                        >
                          <path d="M7 0c3.87 0 7 3.13 7 7 0 1.58-.52 3.03-1.4 4.2.04.03.07.06.11.09l3 3a.996.996 0 1 1-1.41 1.41l-3-3a.481.481 0 0 1-.09-.11A6.948 6.948 0 0 1 7 14c-3.87 0-7-3.13-7-7s3.13-7 7-7zm0 2C4.24 2 2 4.24 2 7s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5z" />
                        </svg>
                      </button>
                    </div>
                  </form>

                  {(contentOptions.suggestedLinks && contentOptions.suggestedLinks.checkbox) ||
                  recentSearch1 ||
                  recentSearch2 ? (
                    <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults">
                      {recentSearch1 || recentSearch2 ? (
                        <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__history">
                          <ul
                            className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__history__list"
                            aria-label="Recently searched products"
                          >
                            {recentSearch1 ? (
                              <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__history__list__item">
                                <a
                                  href="#"
                                  className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__history__list__item__link"
                                >
                                  <svg
                                    viewBox="0 0 32 32"
                                    className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__history__list__item__link__icon"
                                  >
                                    <path d="M16.424 7c-4.738 0-8.567 3.806-8.567 8.5H5l3.776 3.816L12.62 15.5H9.762c0-3.65 2.986-6.611 6.667-6.611 3.68 0 6.666 2.96 6.666 6.611 0 3.65-2.985 6.611-6.666 6.611a6.668 6.668 0 0 1-4.71-1.94l-1.348 1.336A8.553 8.553 0 0 0 16.424 24C21.162 24 25 20.194 25 15.5S21.162 7 16.424 7Zm-.948 4.722v4.722l4.076 2.4.686-1.148-3.333-1.96v-4.014h-1.429Z" />
                                  </svg>
                                  Recent Search 1
                                </a>
                                <button
                                  className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__history__list__item__remove"
                                  aria-label="Remove search history"
                                  onClick={() => setRecentSearch1(false)}
                                >
                                  <svg viewBox="0 0 24 24">
                                    <path d="m17.614 16.194-4.25-4.19 3.54-3.58.71-.71a1.004 1.004 0 1 0-1.42-1.42l-.7.71-3.49 3.54-4.29-4.25a1.004 1.004 0 0 0-1.42 1.42l4.25 4.29-3.54 3.49-.71.7a1.004 1.004 0 0 0 1.42 1.42l.71-.71 3.58-3.54 4.24 4.25a1.004 1.004 0 1 0 1.42-1.42h-.05Z" />
                                  </svg>
                                </button>
                              </li>
                            ) : (
                              ""
                            )}
                            {recentSearch2 ? (
                              <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__history__list__item">
                                <a
                                  href="#"
                                  className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__history__list__item__link"
                                >
                                  <svg
                                    viewBox="0 0 32 32"
                                    className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__history__list__item__link__icon"
                                  >
                                    <path d="M16.424 7c-4.738 0-8.567 3.806-8.567 8.5H5l3.776 3.816L12.62 15.5H9.762c0-3.65 2.986-6.611 6.667-6.611 3.68 0 6.666 2.96 6.666 6.611 0 3.65-2.985 6.611-6.666 6.611a6.668 6.668 0 0 1-4.71-1.94l-1.348 1.336A8.553 8.553 0 0 0 16.424 24C21.162 24 25 20.194 25 15.5S21.162 7 16.424 7Zm-.948 4.722v4.722l4.076 2.4.686-1.148-3.333-1.96v-4.014h-1.429Z" />
                                  </svg>
                                  Recent Search 2
                                </a>
                                <button
                                  className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__history__list__item__remove"
                                  aria-label="Remove search history"
                                  onClick={() => setRecentSearch2(false)}
                                >
                                  <svg viewBox="0 0 24 24">
                                    <path d="m17.614 16.194-4.25-4.19 3.54-3.58.71-.71a1.004 1.004 0 1 0-1.42-1.42l-.7.71-3.49 3.54-4.29-4.25a1.004 1.004 0 0 0-1.42 1.42l4.25 4.29-3.54 3.49-.71.7a1.004 1.004 0 0 0 1.42 1.42l.71-.71 3.58-3.54 4.24 4.25a1.004 1.004 0 1 0 1.42-1.42h-.05Z" />
                                  </svg>
                                </button>
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
                      {contentOptions.suggestedLinks && contentOptions.suggestedLinks.checkbox ? (
                        <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__suggested">
                          <span className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__suggested__title">
                            Suggested links
                          </span>
                          <ul className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__suggested__list">
                            <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__suggested__list__item">
                              <a
                                href="#"
                                className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__suggested__list__item__link"
                              >
                                Kingston FURY
                              </a>
                            </li>
                            <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__suggested__list__item">
                              <a
                                href="#"
                                className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__suggested__list__item__link"
                              >
                                Ironkey
                              </a>
                            </li>
                            <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__suggested__list__item">
                              <a
                                href="#"
                                className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__suggested__list__item__link"
                              >
                                DDR5
                              </a>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}

                      {searchResults ? (
                        <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results u-hide">
                          <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results__group">
                            <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results__group__topic">
                              Products
                            </div>
                            <ul className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results__group__list">
                              <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results__group__list__item">
                                <a href="#">
                                  DC1500M U.2 <span className="u-txt-bold">Enter</span>prise SSD
                                </a>
                              </li>
                              <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results__group__list__item">
                                <a href="#">
                                  DC450R 2.5&quot; <span className="u-txt-bold">Enter</span>prise
                                  SSD
                                </a>
                              </li>
                              <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results__group__list__item">
                                <a href="#">
                                  DC500 2.5&quot; <span className="u-txt-bold">Enter</span>prise SSD
                                </a>
                              </li>
                              <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results__group__list__item">
                                <a href="#">
                                  DC1500M U.2 <span className="u-txt-bold">Enter</span>prise SSD
                                </a>
                              </li>
                              <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results__group__list__item">
                                <a href="#">
                                  DC450R 2.5&quot; <span className="u-txt-bold">Enter</span>prise
                                  SSD
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results__group">
                            <a
                              href="#"
                              className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results__group__topic"
                            >
                              Systems
                              <svg viewBox="0 0 32 32">
                                <path d="M18.332 23.567a1.298 1.298 0 1 1-1.836-1.836l4.252-4.252L7.93 17.3a1.317 1.317 0 0 1-1.298-1.298 1.245 1.245 0 0 1 1.262-1.263l13.36.185-4.732-4.732a1.263 1.263 0 1 1 1.786-1.785L25.9 16l-7.567 7.567Z" />
                              </svg>
                            </a>
                            <ul className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results__group__list">
                              <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results__group__list__item">
                                <a href="#">Chromebook Enterprise Spin 713 (CP713xxx)</a>
                              </li>
                              <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results__group__list__item">
                                <a href="#">Veriton Essential S VES2735G</a>
                              </li>
                              <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results__group__list__item">
                                <a href="#">ASUSPRO ESSENTIAL P2420LA</a>
                              </li>
                            </ul>
                          </div>
                          <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results__group">
                            <a
                              href="#"
                              className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results__group__topic"
                            >
                              Related Pages
                              <svg viewBox="0 0 32 32">
                                <path d="M18.332 23.567a1.298 1.298 0 1 1-1.836-1.836l4.252-4.252L7.93 17.3a1.317 1.317 0 0 1-1.298-1.298 1.245 1.245 0 0 1 1.262-1.263l13.36.185-4.732-4.732a1.263 1.263 0 1 1 1.786-1.785L25.9 16l-7.567 7.567Z" />
                              </svg>
                            </a>
                            <ul className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results__group__list">
                              <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results__group__list__item">
                                <a href="#">
                                  Solid State Drives (SSDs) for Laptops, Desktop PCs, and
                                  Workstation
                                </a>
                              </li>
                              <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results__group__list__item">
                                <a href="#">
                                  DC500 2.5&quot; Enterprise SSD 6GBps SATA SSD for Read-Centric and
                                  Mixed-Use Drives
                                </a>
                              </li>
                              <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__searchResults__results__group__list__item">
                                <a href="#">
                                  DC450R 2.5&quot; Enterprise SSD 6GBps SATA Storage for
                                  Read-Centric Workloads
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  id="sidemenucontent1"
                  className={`n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content--languages${
                    contentOptions.openpanel && contentOptions.openpanel.selected == "Languages"
                      ? ""
                      : " u-hide"
                  }`}
                >
                  <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__group">
                    <span className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__group__category">
                      Language
                    </span>
                    <ul className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__group__list">
                      <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__group__list--active">
                        <a
                          href="#"
                          // className={`${activeLanguage == "English" ? "u-txt-bold" : ""}`}
                          onClick={() => handleActiveLanguage("English")}
                        >
                          English
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          // className={`${activeLanguage == "Bahasa" ? "u-txt-bold" : ""}`}
                          onClick={() => handleActiveLanguage("Bahasa")}
                        >
                          Bahasa (Indonesia)
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          // className={`${activeLanguage == "Deutsch" ? "u-txt-bold" : ""}`}
                          onClick={() => handleActiveLanguage("Deutsch")}
                        >
                          Deutsch
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          // className={`${activeLanguage == "Español" ? "u-txt-bold" : ""}`}
                          onClick={() => handleActiveLanguage("Español")}
                        >
                          Español
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          // className={`${activeLanguage == "Latam" ? "u-txt-bold" : ""}`}
                          onClick={() => handleActiveLanguage("Latam")}
                        >
                          Español (Latam)
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          // className={`${activeLanguage == "Français" ? "u-txt-bold" : ""}`}
                          onClick={() => handleActiveLanguage("Français")}
                        >
                          Français
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          // className={`${activeLanguage == "Italiano" ? "u-txt-bold" : ""}`}
                          onClick={() => handleActiveLanguage("Italiano")}
                        >
                          Italiano
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          // className={`${activeLanguage == "Polski" ? "u-txt-bold" : ""}`}
                          onClick={() => handleActiveLanguage("Polski")}
                        >
                          Polski
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          // className={`${activeLanguage == "Português" ? "u-txt-bold" : ""}`}
                          onClick={() => handleActiveLanguage("Português")}
                        >
                          Português (Brasil)
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          // className={`${activeLanguage == "Pyccкий" ? "u-txt-bold" : ""}`}
                          onClick={() => handleActiveLanguage("Pyccкий")}
                        >
                          Pyccкий
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          // className={`${activeLanguage == "Tiếng Việt" ? "u-txt-bold" : ""}`}
                          onClick={() => handleActiveLanguage("Tiếng Việt")}
                        >
                          Tiếng Việt
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          // className={`${activeLanguage == "Türkçe" ? "u-txt-bold" : ""}`}
                          onClick={() => handleActiveLanguage("Türkçe")}
                        >
                          Türkçe
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          // className={`${activeLanguage == "Українська" ? "u-txt-bold" : ""}`}
                          onClick={() => handleActiveLanguage("Українська")}
                        >
                          Українська
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          // className={`${activeLanguage == "ไทย" ? "u-txt-bold" : ""}`}
                          onClick={() => handleActiveLanguage("ไทย")}
                        >
                          ไทย
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          // className={`${activeLanguage == "한국어" ? "u-txt-bold" : ""}`}
                          onClick={() => handleActiveLanguage("한국어")}
                        >
                          한국어
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          // className={`${activeLanguage == "中文" ? "u-txt-bold" : ""}`}
                          onClick={() => handleActiveLanguage("中文")}
                        >
                          中文 (简体)
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          // className={`${activeLanguage == "繁體" ? "u-txt-bold" : ""}`}
                          onClick={() => handleActiveLanguage("繁體")}
                        >
                          中文 (繁體)
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          // className={`${activeLanguage == "日本語" ? "u-txt-bold" : ""}`}
                          onClick={() => handleActiveLanguage("日本語")}
                        >
                          日本語
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  id="sidemenucontent2"
                  className={`n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content--cart${
                    contentOptions.openpanel && contentOptions.openpanel.selected == "Cart"
                      ? ""
                      : " u-hide"
                  }`}
                >
                  <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart">
                    <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__main">
                      <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__main__body">
                        {contentOptions.numberOfProducts &&
                        contentOptions.numberOfProducts.selected > 0 ? (
                          <ul className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__main__body__cartList">
                            {contentOptions.numberOfProducts.selected >= 1 ? (
                              <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__main__body__cartList__item">
                                <div className={`c-cartItem`} data-kit="4">
                                  <div className={`c-cartItem__product`}>
                                    <img
                                      className="c-cartItem__product__image"
                                      src="https://media.kingston.com/images/products/DDR4_ECC_Load_Reduced_DIMM_4R_x4_1_tn.png"
                                      alt="Alternate Text"
                                      data-dropdown="true"
                                    />
                                    <div className="c-cartItem__product__title">
                                      4 GB DDR3 DIMM 240-pin Memory Module
                                    </div>
                                    <span
                                      className="c-cartItem__product__kit"
                                      role="term"
                                      aria-label={"Kit of " + 4}
                                    >
                                      x 4
                                    </span>
                                  </div>

                                  <div className="c-cartItem__quantity">
                                    <div className="c-cartItem__quantity__value">
                                      <div className="f-quantity js-quantity" id="jsQuantity0">
                                        <label className="f-quantity__label" htmlFor="quant1">
                                          Quantity
                                        </label>
                                        <div className="f-quantity__counter">
                                          <button
                                            className="f-quantity__counter__btn js-quantity__btn f-quantity__counter__btn--disable"
                                            aria-label="Decrease Quantity"
                                            tabIndex="-1"
                                          >
                                            <span className="f-quantity__counter__btn__inner">
                                              <svg viewBox="0 0 16 2">
                                                <title>Minus Icon</title>
                                                <path d="M0 0h16v2H0z"></path>
                                              </svg>
                                            </span>
                                          </button>
                                          <input
                                            className="f-quantity__counter__input"
                                            type="text"
                                            maxLength="4"
                                            value="1"
                                            id="quant1"
                                            name="fQuantity0"
                                          />
                                          <button
                                            className="f-quantity__counter__btn js-quantity__btn"
                                            aria-label="Increase Quantity"
                                            data-add="true"
                                          >
                                            <span className="f-quantity__counter__btn__inner">
                                              <svg viewBox="0 0 16 16">
                                                <title>Plus Icon</title>
                                                <path d="M0 7h16v2H0z"></path>
                                                <path d="M7 0h2v16H7z"></path>
                                              </svg>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="c-cartItem__quantity__total">
                                      <span className="u-txt-strike c-cartItem__quantity__total__strike">
                                        $60.00
                                      </span>
                                      <span className="c-cartItem__quantity__total__value">
                                        $54.99
                                      </span>
                                      <button
                                        className="c-cartItem__quantity__total__trash"
                                        aria-label="Remove from cart"
                                      >
                                        <svg viewBox="0 0 20 20">
                                          <path d="M5 18.695a.342.342 0 0 0 .336.305h9.328c.172 0 .32-.137.336-.305L16 6H4l1 12.695ZM16.68 2.992h-4.42C12.111 1.868 11.203 1 10.094 1c-1.108 0-2.016.868-2.164 1.992H3.32c-.18 0-.321.149-.321.337v1.334c0 .188.141.337.32.337h13.36c.179 0 .32-.149.32-.337V3.329a.327.327 0 0 0-.32-.337" />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {/* <C_CartItem title={"4 GB DDR3 DIMM 240-pin Memory Module"} kit={4} /> */}
                              </li>
                            ) : (
                              ""
                            )}

                            {contentOptions.numberOfProducts.selected >= 2 ? (
                              <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__main__body__cartList__item">
                                <div className={`c-cartItem`}>
                                  <div className={`c-cartItem__product`}>
                                    <img
                                      className="c-cartItem__product__image"
                                      src="https://media.kingston.com/images/products/DDR4_ECC_Load_Reduced_DIMM_4R_x4_1_tn.png"
                                      alt="Alternate Text"
                                      data-dropdown="true"
                                    />
                                    <div className="c-cartItem__product__title">
                                      4 GB DDR3 DIMM 240-pin Memory Module
                                    </div>
                                  </div>

                                  <div className="c-cartItem__quantity">
                                    <div className="c-cartItem__quantity__value">
                                      <div className="f-quantity js-quantity" id="jsQuantity0">
                                        <label className="f-quantity__label" htmlFor="quant1">
                                          Quantity
                                        </label>
                                        <div className="f-quantity__counter">
                                          <button
                                            className="f-quantity__counter__btn js-quantity__btn f-quantity__counter__btn--disable"
                                            aria-label="Decrease Quantity"
                                            tabIndex="-1"
                                          >
                                            <span className="f-quantity__counter__btn__inner">
                                              <svg viewBox="0 0 16 2">
                                                <title>Minus Icon</title>
                                                <path d="M0 0h16v2H0z"></path>
                                              </svg>
                                            </span>
                                          </button>
                                          <input
                                            className="f-quantity__counter__input"
                                            type="text"
                                            maxLength="4"
                                            value="1"
                                            id="quant1"
                                            name="fQuantity0"
                                          />
                                          <button
                                            className="f-quantity__counter__btn js-quantity__btn"
                                            aria-label="Increase Quantity"
                                            data-add="true"
                                          >
                                            <span className="f-quantity__counter__btn__inner">
                                              <svg viewBox="0 0 16 16">
                                                <title>Plus Icon</title>
                                                <path d="M0 7h16v2H0z"></path>
                                                <path d="M7 0h2v16H7z"></path>
                                              </svg>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="c-cartItem__quantity__total">
                                      <span className="c-cartItem__quantity__total__value">
                                        $54.99
                                      </span>
                                      <button
                                        className="c-cartItem__quantity__total__trash"
                                        aria-label="Remove from cart"
                                      >
                                        <svg viewBox="0 0 20 20">
                                          <path d="M5 18.695a.342.342 0 0 0 .336.305h9.328c.172 0 .32-.137.336-.305L16 6H4l1 12.695ZM16.68 2.992h-4.42C12.111 1.868 11.203 1 10.094 1c-1.108 0-2.016.868-2.164 1.992H3.32c-.18 0-.321.149-.321.337v1.334c0 .188.141.337.32.337h13.36c.179 0 .32-.149.32-.337V3.329a.327.327 0 0 0-.32-.337" />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ) : (
                              ""
                            )}

                            {contentOptions.numberOfProducts.selected >= 3 ? (
                              <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__main__body__cartList__item">
                                <div className={`c-cartItem`} data-kit="2">
                                  <div className={`c-cartItem__product`}>
                                    <img
                                      className="c-cartItem__product__image"
                                      src="https://media.kingston.com/images/products/DDR4_ECC_Load_Reduced_DIMM_4R_x4_1_tn.png"
                                      alt="Alternate Text"
                                      data-dropdown="true"
                                    />
                                    <div className="c-cartItem__product__title">
                                      4 GB DDR3 DIMM 240-pin Memory Module
                                    </div>
                                    <span
                                      className="c-cartItem__product__kit"
                                      role="term"
                                      aria-label={"Kit of " + 2}
                                    >
                                      x 2
                                    </span>
                                  </div>

                                  <div className="c-cartItem__quantity">
                                    <div className="c-cartItem__quantity__value">
                                      <div className="f-quantity js-quantity" id="jsQuantity0">
                                        <label className="f-quantity__label" htmlFor="quant1">
                                          Quantity
                                        </label>
                                        <div className="f-quantity__counter">
                                          <button
                                            className="f-quantity__counter__btn js-quantity__btn f-quantity__counter__btn--disable"
                                            aria-label="Decrease Quantity"
                                            tabIndex="-1"
                                          >
                                            <span className="f-quantity__counter__btn__inner">
                                              <svg viewBox="0 0 16 2">
                                                <title>Minus Icon</title>
                                                <path d="M0 0h16v2H0z"></path>
                                              </svg>
                                            </span>
                                          </button>
                                          <input
                                            className="f-quantity__counter__input"
                                            type="text"
                                            maxLength="4"
                                            value="1"
                                            id="quant1"
                                            name="fQuantity0"
                                          />
                                          <button
                                            className="f-quantity__counter__btn js-quantity__btn"
                                            aria-label="Increase Quantity"
                                            data-add="true"
                                          >
                                            <span className="f-quantity__counter__btn__inner">
                                              <svg viewBox="0 0 16 16">
                                                <title>Plus Icon</title>
                                                <path d="M0 7h16v2H0z"></path>
                                                <path d="M7 0h2v16H7z"></path>
                                              </svg>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="c-cartItem__quantity__total">
                                      <span className="c-cartItem__quantity__total__value">
                                        $54.99
                                      </span>
                                      <button
                                        className="c-cartItem__quantity__total__trash"
                                        aria-label="Remove from cart"
                                      >
                                        <svg viewBox="0 0 20 20">
                                          <path d="M5 18.695a.342.342 0 0 0 .336.305h9.328c.172 0 .32-.137.336-.305L16 6H4l1 12.695ZM16.68 2.992h-4.42C12.111 1.868 11.203 1 10.094 1c-1.108 0-2.016.868-2.164 1.992H3.32c-.18 0-.321.149-.321.337v1.334c0 .188.141.337.32.337h13.36c.179 0 .32-.149.32-.337V3.329a.327.327 0 0 0-.32-.337" />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {/* <C_CartItem title={"4 GB DDR3 DIMM 240-pin Memory Module"} kit={2} /> */}
                              </li>
                            ) : (
                              ""
                            )}

                            {contentOptions.numberOfProducts.selected >= 4 ? (
                              <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__main__body__cartList__item">
                                <div className={`c-cartItem`} data-kit="4">
                                  <div className={`c-cartItem__product`}>
                                    <img
                                      className="c-cartItem__product__image"
                                      src="https://media.kingston.com/images/products/DDR4_ECC_Load_Reduced_DIMM_4R_x4_1_tn.png"
                                      alt="Alternate Text"
                                      data-dropdown="true"
                                    />
                                    <div className="c-cartItem__product__title">
                                      4 GB DDR3 DIMM 240-pin Memory Module
                                    </div>
                                    <span
                                      className="c-cartItem__product__kit"
                                      role="term"
                                      aria-label={"Kit of " + 4}
                                    >
                                      x 4
                                    </span>
                                  </div>

                                  <div className="c-cartItem__quantity">
                                    <div className="c-cartItem__quantity__value">
                                      <div className="f-quantity js-quantity" id="jsQuantity0">
                                        <label className="f-quantity__label" htmlFor="quant1">
                                          Quantity
                                        </label>
                                        <div className="f-quantity__counter">
                                          <button
                                            className="f-quantity__counter__btn js-quantity__btn f-quantity__counter__btn--disable"
                                            aria-label="Decrease Quantity"
                                            tabIndex="-1"
                                          >
                                            <span className="f-quantity__counter__btn__inner">
                                              <svg viewBox="0 0 16 2">
                                                <title>Minus Icon</title>
                                                <path d="M0 0h16v2H0z"></path>
                                              </svg>
                                            </span>
                                          </button>
                                          <input
                                            className="f-quantity__counter__input"
                                            type="text"
                                            maxLength="4"
                                            value="1"
                                            id="quant1"
                                            name="fQuantity0"
                                          />
                                          <button
                                            className="f-quantity__counter__btn js-quantity__btn"
                                            aria-label="Increase Quantity"
                                            data-add="true"
                                          >
                                            <span className="f-quantity__counter__btn__inner">
                                              <svg viewBox="0 0 16 16">
                                                <title>Plus Icon</title>
                                                <path d="M0 7h16v2H0z"></path>
                                                <path d="M7 0h2v16H7z"></path>
                                              </svg>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="c-cartItem__quantity__total">
                                      <span className="c-cartItem__quantity__total__value">
                                        $54.99
                                      </span>
                                      <button
                                        className="c-cartItem__quantity__total__trash"
                                        aria-label="Remove from cart"
                                      >
                                        <svg viewBox="0 0 20 20">
                                          <path d="M5 18.695a.342.342 0 0 0 .336.305h9.328c.172 0 .32-.137.336-.305L16 6H4l1 12.695ZM16.68 2.992h-4.42C12.111 1.868 11.203 1 10.094 1c-1.108 0-2.016.868-2.164 1.992H3.32c-.18 0-.321.149-.321.337v1.334c0 .188.141.337.32.337h13.36c.179 0 .32-.149.32-.337V3.329a.327.327 0 0 0-.32-.337" />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {/* <C_CartItem title={"4 GB DDR3 DIMM 240-pin Memory Module"} kit={4} /> */}
                              </li>
                            ) : (
                              ""
                            )}

                            {contentOptions.numberOfProducts.selected >= 5 ? (
                              <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__main__body__cartList__item">
                                <div className={`c-cartItem`}>
                                  <div className={`c-cartItem__product`}>
                                    <img
                                      className="c-cartItem__product__image"
                                      src="https://media.kingston.com/images/products/DDR4_ECC_Load_Reduced_DIMM_4R_x4_1_tn.png"
                                      alt="Alternate Text"
                                      data-dropdown="true"
                                    />
                                    <div className="c-cartItem__product__title">
                                      4 GB DDR3 DIMM 240-pin Memory Module
                                    </div>
                                  </div>

                                  <div className="c-cartItem__quantity">
                                    <div className="c-cartItem__quantity__value">
                                      <div className="f-quantity js-quantity" id="jsQuantity0">
                                        <label className="f-quantity__label" htmlFor="quant1">
                                          Quantity
                                        </label>
                                        <div className="f-quantity__counter">
                                          <button
                                            className="f-quantity__counter__btn js-quantity__btn f-quantity__counter__btn--disable"
                                            aria-label="Decrease Quantity"
                                            tabIndex="-1"
                                          >
                                            <span className="f-quantity__counter__btn__inner">
                                              <svg viewBox="0 0 16 2">
                                                <title>Minus Icon</title>
                                                <path d="M0 0h16v2H0z"></path>
                                              </svg>
                                            </span>
                                          </button>
                                          <input
                                            className="f-quantity__counter__input"
                                            type="text"
                                            maxLength="4"
                                            value="1"
                                            id="quant1"
                                            name="fQuantity0"
                                          />
                                          <button
                                            className="f-quantity__counter__btn js-quantity__btn"
                                            aria-label="Increase Quantity"
                                            data-add="true"
                                          >
                                            <span className="f-quantity__counter__btn__inner">
                                              <svg viewBox="0 0 16 16">
                                                <title>Plus Icon</title>
                                                <path d="M0 7h16v2H0z"></path>
                                                <path d="M7 0h2v16H7z"></path>
                                              </svg>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="c-cartItem__quantity__total">
                                      <span className="c-cartItem__quantity__total__value">
                                        $54.99
                                      </span>
                                      <button
                                        className="c-cartItem__quantity__total__trash"
                                        aria-label="Remove from cart"
                                      >
                                        <svg viewBox="0 0 20 20">
                                          <path d="M5 18.695a.342.342 0 0 0 .336.305h9.328c.172 0 .32-.137.336-.305L16 6H4l1 12.695ZM16.68 2.992h-4.42C12.111 1.868 11.203 1 10.094 1c-1.108 0-2.016.868-2.164 1.992H3.32c-.18 0-.321.149-.321.337v1.334c0 .188.141.337.32.337h13.36c.179 0 .32-.149.32-.337V3.329a.327.327 0 0 0-.32-.337" />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ) : (
                              ""
                            )}

                            {contentOptions.numberOfProducts.selected == 6 ? (
                              <li className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__main__body__cartList__item">
                                <div className={`c-cartItem`} data-kit="2">
                                  <div className={`c-cartItem__product`}>
                                    <img
                                      className="c-cartItem__product__image"
                                      src="https://media.kingston.com/images/products/DDR4_ECC_Load_Reduced_DIMM_4R_x4_1_tn.png"
                                      alt="Alternate Text"
                                      data-dropdown="true"
                                    />
                                    <div className="c-cartItem__product__title">
                                      4 GB DDR3 DIMM 240-pin Memory Module
                                    </div>
                                    <span
                                      className="c-cartItem__product__kit"
                                      role="term"
                                      aria-label={"Kit of " + 2}
                                    >
                                      x 2
                                    </span>
                                  </div>

                                  <div className="c-cartItem__quantity">
                                    <div className="c-cartItem__quantity__value">
                                      <div className="f-quantity js-quantity" id="jsQuantity0">
                                        <label className="f-quantity__label" htmlFor="quant1">
                                          Quantity
                                        </label>
                                        <div className="f-quantity__counter">
                                          <button
                                            className="f-quantity__counter__btn js-quantity__btn f-quantity__counter__btn--disable"
                                            aria-label="Decrease Quantity"
                                            tabIndex="-1"
                                          >
                                            <span className="f-quantity__counter__btn__inner">
                                              <svg viewBox="0 0 16 2">
                                                <title>Minus Icon</title>
                                                <path d="M0 0h16v2H0z"></path>
                                              </svg>
                                            </span>
                                          </button>
                                          <input
                                            className="f-quantity__counter__input"
                                            type="text"
                                            maxLength="4"
                                            value="1"
                                            id="quant1"
                                            name="fQuantity0"
                                          />
                                          <button
                                            className="f-quantity__counter__btn js-quantity__btn"
                                            aria-label="Increase Quantity"
                                            data-add="true"
                                          >
                                            <span className="f-quantity__counter__btn__inner">
                                              <svg viewBox="0 0 16 16">
                                                <title>Plus Icon</title>
                                                <path d="M0 7h16v2H0z"></path>
                                                <path d="M7 0h2v16H7z"></path>
                                              </svg>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="c-cartItem__quantity__total">
                                      <span className="c-cartItem__quantity__total__value">
                                        $54.99
                                      </span>
                                      <button
                                        className="c-cartItem__quantity__total__trash"
                                        aria-label="Remove from cart"
                                      >
                                        <svg viewBox="0 0 20 20">
                                          <path d="M5 18.695a.342.342 0 0 0 .336.305h9.328c.172 0 .32-.137.336-.305L16 6H4l1 12.695ZM16.68 2.992h-4.42C12.111 1.868 11.203 1 10.094 1c-1.108 0-2.016.868-2.164 1.992H3.32c-.18 0-.321.149-.321.337v1.334c0 .188.141.337.32.337h13.36c.179 0 .32-.149.32-.337V3.329a.327.327 0 0 0-.32-.337" />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                        ) : (
                          <p className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__main__body__copy">
                            {contentOptions.cartcopy &&
                              contentOptions.cartcopy.field[contentOptions.currentLang]}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__header">
                      <a
                        href="#link"
                        className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__header__link"
                      >
                        <svg viewBox="0 0 16.32 17.83">
                          <path d="M10.39 9.54a5 5 0 1 0-4.46 0A8.16 8.16 0 0 0 0 17.38v.45h16.32v-.45a8.17 8.17 0 0 0-5.93-7.84zM4 5a4.14 4.14 0 1 1 4.16 4.18A4.15 4.15 0 0 1 4 5zM.91 16.93a7.26 7.26 0 0 1 14.49 0z"></path>
                        </svg>
                        Create account
                      </a>
                      <a
                        href="#link"
                        className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__header__link"
                      >
                        <svg viewBox="0 0 18 17.29">
                          <path d="M1.56 10.27h5.81v2.28a1.19 1.19 0 0 0 1.18 1.19 1.26 1.26 0 0 0 .75-.26l4.79-3.84A1.19 1.19 0 0 0 14.27 8l-.19-.18L9.29 4a1.17 1.17 0 0 0-.74-.26 1.19 1.19 0 0 0-.55.08 1.17 1.17 0 0 0-.67 1.07v2.27H1.56a1.56 1.56 0 1 0 0 3.11zm0-2.21h6.71V4.89a.29.29 0 0 1 .16-.26.29.29 0 0 1 .3 0l4.84 3.88a.28.28 0 0 1-.05.4l-4.79 3.86a.28.28 0 0 1-.46-.22V9.37H1.56a.65.65 0 0 1-.66-.65.66.66 0 0 1 .66-.66z"></path>
                          <path d="M1.44 6.45A.44.44 0 0 0 2 6.17a7.76 7.76 0 1 1 .12 5.26.46.46 0 0 0-.59-.26.45.45 0 0 0-.25.58 8.64 8.64 0 1 0-.13-5.87.45.45 0 0 0 .29.57z"></path>
                        </svg>
                        Sign in
                      </a>
                    </div>
                    <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__footer">
                      <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__footer__content">
                        <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__footer__content__total">
                          <span className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__footer__content__total__items">
                            <span className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__footer__content__total__items__number">
                              {contentOptions.numberOfProducts &&
                                contentOptions.numberOfProducts.selected}
                            </span>{" "}
                            {(contentOptions.numberOfProducts &&
                              contentOptions.numberOfProducts.selected == 0) ||
                            (contentOptions.numberOfProducts &&
                              contentOptions.numberOfProducts.selected >= 2)
                              ? "Items"
                              : "Item"}
                          </span>
                          <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__footer__content__total__cost">
                            <span className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__footer__content__total__cost__copy">
                              Total
                            </span>
                            <span className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__footer__content__total__cost__value">
                              {contentOptions.numberOfProducts &&
                              contentOptions.numberOfProducts.selected == 0
                                ? "—"
                                : contentOptions.numberOfProducts &&
                                    contentOptions.numberOfProducts.selected == 1
                                  ? "$54.99"
                                  : contentOptions.numberOfProducts &&
                                      contentOptions.numberOfProducts.selected == 2
                                    ? "$109.98"
                                    : contentOptions.numberOfProducts &&
                                        contentOptions.numberOfProducts.selected == 3
                                      ? "$164.97"
                                      : contentOptions.numberOfProducts &&
                                          contentOptions.numberOfProducts.selected == 4
                                        ? "$219.96"
                                        : contentOptions.numberOfProducts &&
                                            contentOptions.numberOfProducts.selected == 5
                                          ? "$274.95"
                                          : "$329.94"}
                            </span>
                          </div>
                        </div>
                        {contentOptions.vat && contentOptions.vat.checkbox ? (
                          <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__footer__content__vat">
                            <span>VAT included</span>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__footer__content__discount">
                          <div className="f-input f-input--reactive">
                            <div className="f-input__string">
                              <input type="text" name="acode" id="acode" />
                              <label htmlFor="acode">Discount Code</label>
                            </div>
                            <a className="f-input__icon" href="#link">
                              Apply
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__footer__cta">
                        <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__footer__cta__items">
                          <button className="e-btn">Checkout</button>
                          <div className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__footer__cta__items__paypal"></div>
                          {contentOptions.apple && contentOptions.apple.checkbox ? (
                            <button className="e-btn u-btn-apple">
                              <span>Pay with</span>
                              <img
                                src="/images/fpo/section-navigation/logo-applePay.svg"
                                alt="apple"
                              />
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                        <a
                          className="n-megaMenuNavigation__desktop__mainList__panel__sideMenu__content__cart__footer__cta__continue"
                          href="#link"
                          id="cShopping"
                        >
                          Continue Shopping
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="n-megaMenuNavigation__sideMenu">
          <ul className="n-megaMenuNavigation__sideMenu__list">
            <li
              className={`n-megaMenuNavigation__sideMenu__list__item n-megaMenuNavigation__sideMenu__list__item--search${
                contentOptions.openpanel && contentOptions.openpanel.selected == "Search"
                  ? " n-megaMenuNavigation__sideMenu__list__item--active"
                  : ""
              }`}
            >
              <button
                aria-label="Search"
                className="n-megaMenuNavigation__sideMenu__list__item__trigger"
              >
                <svg viewBox="0 0 16 16" aria-hidden="true">
                  <path d="M7 0c3.87 0 7 3.13 7 7 0 1.58-.52 3.03-1.4 4.2.04.03.07.06.11.09l3 3a.996.996 0 1 1-1.41 1.41l-3-3a.481.481 0 0 1-.09-.11A6.948 6.948 0 0 1 7 14c-3.87 0-7-3.13-7-7s3.13-7 7-7zm0 2C4.24 2 2 4.24 2 7s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5z" />
                </svg>
              </button>
            </li>
            <li
              className={`n-megaMenuNavigation__sideMenu__list__item n-megaMenuNavigation__sideMenu__list__item--chat`}
            >
              <button
                className="n-megaMenuNavigation__sideMenu__list__item__trigger"
                aria-label="Live Chat"
              >
                <svg viewBox="0 0 16 16" aria-hidden="true">
                  <path d="M8 0c4.42 0 8 3.13 8 7s-3.58 7-8 7c0 0-2.33.67-7 2 .5-2.17.86-3.6 1.07-4.3C.78 10.46 0 8.81 0 7c0-3.87 3.58-7 8-7zm0 2C4.63 2 2 4.3 2 7c0 1.2.51 2.35 1.46 3.26l.88.85-.35 1.17c-.05.18-.12.42-.2.71l-.04.15.1-.03c1.87-.53 2.47-.71 3.02-.86l.17-.05c.02-.01.04-.01.06-.02l.12-.03c.08-.02.16-.05.25-.07l.25-.08H8c3.37 0 6-2.3 6-5s-2.63-5-6-5z" />
                </svg>
              </button>
            </li>
            <li
              className={`n-megaMenuNavigation__sideMenu__list__item n-megaMenuNavigation__sideMenu__list__item--languages${
                contentOptions.openpanel && contentOptions.openpanel.selected == "Languages"
                  ? " n-megaMenuNavigation__sideMenu__list__item--active"
                  : ""
              }`}
            >
              <button
                aria-label="Choose Language"
                className="n-megaMenuNavigation__sideMenu__list__item__trigger"
              >
                <svg viewBox="0 0 16 16" aria-hidden="true">
                  <path d="M8 0c4.08 0 7.45 3.05 7.94 7H16v2h-.06c-.49 3.95-3.86 7-7.94 7-4.42 0-8-3.58-8-8s3.58-8 8-8zm.99 9H7.01c.08 2.84.49 5 .99 5s.91-2.16.99-5zM5.01 9H2.08a6.003 6.003 0 0 0 3.32 4.41c-.26-1.12-.37-2.6-.39-4.41zm8.91 0H11c-.03 1.81-.13 3.29-.39 4.41A6.039 6.039 0 0 0 13.92 9zM5.4 2.59l-.12.06A6.032 6.032 0 0 0 2.08 7H5c.03-1.81.14-3.29.4-4.41zM8 2c-.5 0-.91 2.16-.99 5h1.97c-.07-2.74-.46-4.86-.93-4.99L8 2zm2.6.59v.02c.26 1.12.37 2.59.39 4.39h2.92a5.991 5.991 0 0 0-3.31-4.41z" />
                </svg>
              </button>
            </li>
            {contentOptions.cart && contentOptions.cart.checkbox ? (
              <li
                className={`n-megaMenuNavigation__sideMenu__list__item n-megaMenuNavigation__sideMenu__list__item--cart${
                  contentOptions.openpanel && contentOptions.openpanel.selected == "Cart"
                    ? " n-megaMenuNavigation__sideMenu__list__item--active"
                    : ""
                }`}
              >
                <button
                  href="#cartpage"
                  aria-label="Cart"
                  className="n-megaMenuNavigation__sideMenu__list__item__trigger"
                >
                  <svg viewBox="0 0 16 16" aria-hidden="true">
                    <path
                      d="M5 16c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm8 0c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zM4.33 0l.15.82L4.7 2h11.28l-.25 1.2-1.25 6-.17.8H6.15l.18 1H14c.51 0 .94.39.99.88L15 12c0 .55-.45 1-1 1H4.67l-.15-.82L2.66 2H1c-.51 0-.94-.39-.99-.88L0 1c0-.55.45-1 1-1h3.33zm1.46 8h6.9l.83-4H5.06l.73 4z"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    />
                  </svg>

                  {contentOptions.numberOfProducts &&
                  contentOptions.numberOfProducts.selected > 0 ? (
                    <div className="e-tag n-megaMenuNavigation__sideMenu__list__item__trigger__cartItemNumber">
                      {contentOptions.numberOfProducts.selected}
                    </div>
                  ) : (
                    ""
                  )}
                </button>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
        <div
          className={`n-megaMenuNavigation__blur${
            (contentOptions.openpanel && contentOptions.openpanel.selected == "Products") ||
            (contentOptions.openpanel && contentOptions.openpanel.selected == "Solutions") ||
            (contentOptions.openpanel && contentOptions.openpanel.selected == "Support") ||
            (contentOptions.openpanel && contentOptions.openpanel.selected == "Company") ||
            (contentOptions.openpanel && contentOptions.openpanel.selected == "Blog") ||
            (contentOptions.openpanel && contentOptions.openpanel.selected == "Search") ||
            (contentOptions.openpanel && contentOptions.openpanel.selected == "Languages") ||
            (contentOptions.openpanel && contentOptions.openpanel.selected == "Cart")
              ? " n-megaMenuNavigation__blur--show"
              : ""
          }`}
        ></div>
      </nav>
      <section className="s-hero">
        <div className="s-hero__background">
          <picture className="e-picture" id="js-picture">
            <source srcSet="/images/fpo/general/512x512.png" media="(max-width:32em)" />
            <source srcSet="/images/fpo/general/1024x512.png" media="(max-width:64em)" />
            <img src="/images/fpo/general/2048x768.png" alt="Alternate Text/" />
          </picture>
        </div>
        <div className="s-hero__content">
          <div className="c-headerClip">
            <div className="u-h1 c-headerClip__title">Lorem ipsum</div>
            <div className="c-headerClip__cta">
              <a className="c-headerClip__cta__link" href="#heroLink">
                <svg>
                  <use xlinkHref="/images/icons-map.svg#play-basic"></use>
                </svg>
                Sed ut perspiciatis
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="s-inPageNav t-white" id="inPageNav1">
        <div className="s-inPageNav__product">
          <span>Lorem ipsum</span>
        </div>
        <span className="e-arrowToggle__down s-inPageNav__toggle">
          <svg viewBox="0 0 14 8">
            <path
              path="path"
              d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z"
            ></path>
          </svg>
        </span>
        <div className="s-inPageNav__links">
          <span className="u-invisible">
            <svg viewBox="0 0 14 8">
              <path
                path="path"
                d="M13.02 7.98a.062.062 0 0 1-.09 0L7.058 2.13c-.038-.035-.068-.039-.105-.01L1.071 7.98a.062.062 0 0 1-.09 0L.02 7.023a.062.062 0 0 1 0-.09L6.957.017a.066.066 0 0 1 .09 0l.962.96 5.973 5.954a.065.065 0 0 1 0 .09l-.961.958z"
              ></path>
            </svg>
          </span>
          <ul className="s-inPageNav__links__nav" id="s-inPageNav__links__nav">
            <li>
              <a href="#link1" data-root="inPageNav1">
                Sed
              </a>
            </li>
            <li>
              <a href="#link2" data-root="inPageNav1">
                Ut
              </a>
            </li>
            <li>
              <a href="#link3" data-root="inPageNav1">
                Perspiciatis
              </a>
            </li>
          </ul>
          <span className="">
            <svg viewBox="0 0 14 8">
              <path
                path="path"
                d="M13.02 7.98a.062.062 0 0 1-.09 0L7.058 2.13c-.038-.035-.068-.039-.105-.01L1.071 7.98a.062.062 0 0 1-.09 0L.02 7.023a.062.062 0 0 1 0-.09L6.957.017a.066.066 0 0 1 .09 0l.962.96 5.973 5.954a.065.065 0 0 1 0 .09l-.961.958z"
              ></path>
            </svg>
          </span>
        </div>
        <span className="s-inPageNav__price">$99.99 - $139.99</span>
        <a className="e-btn" href="#buyLink" target="_self" title="Button Title">
          <span>Iste</span>
        </a>
      </section>

      <section className="s-productGallery3 t-pearl" id="productGallery30">
        <div className="c-heading">
          <div className="c-heading__block">
            <div className="c-heading__block__header">
              <h2>Lorem ipsum dolor sit amet</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore.
              </p>
            </div>
          </div>
        </div>
        <div className="l-tabView">
          <div className="u-invisible s-productGallery3__tabLeft"></div>
          <ul className="l-tabView__tabs" role="tablist">
            <li
              className="l-tabView__tabs__tab l-tabView__tabs__tab--active"
              role="tab"
              data-parent="0"
              id="tab0_0"
              data-tab="tabContent0_0"
              aria-controls="tabContent0_0"
              aria-selected="true"
              tabIndex="0"
              data-id="productGallery30"
            >
              <span>All Products</span>
            </li>
            <li
              className="l-tabView__tabs__tab "
              role="tab"
              data-parent="0"
              id="tab0_1"
              data-tab="tabContent0_1"
              aria-controls="tabContent0_1"
              aria-selected="false"
              tabIndex="-1"
              data-id="productGallery30"
            >
              <span>Headsets</span>
            </li>
            <li
              className="l-tabView__tabs__tab "
              role="tab"
              data-parent="0"
              id="tab0_2"
              data-tab="tabContent0_2"
              aria-controls="tabContent0_2"
              aria-selected="false"
              tabIndex="-1"
              data-id="productGallery30"
            >
              <span>Keyboards</span>
            </li>
            <li
              className="l-tabView__tabs__tab "
              role="tab"
              data-parent="0"
              id="tab0_3"
              data-tab="tabContent0_3"
              aria-controls="tabContent0_3"
              aria-selected="false"
              tabIndex="-1"
              data-id="productGallery30"
            >
              <span>Storage</span>
            </li>
          </ul>
          <div className="u-invisible s-productGallery3__tabRight"></div>
          <div className="l-tabView__panels">
            <div
              className="l-tabView__panels__panel l-tabView__panels__panel--active"
              role="tabpanel"
              aria-labelledby="tab0_0"
              id="tabContent0_0"
            >
              <div className="s-productGallery3__filterLayout">
                <div className="s-productGallery3__filterLayout__controls">
                  <div className="s-productGallery3__filterLayout__controls__section">
                    <h5 className="u-txt-uppercase">Search</h5>
                    <div className="c-accordion c-accordion--noOutline">
                      <span className="c-accordion__tab false">
                        <button aria-expanded="false">
                          System/Device
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden="true"
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                          </svg>
                        </button>
                      </span>
                      <div className="c-accordion__panel">
                        <div className="c-accordion__panel__content">
                          <div className="c-searchCard">
                            <div className="c-searchCard__body">
                              <p>
                                Simply enter the make and model number or system part number of the
                                computer system or digital device to find the memory you need.
                              </p>
                              <form className="c-searchCard__body__cta">
                                <div className="f-input f-input--outlined">
                                  <div className="f-input__string">
                                    <input
                                      type="text"
                                      name="makeModel"
                                      id="makeModel"
                                      required=""
                                      pattern="[a-zA-Z0-9]+"
                                    />
                                    <label htmlFor="makeModel">Make/Model</label>
                                  </div>
                                </div>
                                <a className="e-btn" href="#link" aria-label="Search">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.2 16.7">
                                    <path d="M9.8 0C5.7 0 2.4 3.3 2.4 7.4s3.3 7.4 7.4 7.4 7.4-3.3 7.4-7.4S13.9 0 9.8 0zm0 12.8c-3 0-5.3-2.4-5.3-5.3 0-3 2.4-5.3 5.3-5.3s5.3 2.4 5.3 5.3-2.4 5.3-5.3 5.3z" />
                                    <path d="M4.5 10.5L.4 14.6c-.5.5-.5 1.2 0 1.7s1.2.5 1.7 0l4.1-4.1" />
                                  </svg>
                                </a>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="c-accordion__tab false">
                        <button aria-controls="accPanel-m2vhwm" aria-expanded="false">
                          Memory Part Number
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden="true"
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                          </svg>
                        </button>
                      </span>
                      <div className="c-accordion__panel">
                        <div className="c-accordion__panel__content">
                          <div className="c-searchCard">
                            <div className="c-searchCard__body">
                              <p>
                                Search by either the Kingston part number, distributor part number
                                or manufacturer equivalent part number.
                              </p>
                              <form className="c-searchCard__body__cta">
                                <div className="f-input f-input--outlined">
                                  <div className="f-input__string">
                                    <input
                                      type="text"
                                      name="partNumber"
                                      id="partNumber"
                                      required=""
                                      pattern="[a-zA-Z0-9]+"
                                    />
                                    <label htmlFor="partNumber">Part Number</label>
                                  </div>
                                </div>
                                <a className="e-btn" href="#link" aria-label="Search">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.2 16.7">
                                    <path d="M9.8 0C5.7 0 2.4 3.3 2.4 7.4s3.3 7.4 7.4 7.4 7.4-3.3 7.4-7.4S13.9 0 9.8 0zm0 12.8c-3 0-5.3-2.4-5.3-5.3 0-3 2.4-5.3 5.3-5.3s5.3 2.4 5.3 5.3-2.4 5.3-5.3 5.3z" />
                                    <path d="M4.5 10.5L.4 14.6c-.5.5-.5 1.2 0 1.7s1.2.5 1.7 0l4.1-4.1" />
                                  </svg>
                                </a>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="s-productGallery3__filterLayout__controls__section">
                    <h5 className="u-txt-uppercase">Browse</h5>
                    <ul>
                      <li>
                        <a href="#serverLink" title="Anchor Title">
                          Server Memory
                        </a>
                      </li>
                      <li>
                        <a href="#desktopLink" title="Anchor Title">
                          Desktop/Laptop Memory
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="c-slideMenu s-productGallery3__filterLayout__slides"
                  id="slideMenu0"
                >
                  <div
                    className="c-slideMenu__slider c-filterCard s-productGallery3__filterLayout__slides__filter "
                    data-filterid="filterCard0"
                    data-dropdown="true"
                  >
                    <button className="c-slideMenu__slider__toggle" aria-expanded="false">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <use href="../images/icons-map.svg#filter" />
                      </svg>
                      <span className="u-h5 u-txt-uppercase c-filterCard__title">Filter</span>
                    </button>
                    <div className="c-filterCard__results">
                      <span data-results="0">Results</span>
                    </div>
                    <form className="c-slideMenu__slider__panel c-filterCard__details">
                      <div className="c-slideMenu__slider__panel__content c-filterCard__details__content">
                        <div className="c-accordion c-accordion--noOutline">
                          <span className="c-accordion__tab false">
                            <button aria-expanded="false" type="button">
                              Lightning
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="c-accordion__tab__icon"
                                viewBox="0 0 14 8"
                                aria-hidden="true"
                              >
                                <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                              </svg>
                            </button>
                          </span>
                          <div className="c-accordion__panel">
                            <div className="c-accordion__panel__content">
                              <ul className="u-list-unstyled">
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="LightningfilterBtnsID0"
                                        id="filterBtnsIDLightning0"
                                        value="valLightning0"
                                      />
                                      <label htmlFor="filterBtnsIDLightning0">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="LightningfilterBtnsID1"
                                        id="filterBtnsIDLightning1"
                                        value="valLightning1"
                                      />
                                      <label htmlFor="filterBtnsIDLightning1">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="c-accordion c-accordion--noOutline">
                          <span className="c-accordion__tab false">
                            <button aria-expanded="false" type="button">
                              Wired
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="c-accordion__tab__icon"
                                viewBox="0 0 14 8"
                                aria-hidden="true"
                              >
                                <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                              </svg>
                            </button>
                          </span>
                          <div className="c-accordion__panel">
                            <div className="c-accordion__panel__content">
                              <ul className="u-list-unstyled">
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="WiredfilterBtnsID0"
                                        id="filterBtnsIDWired0"
                                        value="valWired0"
                                      />
                                      <label htmlFor="filterBtnsIDWired0">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="WiredfilterBtnsID1"
                                        id="filterBtnsIDWired1"
                                        value="valWired1"
                                      />
                                      <label htmlFor="filterBtnsIDWired1">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="WiredfilterBtnsID2"
                                        id="filterBtnsIDWired2"
                                        value="valWired2"
                                      />
                                      <label htmlFor="filterBtnsIDWired2">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="c-accordion c-accordion--noOutline">
                          <span className="c-accordion__tab false">
                            <button aria-expanded="false" type="button">
                              Platform
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="c-accordion__tab__icon"
                                viewBox="0 0 14 8"
                                aria-hidden="true"
                              >
                                <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                              </svg>
                            </button>
                          </span>
                          <div className="c-accordion__panel">
                            <div className="c-accordion__panel__content">
                              <ul className="u-list-unstyled">
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="PlatformfilterBtnsID0"
                                        id="filterBtnsIDPlatform0"
                                        value="valPlatform0"
                                      />
                                      <label htmlFor="filterBtnsIDPlatform0">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="PlatformfilterBtnsID1"
                                        id="filterBtnsIDPlatform1"
                                        value="valPlatform1"
                                      />
                                      <label htmlFor="filterBtnsIDPlatform1">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="c-filterCard__details__content__info">
                          <a href="#anchor" title="anchor">
                            Search HTS/ECCN
                          </a>
                        </div>
                      </div>
                      <div className="c-slideMenu__slider__panel__cta c-filterCard__details__cta">
                        <button
                          className="e-btn e-btn--alt2 c-filterCard__details__cta__reset"
                          type="button"
                        >
                          Reset
                        </button>
                        <button
                          className="e-btn c-slideMenu__slider__panel__cta__closeBtn c-filterCard__details__cta__close"
                          type="button"
                        >
                          Close
                        </button>
                        <button className="e-btn c-filterCard__details__cta__apply">Apply</button>
                      </div>
                    </form>
                  </div>
                  <div className="c-slideMenu__slider c-slideMenu__slider--right s-productGallery3__filterLayout__slides__sort false">
                    <button className="c-slideMenu__slider__toggle" aria-expanded="false">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <use href="../images/icons-map.svg#sort" />
                      </svg>
                      <span className="u-h5 u-txt-uppercase">Sort</span>
                    </button>
                    <form className="c-slideMenu__slider__panel s-productGallery3__filterLayout__slides__sort__details">
                      <div className="c-slideMenu__slider__panel__content s-productGallery3__filterLayout__slides__sort__details__content">
                        <ul className="u-list-unstyled">
                          <li>
                            <div className="f-input">
                              <div className="f-input__radio">
                                <input
                                  type="radio"
                                  name="radioName"
                                  id="radioName0"
                                  value="Featured"
                                />
                                <label htmlFor="radioName0" aria-checked="false">
                                  Featured
                                </label>
                              </div>
                              <div className="f-input__radio">
                                <input
                                  type="radio"
                                  name="radioName"
                                  id="radioName1"
                                  value="Newest"
                                />
                                <label htmlFor="radioName1" aria-checked="false">
                                  Newest
                                </label>
                              </div>
                              <div className="f-input__radio">
                                <input
                                  type="radio"
                                  name="radioName"
                                  id="radioName2"
                                  value="Oldest"
                                />
                                <label htmlFor="radioName2" aria-checked="false">
                                  Oldest
                                </label>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="c-slideMenu__slider__panel__cta">
                        <button
                          className="e-btn c-slideMenu__slider__panel__cta__closeBtn"
                          type="button"
                        >
                          Close
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="s-productGallery3__filterLayout__view">
                  <div className="s-productGallery3__filterLayout__view__sort">
                    <div
                      className="c-filterCard__dropDown s-productGallery3__filterLayout__view__sort__display s-productGallery3__filterLayout__view__sort__filter u-hide"
                      data-target="filterCard0"
                      data-id="filterCard0"
                    >
                      <div className="s-productGallery3__filterLayout__view__sort__display">
                        <div
                          className="c-dropDown c-dropDown--absolute false"
                          data-type="selection"
                          id="dropDown0"
                        >
                          <div
                            className="c-dropDown__toggler"
                            aria-expanded="false"
                            aria-controls="dropDownPanel0"
                          >
                            <div className="c-dropDown__toggler__selection">
                              <span className="c-dropDown__toggler__selection__value u-txt-bold"></span>
                              <span className="c-dropDown__toggler__selection">
                                <span className="u-txt-bold c-dropDown__toggler__selection__single">
                                  Filter
                                </span>
                                <span className="u-txt-bold c-dropDown__toggler__selection__plural">
                                  Filters
                                </span>
                              </span>
                              Applied
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="8"
                              viewBox="0 0 14 8"
                            >
                              <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                            </svg>
                          </div>
                          <div className="c-dropDown__panel" id="dropDownPanel0">
                            <button className="c-dropDown__panel__clearBtn">Clear All</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="s-productGallery3__filterLayout__view__sort__display s-productGallery3__filterLayout__view__sort__layout">
                      <div
                        className="c-dropDown c-dropDown--absolute false"
                        data-type="selection"
                        id="dropDown1"
                        role="button"
                        tabIndex="-1"
                      >
                        <div
                          className="c-dropDown__toggler"
                          aria-expanded="false"
                          aria-controls="dropDownPanel1"
                        >
                          <div className="c-dropDown__toggler__selection">
                            <span className="c-dropDown__toggler__selection">
                              Sort By{" "}
                              <span className="c-dropDown__toggler__selection__value u-txt-bold">
                                Grid
                              </span>
                            </span>
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                          </svg>
                        </div>
                        <div className="c-dropDown__panel" id="dropDownPanel1">
                          <ul>
                            <li className="c-dropDown__panel__item false">Grid</li>
                            <li className="c-dropDown__panel__item">List</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="s-productGallery3__filterLayout__view__sort__display s-productGallery3__filterLayout__view__sort__options">
                      <div className="c-dropDown c-dropDown--absolute false" id="dropDown2">
                        <div
                          className="c-dropDown__toggler"
                          aria-expanded="false"
                          aria-controls="dropDownPanel2"
                          role="button"
                          tabIndex="-1"
                        >
                          <div className="c-dropDown__toggler__selection">
                            <span className="c-dropDown__toggler__selection">
                              Sort By{" "}
                              <span className="c-dropDown__toggler__selection__value u-txt-bold">
                                Featured
                              </span>
                            </span>
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                          </svg>
                        </div>
                        <div className="c-dropDown__panel" id="dropDownPanel2">
                          <ul>
                            <li className="c-dropDown__panel__item">Oldest</li>
                            <li className="c-dropDown__panel__item">Newest</li>
                            <li className="c-dropDown__panel__item c-dropDown__panel__item--active">
                              Featured
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="l-gridFlex s-productGallery3__grid" data-min="288" data-max="480">
                    <li className="s-productGallery3__grid__cell">
                      <div className="c-productCard4" id="productCard4-0">
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">Product Name</span>
                            <span className="e-tag">Sale</span>
                          </a>
                        </div>
                        <span className="c-productCard4__partNumber">
                          Part Number:{" "}
                          <span className="c-productCard4__partNumber"> KTH-PL426/16G</span>
                        </span>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/kingston/product/DDR4_Non-ECC_Unbuffered_SODIMM_4GB_1-tn.png"
                                alt="Product Name"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__selections">
                              <div
                                className="f-input c-productCard4__details__content__selections__type"
                                role="radiogroup"
                                aria-label="Selections"
                              >
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection0"
                                    name="size16"
                                    checked=""
                                  />
                                  <label htmlFor="selection0">16 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection1"
                                    name="size32"
                                  />
                                  <label htmlFor="selection1">32 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection2"
                                    name="size64"
                                    disabled=""
                                  />
                                  <label htmlFor="selection2">64 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection3"
                                    name="size128"
                                  />
                                  <label htmlFor="selection3">128 GB</label>
                                </div>
                              </div>
                            </div>
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li>
                                  This is Bullet Number One and it goes on for a little while here.
                                </li>
                                <li>
                                  Second Bullet Point Goes Here, thank you very much for not keeping
                                  it short.
                                </li>
                                <li className="c-productCard4__details__content__longDesc__partNumber">
                                  Part Number: KTH-PL426/16G
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">Short Description</p>
                        </div>
                        <div className="c-productCard4__footer">
                          <div className="c-productCard4__footer__price">
                            <div className="c-productCard4__footer__price__container">
                              <span className="c-productCard4__footer__price__container__amount">
                                $66.98
                              </span>
                            </div>
                          </div>
                          <button
                            className="e-btn e-btn--alt2 c-productCard4__footer__buyBtn"
                            aria-expanded="false"
                          >
                            <span>Buy</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
                              <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                            </svg>
                          </button>
                        </div>
                        <div className="c-productCard4__buyOptions" aria-hidden="true">
                          <div className="c-productCard4__buyOptions__panel">
                            <div className="c-productCard4__buyOptions__content">
                              <span className="c-productCard4__buyOptions__content__copy">
                                Product scheduled to ship 1 - 2 weeks
                              </span>
                              <form className="c-productCard4__buyOptions__content__qtyCTA">
                                <label className="f-quantity__label" htmlFor="quant1">
                                  Quantity
                                </label>
                                <div className="f-quantity js-quantity" id="jsQuantity0">
                                  <div className="f-quantity__counter">
                                    <button
                                      className="f-quantity__counter__btn js-quantity__btn "
                                      aria-label="Decrease Quantity"
                                      tabIndex="-1"
                                    >
                                      <span
                                        className="f-quantity__counter__btn__inner"
                                        tabIndex="-1"
                                      >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 2">
                                          <title>Minus Icon</title>
                                          <path d="M0 0h16v2H0z" />
                                        </svg>
                                      </span>
                                    </button>
                                    <input
                                      className="f-quantity__counter__input"
                                      type="text"
                                      maxLength="4"
                                      id="quant1"
                                      name="fQuantity0"
                                      value="1"
                                    />
                                    <button
                                      className="f-quantity__counter__btn js-quantity__btn"
                                      aria-label="Increase Quantity"
                                      data-add="true"
                                    >
                                      <span
                                        className="f-quantity__counter__btn__inner"
                                        tabIndex="-1"
                                      >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                          <title>Plus Icon</title>
                                          <path d="M0 7h16v2H0z" />
                                          <path d="M7 0h2v16H7z" />
                                        </svg>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <button className="e-btn" title="Button Title">
                                  <svg xmlns="http://www.w3.org/2000/svg">
                                    <use href="/images/icons-map.svg#btn-cart" />
                                  </svg>
                                  <span>Add to Cart</span>
                                </button>
                              </form>
                              <h5 className="u-txt-center">Or Buy From</h5>
                              <div className="c-productCard4__buyOptions__content__partnersCTA">
                                <div className="c-productCard4__buyOptions__content__partnersCTA__partners">
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div className="c-productCard4" id="productCard4-1">
                        <div className="e-tag c-productCard4__kit">
                          <span className="c-productCard4__kit__lg"> Kit of 4</span>
                          <span className="c-productCard4__kit__sm">x 4</span>
                        </div>
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">
                              2GB Module - DDR3 1600MHz
                            </span>
                            <span className="e-tag">Sale</span>
                          </a>
                        </div>
                        <span className="c-productCard4__partNumber">
                          Part Number:{" "}
                          <span className="c-productCard4__partNumber"> KTH-PL426/16G</span>
                        </span>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/kingston/product/DDR4_ECC_Registered_VLP_DIMM_1-tn.png"
                                alt="2GB Module - DDR3 1600MHz"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__selections">
                              <div
                                className="f-input c-productCard4__details__content__selections__type"
                                role="radiogroup"
                                aria-label="Selections"
                              >
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection0"
                                    name="size16"
                                    checked=""
                                  />
                                  <label htmlFor="selection0">16 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection1"
                                    name="size32"
                                  />
                                  <label htmlFor="selection1">32 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection2"
                                    name="size64"
                                    disabled=""
                                  />
                                  <label htmlFor="selection2">64 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection3"
                                    name="size128"
                                  />
                                  <label htmlFor="selection3">128 GB</label>
                                </div>
                              </div>
                            </div>
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li>
                                  This is Bullet Number One and it goes on for a little while here.
                                </li>
                                <li>
                                  Second Bullet Point Goes Here, thank you very much for not keeping
                                  it short.
                                </li>
                                <li className="c-productCard4__details__content__longDesc__partNumber">
                                  Part Number: KTH-PL426/16G
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">Short Description</p>
                        </div>
                        <div className="c-productCard4__footer">
                          <button
                            className="e-btn e-btn--alt2 c-productCard4__footer__buyBtn"
                            aria-expanded="false"
                          >
                            <span>Buy</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
                              <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                            </svg>
                          </button>
                        </div>
                        <div className="c-productCard4__buyOptions" aria-hidden="true">
                          <div className="c-productCard4__buyOptions__panel">
                            <div className="c-productCard4__buyOptions__content">
                              <div className="c-productCard4__buyOptions__content__partnersCTA">
                                <h5 className="u-txt-center">Buy From</h5>
                                <div className="c-productCard4__buyOptions__content__partnersCTA__partners">
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div className="c-productCard4" id="productCard4-2">
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">
                              DC450R 2.5 Enterprise SSD
                            </span>
                          </a>
                        </div>
                        <span className="c-productCard4__partNumber">
                          Part Number:{" "}
                          <span className="c-productCard4__partNumber"> KTH-PL426/16G</span>
                        </span>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/kingston/product/ktc-product-ssd-sedc450r-1-tn.png"
                                alt='"DC450R 2.5" Enterprise SSD'
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__selections">
                              <div
                                className="f-input c-productCard4__details__content__selections__type"
                                role="radiogroup"
                                aria-label="Selections"
                              >
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection0"
                                    name="size16"
                                    checked=""
                                  />
                                  <label htmlFor="selection0">16 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection1"
                                    name="size32"
                                  />
                                  <label htmlFor="selection1">32 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection2"
                                    name="size64"
                                    disabled=""
                                  />
                                  <label htmlFor="selection2">64 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection3"
                                    name="size128"
                                  />
                                  <label htmlFor="selection3">128 GB</label>
                                </div>
                              </div>
                            </div>
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li>
                                  This is Bullet Number One and it goes on for a little while here.
                                </li>
                                <li>
                                  Second Bullet Point Goes Here, thank you very much for not keeping
                                  it short.
                                </li>
                                <li className="c-productCard4__details__content__longDesc__partNumber">
                                  Part Number: KTH-PL426/16G
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">Short Description</p>
                        </div>
                        <div className="c-productCard4__footer">
                          <button
                            className="e-btn e-btn--alt2 c-productCard4__footer__buyBtn"
                            aria-expanded="false"
                          >
                            <span>Buy</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
                              <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                            </svg>
                          </button>
                        </div>
                        <div className="c-productCard4__buyOptions" aria-hidden="true">
                          <div className="c-productCard4__buyOptions__panel">
                            <div className="c-productCard4__buyOptions__content">
                              <div className="c-productCard4__buyOptions__content__whereToBuy">
                                <a
                                  className="e-btn e-btn--alt2"
                                  target="_self"
                                  title="Button Title"
                                  href="#link"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg">
                                    <use href="../images/icons-map.svg#aae" />
                                  </svg>
                                  <span>Ask an Expert</span>
                                </a>
                                <a
                                  className="e-btn"
                                  target="_self"
                                  title="Button Title"
                                  href="#link"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg">
                                    <use href="/images/icons-map.svg#location" />
                                  </svg>
                                  <span>Where to Buy</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div className="c-productCard4" id="productCard4-3">
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">
                              UV500 SATA SSD
                            </span>
                          </a>
                        </div>
                        <span className="c-productCard4__partNumber">
                          Part Number:{" "}
                          <span className="c-productCard4__partNumber"> KTH-PL426/16G</span>
                        </span>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/kingston/product-card/ktc-product-ssd-suv500-1-tn.png"
                                alt="UV500 SATA SSD"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__selections">
                              <div
                                className="f-input c-productCard4__details__content__selections__type"
                                role="radiogroup"
                                aria-label="Selections"
                              >
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection0"
                                    name="size16"
                                    checked=""
                                  />
                                  <label htmlFor="selection0">16 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection1"
                                    name="size32"
                                  />
                                  <label htmlFor="selection1">32 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection2"
                                    name="size64"
                                    disabled=""
                                  />
                                  <label htmlFor="selection2">64 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection3"
                                    name="size128"
                                  />
                                  <label htmlFor="selection3">128 GB</label>
                                </div>
                              </div>
                            </div>
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li>
                                  This is Bullet Number One and it goes on for a little while here.
                                </li>
                                <li>
                                  Second Bullet Point Goes Here, thank you very much for not keeping
                                  it short.
                                </li>
                                <li className="c-productCard4__details__content__longDesc__partNumber">
                                  Part Number: KTH-PL426/16G
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">Short Description</p>
                        </div>
                        <div className="c-productCard4__footer">
                          <button
                            className="e-btn e-btn--alt2 c-productCard4__footer__buyBtn"
                            aria-expanded="false"
                          >
                            <span>Buy</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
                              <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                            </svg>
                          </button>
                        </div>
                        <div className="c-productCard4__buyOptions" aria-hidden="true">
                          <div className="c-productCard4__buyOptions__panel">
                            <div className="c-productCard4__buyOptions__content"></div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div className="c-productCard4 c-productCard4--singleCTA" id="productCard4-4">
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">
                              4GB Module - DDR4 2666MHz
                            </span>
                          </a>
                        </div>
                        <span className="c-productCard4__partNumber">
                          Part Number:{" "}
                          <span className="c-productCard4__partNumber"> KTH-PL426/16G</span>
                        </span>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/images/products/SA2000M8_250GB_tn.png"
                                alt="4GB Module - DDR4 2666MHz"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__selections">
                              <div
                                className="f-input c-productCard4__details__content__selections__type"
                                role="radiogroup"
                                aria-label="Selections"
                              >
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection0"
                                    name="size16"
                                    checked=""
                                  />
                                  <label htmlFor="selection0">16 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection1"
                                    name="size32"
                                  />
                                  <label htmlFor="selection1">32 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection2"
                                    name="size64"
                                    disabled=""
                                  />
                                  <label htmlFor="selection2">64 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection3"
                                    name="size128"
                                  />
                                  <label htmlFor="selection3">128 GB</label>
                                </div>
                              </div>
                            </div>
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li>
                                  This is Bullet Number One and it goes on for a little while here.
                                </li>
                                <li>
                                  Second Bullet Point Goes Here, thank you very much for not keeping
                                  it short.
                                </li>
                                <li className="c-productCard4__details__content__longDesc__partNumber">
                                  Part Number: KTH-PL426/16G
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">Short Description</p>
                        </div>
                        <div className="c-productCard4__footer"></div>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div className="c-productCard4" id="productCard4-5">
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">DRAM</span>
                          </a>
                        </div>
                        <span className="c-productCard4__partNumber">
                          Part Number:{" "}
                          <span className="c-productCard4__partNumber"> KTH-PL426/16G</span>
                        </span>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/kingston/product/ktc-product-usb-dtmicroduo3c-dtduo3c128gb-1-tn.png"
                                alt="DRAM"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__selections">
                              <div
                                className="f-input c-productCard4__details__content__selections__type"
                                role="radiogroup"
                                aria-label="Selections"
                              >
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection0"
                                    name="size16"
                                    checked=""
                                  />
                                  <label htmlFor="selection0">16 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection1"
                                    name="size32"
                                  />
                                  <label htmlFor="selection1">32 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection2"
                                    name="size64"
                                    disabled=""
                                  />
                                  <label htmlFor="selection2">64 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection3"
                                    name="size128"
                                  />
                                  <label htmlFor="selection3">128 GB</label>
                                </div>
                              </div>
                            </div>
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li>
                                  This is Bullet Number One and it goes on for a little while here.
                                </li>
                                <li>
                                  Second Bullet Point Goes Here, thank you very much for not keeping
                                  it short.
                                </li>
                                <li className="c-productCard4__details__content__longDesc__partNumber">
                                  Part Number: KTH-PL426/16G
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">Short Description</p>
                        </div>
                        <div className="c-productCard4__footer">
                          <button
                            className="e-btn e-btn--alt2 c-productCard4__footer__buyBtn"
                            aria-expanded="false"
                          >
                            <span>Buy</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
                              <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                            </svg>
                          </button>
                        </div>
                        <div className="c-productCard4__buyOptions" aria-hidden="true">
                          <div className="c-productCard4__buyOptions__panel">
                            <div className="c-productCard4__buyOptions__content">
                              <div className="c-productCard4__buyOptions__content__partnersCTA">
                                <h5 className="u-txt-center">Buy From</h5>
                                <div className="c-productCard4__buyOptions__content__partnersCTA__partners">
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div className="c-productCard4" id="productCard4-1">
                        <div className="e-tag c-productCard4__kit">
                          <span className="c-productCard4__kit__lg"> Kit of 4</span>
                          <span className="c-productCard4__kit__sm">x 4</span>
                        </div>
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">
                              DataTraveler MicroDuo 3C USB Flash Drive
                            </span>
                            <span className="e-tag">Sale</span>
                          </a>
                        </div>
                        <span className="c-productCard4__partNumber">
                          Part Number:{" "}
                          <span className="c-productCard4__partNumber"> KTH-PL426/16G</span>
                        </span>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/kingston/product/ktc-product-usb-ironkey-d300-standard-ikd300128gb-1-tn.png"
                                alt="DataTraveler MicroDuo 3C USB Flash Drive"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__selections">
                              <div
                                className="f-input c-productCard4__details__content__selections__type"
                                role="radiogroup"
                                aria-label="Selections"
                              >
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection0"
                                    name="size16"
                                    checked=""
                                  />
                                  <label htmlFor="selection0">16 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection1"
                                    name="size32"
                                  />
                                  <label htmlFor="selection1">32 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection2"
                                    name="size64"
                                    disabled=""
                                  />
                                  <label htmlFor="selection2">64 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection3"
                                    name="size128"
                                  />
                                  <label htmlFor="selection3">128 GB</label>
                                </div>
                              </div>
                            </div>
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li>
                                  This is Bullet Number One and it goes on for a little while here.
                                </li>
                                <li>
                                  Second Bullet Point Goes Here, thank you very much for not keeping
                                  it short.
                                </li>
                                <li className="c-productCard4__details__content__longDesc__partNumber">
                                  Part Number: KTH-PL426/16G
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">Short Description</p>
                        </div>
                        <div className="c-productCard4__footer">
                          <button
                            className="e-btn e-btn--alt2 c-productCard4__footer__buyBtn"
                            aria-expanded="false"
                          >
                            <span>Buy</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
                              <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                            </svg>
                          </button>
                        </div>
                        <div className="c-productCard4__buyOptions" aria-hidden="true">
                          <div className="c-productCard4__buyOptions__panel">
                            <div className="c-productCard4__buyOptions__content">
                              <div className="c-productCard4__buyOptions__content__whereToBuy">
                                <a
                                  className="e-btn e-btn--alt2"
                                  target="_self"
                                  title="Button Title"
                                  href="#link"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg">
                                    <use href="../images/icons-map.svg#aae" />
                                  </svg>
                                  <span>Ask an Expert</span>
                                </a>
                                <a
                                  className="e-btn"
                                  target="_self"
                                  title="Button Title"
                                  href="#link"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg">
                                    <use href="/images/icons-map.svg#location" />
                                  </svg>
                                  <span>Where to Buy</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div className="c-productCard4" id="productCard4-0">
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">
                              NV1 NVMe PCIe SSD
                            </span>
                            <span className="e-tag">Sale</span>
                          </a>
                        </div>
                        <span className="c-productCard4__partNumber">
                          Part Number:{" "}
                          <span className="c-productCard4__partNumber"> KTH-PL426/16G</span>
                        </span>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/kingston/product/ktc-product-ssd-snvs-500g-1-tn.png"
                                alt="NV1 NVMe PCIe SSD"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__selections">
                              <div
                                className="f-input c-productCard4__details__content__selections__type"
                                role="radiogroup"
                                aria-label="Selections"
                              >
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection0"
                                    name="size16"
                                    checked=""
                                  />
                                  <label htmlFor="selection0">16 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection1"
                                    name="size32"
                                  />
                                  <label htmlFor="selection1">32 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection2"
                                    name="size64"
                                    disabled=""
                                  />
                                  <label htmlFor="selection2">64 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection3"
                                    name="size128"
                                  />
                                  <label htmlFor="selection3">128 GB</label>
                                </div>
                              </div>
                            </div>
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li>
                                  This is Bullet Number One and it goes on for a little while here.
                                </li>
                                <li>
                                  Second Bullet Point Goes Here, thank you very much for not keeping
                                  it short.
                                </li>
                                <li className="c-productCard4__details__content__longDesc__partNumber">
                                  Part Number: KTH-PL426/16G
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">Short Description</p>
                        </div>
                        <div className="c-productCard4__footer">
                          <div className="c-productCard4__footer__price">
                            <div className="c-productCard4__footer__price__container">
                              <span className="c-productCard4__footer__price__container__amount">
                                $66.98
                              </span>
                              <span className="u-txt-strike c-productCard4__footer__price__container__strike">
                                $122.54
                              </span>
                            </div>
                          </div>
                          <button
                            className="e-btn e-btn--alt2 c-productCard4__footer__buyBtn"
                            aria-expanded="false"
                          >
                            <span>Buy</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
                              <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                            </svg>
                          </button>
                        </div>
                        <div className="c-productCard4__buyOptions" aria-hidden="true">
                          <div className="c-productCard4__buyOptions__panel">
                            <div className="c-productCard4__buyOptions__content">
                              <form className="c-productCard4__buyOptions__content__qtyCTA">
                                <label className="f-quantity__label" htmlFor="quant1">
                                  Quantity
                                </label>
                                <div className="f-quantity js-quantity" id="jsQuantity0">
                                  <div className="f-quantity__counter">
                                    <button
                                      className="f-quantity__counter__btn js-quantity__btn "
                                      aria-label="Decrease Quantity"
                                      tabIndex="-1"
                                    >
                                      <span
                                        className="f-quantity__counter__btn__inner"
                                        tabIndex="-1"
                                      >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 2">
                                          <title>Minus Icon</title>
                                          <path d="M0 0h16v2H0z" />
                                        </svg>
                                      </span>
                                    </button>
                                    <input
                                      className="f-quantity__counter__input"
                                      type="text"
                                      maxLength="4"
                                      id="quant1"
                                      name="fQuantity0"
                                      value="1"
                                    />
                                    <button
                                      className="f-quantity__counter__btn js-quantity__btn"
                                      aria-label="Increase Quantity"
                                      data-add="true"
                                    >
                                      <span
                                        className="f-quantity__counter__btn__inner"
                                        tabIndex="-1"
                                      >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                          <title>Plus Icon</title>
                                          <path d="M0 7h16v2H0z" />
                                          <path d="M7 0h2v16H7z" />
                                        </svg>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <button className="e-btn" title="Button Title">
                                  <svg xmlns="http://www.w3.org/2000/svg">
                                    <use href="/images/icons-map.svg#btn-cart" />
                                  </svg>
                                  <span>Add to Cart</span>
                                </button>
                              </form>
                              <h5 className="u-txt-center">Or Buy From</h5>
                              <div className="c-productCard4__buyOptions__content__partnersCTA">
                                <div className="c-productCard4__buyOptions__content__partnersCTA__partners">
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div className="c-productCard4 c-productCard4--singleCTA" id="productCard4-0">
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">
                              Canvas Go! Plus SD Memory Card
                            </span>
                            <span className="e-tag">Sale</span>
                          </a>
                        </div>
                        <span className="c-productCard4__partNumber">
                          Part Number:{" "}
                          <span className="c-productCard4__partNumber"> KTH-PL426/16G</span>
                        </span>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/kingston/product/ktc-product-flash-sdcards-sdg3-512gb-1-tn.png"
                                alt="Canvas Go! Plus SD Memory Card"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__selections">
                              <div
                                className="f-input c-productCard4__details__content__selections__type"
                                role="radiogroup"
                                aria-label="Selections"
                              >
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection0"
                                    name="size16"
                                    checked=""
                                  />
                                  <label htmlFor="selection0">16 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection1"
                                    name="size32"
                                  />
                                  <label htmlFor="selection1">32 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection2"
                                    name="size64"
                                    disabled=""
                                  />
                                  <label htmlFor="selection2">64 GB</label>
                                </div>
                                <div className="f-input__radio">
                                  <input
                                    type="radio"
                                    className="radio-button"
                                    id="selection3"
                                    name="size128"
                                  />
                                  <label htmlFor="selection3">128 GB</label>
                                </div>
                              </div>
                            </div>
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li>
                                  This is Bullet Number One and it goes on for a little while here.
                                </li>
                                <li>
                                  Second Bullet Point Goes Here, thank you very much for not keeping
                                  it short.
                                </li>
                                <li className="c-productCard4__details__content__longDesc__partNumber">
                                  Part Number: KTH-PL426/16G
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">Short Description</p>
                        </div>
                        <div className="c-productCard4__footer">
                          <span>
                            Replaced By{" "}
                            <a href="#link" title="KTH-PL426/16G">
                              KTH-PL426/16G
                            </a>
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="s-productGallery3__filterLayout__view__loadMore">
                    <a className="e-btn" target="_self" title="Button Title" href="#loadmore">
                      <span>Load More</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="l-tabView__panels__panel "
              role="tabpanel"
              aria-labelledby="tab0_1"
              id="tabContent0_1"
            >
              <div className="s-productGallery3__filterLayout">
                <div className="s-productGallery3__filterLayout__controls">
                  <div className="s-productGallery3__filterLayout__controls__section">
                    <h5 className="u-txt-uppercase">Search</h5>
                    <div className="c-accordion c-accordion--noOutline">
                      <span className="c-accordion__tab false">
                        <button aria-expanded="false">
                          System/Device
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden="true"
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                          </svg>
                        </button>
                      </span>
                      <div className="c-accordion__panel">
                        <div className="c-accordion__panel__content">
                          <div className="c-searchCard">
                            <div className="c-searchCard__body">
                              <p>
                                Simply enter the make and model number or system part number of the
                                computer system or digital device to find the memory you need.
                              </p>
                              <form className="c-searchCard__body__cta">
                                <div className="f-input f-input--outlined">
                                  <div className="f-input__string">
                                    <input
                                      type="text"
                                      name="makeModel"
                                      id="makeModel"
                                      required=""
                                      pattern="[a-zA-Z0-9]+"
                                    />
                                    <label htmlFor="makeModel">Make/Model</label>
                                  </div>
                                </div>
                                <a className="e-btn" href="#link" aria-label="Search">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.2 16.7">
                                    <path d="M9.8 0C5.7 0 2.4 3.3 2.4 7.4s3.3 7.4 7.4 7.4 7.4-3.3 7.4-7.4S13.9 0 9.8 0zm0 12.8c-3 0-5.3-2.4-5.3-5.3 0-3 2.4-5.3 5.3-5.3s5.3 2.4 5.3 5.3-2.4 5.3-5.3 5.3z" />
                                    <path d="M4.5 10.5L.4 14.6c-.5.5-.5 1.2 0 1.7s1.2.5 1.7 0l4.1-4.1" />
                                  </svg>
                                </a>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="c-accordion__tab false">
                        <button aria-controls="accPanel-m2vhwm" aria-expanded="false">
                          Memory Part Number
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden="true"
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                          </svg>
                        </button>
                      </span>
                      <div className="c-accordion__panel">
                        <div className="c-accordion__panel__content">
                          <div className="c-searchCard">
                            <div className="c-searchCard__body">
                              <p>
                                Search by either the Kingston part number, distributor part number
                                or manufacturer equivalent part number.
                              </p>
                              <form className="c-searchCard__body__cta">
                                <div className="f-input f-input--outlined">
                                  <div className="f-input__string">
                                    <input
                                      type="text"
                                      name="partNumber"
                                      id="partNumber"
                                      required=""
                                      pattern="[a-zA-Z0-9]+"
                                    />
                                    <label htmlFor="partNumber">Part Number</label>
                                  </div>
                                </div>
                                <a className="e-btn" href="#link" aria-label="Search">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.2 16.7">
                                    <path d="M9.8 0C5.7 0 2.4 3.3 2.4 7.4s3.3 7.4 7.4 7.4 7.4-3.3 7.4-7.4S13.9 0 9.8 0zm0 12.8c-3 0-5.3-2.4-5.3-5.3 0-3 2.4-5.3 5.3-5.3s5.3 2.4 5.3 5.3-2.4 5.3-5.3 5.3z" />
                                    <path d="M4.5 10.5L.4 14.6c-.5.5-.5 1.2 0 1.7s1.2.5 1.7 0l4.1-4.1" />
                                  </svg>
                                </a>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="s-productGallery3__filterLayout__controls__section">
                    <h5 className="u-txt-uppercase">Browse</h5>
                    <ul>
                      <li>
                        <a href="#serverLink" title="Anchor Title">
                          Server Memory
                        </a>
                      </li>
                      <li>
                        <a href="#desktopLink" title="Anchor Title">
                          Desktop/Laptop Memory
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="c-slideMenu s-productGallery3__filterLayout__slides"
                  id="slideMenu0"
                >
                  <div
                    className="c-slideMenu__slider c-filterCard s-productGallery3__filterLayout__slides__filter "
                    data-filterid="filterCard0"
                    data-dropdown="true"
                  >
                    <button className="c-slideMenu__slider__toggle" aria-expanded="false">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <use href="../images/icons-map.svg#filter" />
                      </svg>
                      <span className="u-h5 u-txt-uppercase c-filterCard__title">Filter</span>
                    </button>
                    <div className="c-filterCard__results">
                      <span data-results="0">Results</span>
                    </div>
                    <form className="c-slideMenu__slider__panel c-filterCard__details">
                      <div className="c-slideMenu__slider__panel__content c-filterCard__details__content">
                        <div className="c-accordion c-accordion--noOutline">
                          <span className="c-accordion__tab false">
                            <button aria-expanded="false" type="button">
                              Lightning
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="c-accordion__tab__icon"
                                viewBox="0 0 14 8"
                                aria-hidden="true"
                              >
                                <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                              </svg>
                            </button>
                          </span>
                          <div className="c-accordion__panel">
                            <div className="c-accordion__panel__content">
                              <ul className="u-list-unstyled">
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="LightningfilterBtnsID0"
                                        id="filterBtnsIDLightning0"
                                        value="valLightning0"
                                      />
                                      <label htmlFor="filterBtnsIDLightning0">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="LightningfilterBtnsID1"
                                        id="filterBtnsIDLightning1"
                                        value="valLightning1"
                                      />
                                      <label htmlFor="filterBtnsIDLightning1">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="c-accordion c-accordion--noOutline">
                          <span className="c-accordion__tab false">
                            <button aria-expanded="false" type="button">
                              Wired
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="c-accordion__tab__icon"
                                viewBox="0 0 14 8"
                                aria-hidden="true"
                              >
                                <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                              </svg>
                            </button>
                          </span>
                          <div className="c-accordion__panel">
                            <div className="c-accordion__panel__content">
                              <ul className="u-list-unstyled">
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="WiredfilterBtnsID0"
                                        id="filterBtnsIDWired0"
                                        value="valWired0"
                                      />
                                      <label htmlFor="filterBtnsIDWired0">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="WiredfilterBtnsID1"
                                        id="filterBtnsIDWired1"
                                        value="valWired1"
                                      />
                                      <label htmlFor="filterBtnsIDWired1">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="WiredfilterBtnsID2"
                                        id="filterBtnsIDWired2"
                                        value="valWired2"
                                      />
                                      <label htmlFor="filterBtnsIDWired2">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="c-accordion c-accordion--noOutline">
                          <span className="c-accordion__tab false">
                            <button aria-expanded="false" type="button">
                              Platform
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="c-accordion__tab__icon"
                                viewBox="0 0 14 8"
                                aria-hidden="true"
                              >
                                <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                              </svg>
                            </button>
                          </span>
                          <div className="c-accordion__panel">
                            <div className="c-accordion__panel__content">
                              <ul className="u-list-unstyled">
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="PlatformfilterBtnsID0"
                                        id="filterBtnsIDPlatform0"
                                        value="valPlatform0"
                                      />
                                      <label htmlFor="filterBtnsIDPlatform0">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="PlatformfilterBtnsID1"
                                        id="filterBtnsIDPlatform1"
                                        value="valPlatform1"
                                      />
                                      <label htmlFor="filterBtnsIDPlatform1">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="c-filterCard__details__content__info">
                          <a href="#anchor" title="anchor">
                            Search HTS/ECCN
                          </a>
                        </div>
                      </div>
                      <div className="c-slideMenu__slider__panel__cta c-filterCard__details__cta">
                        <button
                          className="e-btn e-btn--alt2 c-filterCard__details__cta__reset"
                          type="button"
                        >
                          Reset
                        </button>
                        <button
                          className="e-btn c-slideMenu__slider__panel__cta__closeBtn c-filterCard__details__cta__close"
                          type="button"
                        >
                          Close
                        </button>
                        <button className="e-btn c-filterCard__details__cta__apply">Apply</button>
                      </div>
                    </form>
                  </div>
                  <div className="c-slideMenu__slider c-slideMenu__slider--right s-productGallery3__filterLayout__slides__sort false">
                    <button className="c-slideMenu__slider__toggle" aria-expanded="false">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <use href="../images/icons-map.svg#sort" />
                      </svg>
                      <span className="u-h5 u-txt-uppercase">Sort</span>
                    </button>
                    <form className="c-slideMenu__slider__panel s-productGallery3__filterLayout__slides__sort__details">
                      <div className="c-slideMenu__slider__panel__content s-productGallery3__filterLayout__slides__sort__details__content">
                        <ul className="u-list-unstyled">
                          <li>
                            <div className="f-input">
                              <div className="f-input__radio">
                                <input
                                  type="radio"
                                  name="radioName"
                                  id="radioName0"
                                  value="Featured"
                                />
                                <label htmlFor="radioName0" aria-checked="false">
                                  Featured
                                </label>
                              </div>
                              <div className="f-input__radio">
                                <input
                                  type="radio"
                                  name="radioName"
                                  id="radioName1"
                                  value="Newest"
                                />
                                <label htmlFor="radioName1" aria-checked="false">
                                  Newest
                                </label>
                              </div>
                              <div className="f-input__radio">
                                <input
                                  type="radio"
                                  name="radioName"
                                  id="radioName2"
                                  value="Oldest"
                                />
                                <label htmlFor="radioName2" aria-checked="false">
                                  Oldest
                                </label>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="c-slideMenu__slider__panel__cta">
                        <button
                          className="e-btn c-slideMenu__slider__panel__cta__closeBtn"
                          type="button"
                        >
                          Close
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="s-productGallery3__filterLayout__view">
                  <div className="s-productGallery3__filterLayout__view__sort">
                    <div
                      className="c-filterCard__dropDown s-productGallery3__filterLayout__view__sort__display s-productGallery3__filterLayout__view__sort__filter u-hide"
                      data-target="filterCard0"
                      data-id="filterCard0"
                    >
                      <div className="s-productGallery3__filterLayout__view__sort__display">
                        <div
                          className="c-dropDown c-dropDown--absolute false"
                          data-type="selection"
                          id="dropDown0"
                        >
                          <div
                            className="c-dropDown__toggler"
                            aria-expanded="false"
                            aria-controls="dropDownPanel0"
                          >
                            <div className="c-dropDown__toggler__selection">
                              <span className="c-dropDown__toggler__selection__value u-txt-bold"></span>
                              <span className="c-dropDown__toggler__selection">
                                <span className="u-txt-bold c-dropDown__toggler__selection__single">
                                  Filter
                                </span>
                                <span className="u-txt-bold c-dropDown__toggler__selection__plural">
                                  Filters
                                </span>
                              </span>
                              Applied
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="8"
                              viewBox="0 0 14 8"
                            >
                              <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                            </svg>
                          </div>
                          <div className="c-dropDown__panel" id="dropDownPanel0">
                            <button className="c-dropDown__panel__clearBtn">Clear All</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="s-productGallery3__filterLayout__view__sort__display s-productGallery3__filterLayout__view__sort__layout">
                      <div
                        className="c-dropDown c-dropDown--absolute false"
                        data-type="selection"
                        id="dropDown1"
                        role="button"
                        tabIndex="-1"
                      >
                        <div
                          className="c-dropDown__toggler"
                          aria-expanded="false"
                          aria-controls="dropDownPanel1"
                        >
                          <div className="c-dropDown__toggler__selection">
                            <span className="c-dropDown__toggler__selection">
                              Sort By{" "}
                              <span className="c-dropDown__toggler__selection__value u-txt-bold">
                                Grid
                              </span>
                            </span>
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                          </svg>
                        </div>
                        <div className="c-dropDown__panel" id="dropDownPanel1">
                          <ul>
                            <li className="c-dropDown__panel__item false">Grid</li>
                            <li className="c-dropDown__panel__item">List</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="s-productGallery3__filterLayout__view__sort__display s-productGallery3__filterLayout__view__sort__options">
                      <div className="c-dropDown c-dropDown--absolute false" id="dropDown2">
                        <div
                          className="c-dropDown__toggler"
                          aria-expanded="false"
                          aria-controls="dropDownPanel2"
                          role="button"
                          tabIndex="-1"
                        >
                          <div className="c-dropDown__toggler__selection">
                            <span className="c-dropDown__toggler__selection">
                              Sort By{" "}
                              <span className="c-dropDown__toggler__selection__value u-txt-bold">
                                Featured
                              </span>
                            </span>
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                          </svg>
                        </div>
                        <div className="c-dropDown__panel" id="dropDownPanel2">
                          <ul>
                            <li className="c-dropDown__panel__item">Oldest</li>
                            <li className="c-dropDown__panel__item">Newest</li>
                            <li className="c-dropDown__panel__item c-dropDown__panel__item--active">
                              Featured
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="l-gridFlex s-productGallery3__grid" data-min="288" data-max="480">
                    <li className="s-productGallery3__grid__cell">
                      <div className="c-productCard4 c-productCard4--product" id="productCard4-9">
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">Product Name</span>
                            <span className="e-tag">Sale</span>
                          </a>
                        </div>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/kingston/product/ktc-product-usb-dt100g3-dt100g3256gb-1-tn.png"
                                alt="Product Name"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                            </div>
                            <button
                              className="c-productCard4__details__content__btn"
                              aria-expanded="false"
                            >
                              <span className="c-productCard4__details__content__btn__showMore">
                                SHOW MORE +
                              </span>
                              <span className="c-productCard4__details__content__btn__showLess">
                                SHOW LESS -
                              </span>
                            </button>
                          </div>
                          <p className="c-productCard4__details__shortDesc">Short Description</p>
                        </div>
                        <div className="c-productCard4__footer">
                          <button className="e-btn e-btn--alt2" title="Button Title">
                            <span>Learn More</span>
                          </button>
                          <button className="e-btn" title="Button Title">
                            <span>Buy</span>
                          </button>
                        </div>
                        <a
                          className="c-productCard4__arrow"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                          </svg>
                        </a>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div
                        className="c-productCard4 c-productCard4--product c-productCard4--singleCTA"
                        id="productCard4-1"
                      >
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">
                              DataTraveler Elite G2 USB Flash Drive
                            </span>
                          </a>
                        </div>
                        <span className="c-productCard4__partNumber">
                          Part Number:{" "}
                          <span className="c-productCard4__partNumber"> KTH-PL426/16G</span>
                        </span>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/kingston/product/ktc-product-usb-dteliteg2-dteg2128gb-1-tn.png"
                                alt="DataTraveler Elite G2 USB Flash Drive"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li>Shock and water-resistant casing, removable cap</li>
                                <li>USB 3.1 Gen 1 (USB 3.0)</li>
                                <li>64GB, 128GB</li>
                                <li>Up to 180MB/s read, 70MB/s write</li>
                              </ul>
                            </div>
                            <button
                              className="c-productCard4__details__content__btn"
                              aria-expanded="false"
                            >
                              <span className="c-productCard4__details__content__btn__showMore">
                                SHOW MORE +
                              </span>
                              <span className="c-productCard4__details__content__btn__showLess">
                                SHOW LESS -
                              </span>
                            </button>
                          </div>
                          <p className="c-productCard4__details__shortDesc">Short Description</p>
                        </div>
                        <div className="c-productCard4__footer">
                          <button className="e-btn" title="Button Title">
                            <span>Learn More</span>
                          </button>
                        </div>
                        <a
                          className="c-productCard4__arrow"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                          </svg>
                        </a>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div className="c-productCard4 c-productCard4--product" id="productCard4-2">
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">
                              DC450R 2.5 Enterprise SSD
                            </span>
                          </a>
                        </div>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/kingston/product/ktc-product-ssd-sedc450r-1-tn.png"
                                alt='"DC450R 2.5" Enterprise SSD'
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li>Optimized for read-centric applications</li>
                                <li>2.5 form factor</li>
                                <li>480GB, 960GB, 1.92TB, 3.84TB, 7.68TB</li>
                                <li>Up to 560MB/s read, 530MB/s write</li>
                              </ul>
                            </div>
                            <button
                              className="c-productCard4__details__content__btn"
                              aria-expanded="false"
                            >
                              <span className="c-productCard4__details__content__btn__showMore">
                                SHOW MORE +
                              </span>
                              <span className="c-productCard4__details__content__btn__showLess">
                                SHOW LESS -
                              </span>
                            </button>
                          </div>
                          <p className="c-productCard4__details__shortDesc">Short Description</p>
                        </div>
                        <a
                          className="c-productCard4__arrow"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                          </svg>
                        </a>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div
                        className="c-productCard4 c-productCard4--product c-productCard4--singleCTA"
                        id="productCard4-3"
                      >
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">
                              UV500 SATA SSD
                            </span>
                          </a>
                        </div>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/kingston/product-card/ktc-product-ssd-suv500-1-tn.png"
                                alt="UV500 SATA SSD"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__longDesc">
                              <p>HyperX Cloud Stinger Core Gaming Headset</p>
                            </div>
                            <button
                              className="c-productCard4__details__content__btn"
                              aria-expanded="false"
                            >
                              <span className="c-productCard4__details__content__btn__showMore">
                                SHOW MORE +
                              </span>
                              <span className="c-productCard4__details__content__btn__showLess">
                                SHOW LESS -
                              </span>
                            </button>
                          </div>
                          <p className="c-productCard4__details__shortDesc">Short Description</p>
                        </div>
                        <div className="c-productCard4__footer">
                          <button className="e-btn" title="Button Title">
                            <span>Buy</span>
                          </button>
                        </div>
                        <a
                          className="c-productCard4__arrow"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                          </svg>
                        </a>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div className="c-productCard4 c-productCard4--product" id="productCard4-4">
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">
                              4GB Module - DDR4 2666MHz
                            </span>
                          </a>
                        </div>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/images/products/SA2000M8_250GB_tn.png"
                                alt="4GB Module - DDR4 2666MHz"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li>
                                  This is Bullet Number One and it goes on for a little while here.
                                </li>
                                <li>
                                  Second Bullet Point Goes Here, thank you very much for not keeping
                                  it short.
                                </li>
                                <li>Maybe a Third One As Well, who knows……</li>
                                <li>Maybe a Third One As Well</li>
                                <li>Second Bullet Point Goes Here</li>
                              </ul>
                            </div>
                            <button
                              className="c-productCard4__details__content__btn"
                              aria-expanded="false"
                            >
                              <span className="c-productCard4__details__content__btn__showMore">
                                SHOW MORE +
                              </span>
                              <span className="c-productCard4__details__content__btn__showLess">
                                SHOW LESS -
                              </span>
                            </button>
                          </div>
                          <p className="c-productCard4__details__shortDesc">Short Description</p>
                        </div>
                        <a
                          className="c-productCard4__arrow"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                          </svg>
                        </a>
                      </div>
                    </li>
                  </ul>
                  <div className="s-productGallery3__filterLayout__view__loadMore">
                    <a className="e-btn" target="_self" title="Button Title" href="#loadmore">
                      <span>Load More</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="l-tabView__panels__panel "
              role="tabpanel"
              aria-labelledby="tab0_2"
              id="tabContent0_2"
            >
              <div className="s-productGallery3__filterLayout">
                <div className="s-productGallery3__filterLayout__controls">
                  <div className="s-productGallery3__filterLayout__controls__section">
                    <h5 className="u-txt-uppercase">Search</h5>
                    <div className="c-accordion c-accordion--noOutline">
                      <span className="c-accordion__tab false">
                        <button aria-expanded="false">
                          System/Device
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden="true"
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                          </svg>
                        </button>
                      </span>
                      <div className="c-accordion__panel">
                        <div className="c-accordion__panel__content">
                          <div className="c-searchCard">
                            <div className="c-searchCard__body">
                              <p>
                                Simply enter the make and model number or system part number of the
                                computer system or digital device to find the memory you need.
                              </p>
                              <form className="c-searchCard__body__cta">
                                <div className="f-input f-input--outlined">
                                  <div className="f-input__string">
                                    <input
                                      type="text"
                                      name="makeModel"
                                      id="makeModel"
                                      required=""
                                      pattern="[a-zA-Z0-9]+"
                                    />
                                    <label htmlFor="makeModel">Make/Model</label>
                                  </div>
                                </div>
                                <a className="e-btn" href="#link" aria-label="Search">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.2 16.7">
                                    <path d="M9.8 0C5.7 0 2.4 3.3 2.4 7.4s3.3 7.4 7.4 7.4 7.4-3.3 7.4-7.4S13.9 0 9.8 0zm0 12.8c-3 0-5.3-2.4-5.3-5.3 0-3 2.4-5.3 5.3-5.3s5.3 2.4 5.3 5.3-2.4 5.3-5.3 5.3z" />
                                    <path d="M4.5 10.5L.4 14.6c-.5.5-.5 1.2 0 1.7s1.2.5 1.7 0l4.1-4.1" />
                                  </svg>
                                </a>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="c-accordion__tab false">
                        <button aria-controls="accPanel-m2vhwm" aria-expanded="false">
                          Memory Part Number
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden="true"
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                          </svg>
                        </button>
                      </span>
                      <div className="c-accordion__panel">
                        <div className="c-accordion__panel__content">
                          <div className="c-searchCard">
                            <div className="c-searchCard__body">
                              <p>
                                Search by either the Kingston part number, distributor part number
                                or manufacturer equivalent part number.
                              </p>
                              <form className="c-searchCard__body__cta">
                                <div className="f-input f-input--outlined">
                                  <div className="f-input__string">
                                    <input
                                      type="text"
                                      name="partNumber"
                                      id="partNumber"
                                      required=""
                                      pattern="[a-zA-Z0-9]+"
                                    />
                                    <label htmlFor="partNumber">Part Number</label>
                                  </div>
                                </div>
                                <a className="e-btn" href="#link" aria-label="Search">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.2 16.7">
                                    <path d="M9.8 0C5.7 0 2.4 3.3 2.4 7.4s3.3 7.4 7.4 7.4 7.4-3.3 7.4-7.4S13.9 0 9.8 0zm0 12.8c-3 0-5.3-2.4-5.3-5.3 0-3 2.4-5.3 5.3-5.3s5.3 2.4 5.3 5.3-2.4 5.3-5.3 5.3z" />
                                    <path d="M4.5 10.5L.4 14.6c-.5.5-.5 1.2 0 1.7s1.2.5 1.7 0l4.1-4.1" />
                                  </svg>
                                </a>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="s-productGallery3__filterLayout__controls__section">
                    <h5 className="u-txt-uppercase">Browse</h5>
                    <ul>
                      <li>
                        <a href="#serverLink" title="Anchor Title">
                          Server Memory
                        </a>
                      </li>
                      <li>
                        <a href="#desktopLink" title="Anchor Title">
                          Desktop/Laptop Memory
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="c-slideMenu s-productGallery3__filterLayout__slides"
                  id="slideMenu0"
                >
                  <div
                    className="c-slideMenu__slider c-filterCard s-productGallery3__filterLayout__slides__filter "
                    data-filterid="filterCard0"
                    data-dropdown="true"
                  >
                    <button className="c-slideMenu__slider__toggle" aria-expanded="false">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <use href="../images/icons-map.svg#filter" />
                      </svg>
                      <span className="u-h5 u-txt-uppercase c-filterCard__title">Filter</span>
                    </button>
                    <div className="c-filterCard__results">
                      <span data-results="0">Results</span>
                    </div>
                    <form className="c-slideMenu__slider__panel c-filterCard__details">
                      <div className="c-slideMenu__slider__panel__content c-filterCard__details__content">
                        <div className="c-accordion c-accordion--noOutline">
                          <span className="c-accordion__tab false">
                            <button aria-expanded="false" type="button">
                              Lightning
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="c-accordion__tab__icon"
                                viewBox="0 0 14 8"
                                aria-hidden="true"
                              >
                                <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                              </svg>
                            </button>
                          </span>
                          <div className="c-accordion__panel">
                            <div className="c-accordion__panel__content">
                              <ul className="u-list-unstyled">
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="LightningfilterBtnsID0"
                                        id="filterBtnsIDLightning0"
                                        value="valLightning0"
                                      />
                                      <label htmlFor="filterBtnsIDLightning0">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="LightningfilterBtnsID1"
                                        id="filterBtnsIDLightning1"
                                        value="valLightning1"
                                      />
                                      <label htmlFor="filterBtnsIDLightning1">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="c-accordion c-accordion--noOutline">
                          <span className="c-accordion__tab false">
                            <button aria-expanded="false" type="button">
                              Wired
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="c-accordion__tab__icon"
                                viewBox="0 0 14 8"
                                aria-hidden="true"
                              >
                                <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                              </svg>
                            </button>
                          </span>
                          <div className="c-accordion__panel">
                            <div className="c-accordion__panel__content">
                              <ul className="u-list-unstyled">
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="WiredfilterBtnsID0"
                                        id="filterBtnsIDWired0"
                                        value="valWired0"
                                      />
                                      <label htmlFor="filterBtnsIDWired0">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="WiredfilterBtnsID1"
                                        id="filterBtnsIDWired1"
                                        value="valWired1"
                                      />
                                      <label htmlFor="filterBtnsIDWired1">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="WiredfilterBtnsID2"
                                        id="filterBtnsIDWired2"
                                        value="valWired2"
                                      />
                                      <label htmlFor="filterBtnsIDWired2">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="c-accordion c-accordion--noOutline">
                          <span className="c-accordion__tab false">
                            <button aria-expanded="false" type="button">
                              Platform
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="c-accordion__tab__icon"
                                viewBox="0 0 14 8"
                                aria-hidden="true"
                              >
                                <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                              </svg>
                            </button>
                          </span>
                          <div className="c-accordion__panel">
                            <div className="c-accordion__panel__content">
                              <ul className="u-list-unstyled">
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="PlatformfilterBtnsID0"
                                        id="filterBtnsIDPlatform0"
                                        value="valPlatform0"
                                      />
                                      <label htmlFor="filterBtnsIDPlatform0">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="f-input">
                                    <div className="f-input__checkbox">
                                      <input
                                        type="checkbox"
                                        name="PlatformfilterBtnsID1"
                                        id="filterBtnsIDPlatform1"
                                        value="valPlatform1"
                                      />
                                      <label htmlFor="filterBtnsIDPlatform1">Checkbox Label</label>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="c-filterCard__details__content__info">
                          <a href="#anchor" title="anchor">
                            Search HTS/ECCN
                          </a>
                        </div>
                      </div>
                      <div className="c-slideMenu__slider__panel__cta c-filterCard__details__cta">
                        <button
                          className="e-btn e-btn--alt2 c-filterCard__details__cta__reset"
                          type="button"
                        >
                          Reset
                        </button>
                        <button
                          className="e-btn c-slideMenu__slider__panel__cta__closeBtn c-filterCard__details__cta__close"
                          type="button"
                        >
                          Close
                        </button>
                        <button className="e-btn c-filterCard__details__cta__apply">Apply</button>
                      </div>
                    </form>
                  </div>
                  <div className="c-slideMenu__slider c-slideMenu__slider--right s-productGallery3__filterLayout__slides__sort false">
                    <button className="c-slideMenu__slider__toggle" aria-expanded="false">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <use href="../images/icons-map.svg#sort" />
                      </svg>
                      <span className="u-h5 u-txt-uppercase">Sort</span>
                    </button>
                    <form className="c-slideMenu__slider__panel s-productGallery3__filterLayout__slides__sort__details">
                      <div className="c-slideMenu__slider__panel__content s-productGallery3__filterLayout__slides__sort__details__content">
                        <ul className="u-list-unstyled">
                          <li>
                            <div className="f-input">
                              <div className="f-input__radio">
                                <input
                                  type="radio"
                                  name="radioName"
                                  id="radioName0"
                                  value="Featured"
                                />
                                <label htmlFor="radioName0" aria-checked="false">
                                  Featured
                                </label>
                              </div>
                              <div className="f-input__radio">
                                <input
                                  type="radio"
                                  name="radioName"
                                  id="radioName1"
                                  value="Newest"
                                />
                                <label htmlFor="radioName1" aria-checked="false">
                                  Newest
                                </label>
                              </div>
                              <div className="f-input__radio">
                                <input
                                  type="radio"
                                  name="radioName"
                                  id="radioName2"
                                  value="Oldest"
                                />
                                <label htmlFor="radioName2" aria-checked="false">
                                  Oldest
                                </label>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="c-slideMenu__slider__panel__cta">
                        <button
                          className="e-btn c-slideMenu__slider__panel__cta__closeBtn"
                          type="button"
                        >
                          Close
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="s-productGallery3__filterLayout__view">
                  <div className="s-productGallery3__filterLayout__view__sort">
                    <div
                      className="c-filterCard__dropDown s-productGallery3__filterLayout__view__sort__display s-productGallery3__filterLayout__view__sort__filter u-hide"
                      data-target="filterCard0"
                      data-id="filterCard0"
                    >
                      <div className="s-productGallery3__filterLayout__view__sort__display">
                        <div
                          className="c-dropDown c-dropDown--absolute false"
                          data-type="selection"
                          id="dropDown0"
                        >
                          <div
                            className="c-dropDown__toggler"
                            aria-expanded="false"
                            aria-controls="dropDownPanel0"
                          >
                            <div className="c-dropDown__toggler__selection">
                              <span className="c-dropDown__toggler__selection__value u-txt-bold"></span>
                              <span className="c-dropDown__toggler__selection">
                                <span className="u-txt-bold c-dropDown__toggler__selection__single">
                                  Filter
                                </span>
                                <span className="u-txt-bold c-dropDown__toggler__selection__plural">
                                  Filters
                                </span>
                              </span>
                              Applied
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="8"
                              viewBox="0 0 14 8"
                            >
                              <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                            </svg>
                          </div>
                          <div className="c-dropDown__panel" id="dropDownPanel0">
                            <button className="c-dropDown__panel__clearBtn">Clear All</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="s-productGallery3__filterLayout__view__sort__display s-productGallery3__filterLayout__view__sort__layout">
                      <div
                        className="c-dropDown c-dropDown--absolute false"
                        data-type="selection"
                        id="dropDown1"
                        role="button"
                        tabIndex="-1"
                      >
                        <div
                          className="c-dropDown__toggler"
                          aria-expanded="false"
                          aria-controls="dropDownPanel1"
                        >
                          <div className="c-dropDown__toggler__selection">
                            <span className="c-dropDown__toggler__selection">
                              Sort By{" "}
                              <span className="c-dropDown__toggler__selection__value u-txt-bold">
                                Grid
                              </span>
                            </span>
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                          </svg>
                        </div>
                        <div className="c-dropDown__panel" id="dropDownPanel1">
                          <ul>
                            <li className="c-dropDown__panel__item false">Grid</li>
                            <li className="c-dropDown__panel__item">List</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="s-productGallery3__filterLayout__view__sort__display s-productGallery3__filterLayout__view__sort__options">
                      <div className="c-dropDown c-dropDown--absolute false" id="dropDown2">
                        <div
                          className="c-dropDown__toggler"
                          aria-expanded="false"
                          aria-controls="dropDownPanel2"
                          role="button"
                          tabIndex="-1"
                        >
                          <div className="c-dropDown__toggler__selection">
                            <span className="c-dropDown__toggler__selection">
                              Sort By{" "}
                              <span className="c-dropDown__toggler__selection__value u-txt-bold">
                                Featured
                              </span>
                            </span>
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                          </svg>
                        </div>
                        <div className="c-dropDown__panel" id="dropDownPanel2">
                          <ul>
                            <li className="c-dropDown__panel__item">Oldest</li>
                            <li className="c-dropDown__panel__item">Newest</li>
                            <li className="c-dropDown__panel__item c-dropDown__panel__item--active">
                              Featured
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul
                    className="l-gridFlex s-productGallery3__grid l-gridFlex--list"
                    data-min="288"
                    data-max="480"
                  >
                    <li className="s-productGallery3__grid__cell">
                      <div
                        className="c-productCard4 c-productCard4--dram c-productCard4--listView"
                        id="productCard4-0"
                      >
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">Product Name</span>
                            <span className="e-tag">Sale</span>
                          </a>
                        </div>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/kingston/product/DDR4_Non-ECC_Unbuffered_SODIMM_4GB_1-tn.png"
                                alt="Product Name"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li></li>
                                <li></li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">Short Description</p>
                        </div>
                        <div className="c-productCard4__footer">
                          <button
                            className="e-btn e-btn--alt2 c-productCard4__footer__buyBtn"
                            aria-expanded="false"
                          >
                            <span>Buy</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
                              <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                            </svg>
                          </button>
                        </div>
                        <div className="c-productCard4__buyOptions" aria-hidden="true">
                          <div className="c-productCard4__buyOptions__panel">
                            <div className="c-productCard4__buyOptions__content">
                              <form className="c-productCard4__buyOptions__content__qtyCTA">
                                <label className="f-quantity__label" htmlFor="quant1">
                                  Quantity
                                </label>
                                <div className="f-quantity js-quantity" id="jsQuantity0">
                                  <div className="f-quantity__counter">
                                    <button
                                      className="f-quantity__counter__btn js-quantity__btn "
                                      aria-label="Decrease Quantity"
                                      tabIndex="-1"
                                    >
                                      <span
                                        className="f-quantity__counter__btn__inner"
                                        tabIndex="-1"
                                      >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 2">
                                          <title>Minus Icon</title>
                                          <path d="M0 0h16v2H0z" />
                                        </svg>
                                      </span>
                                    </button>
                                    <input
                                      className="f-quantity__counter__input"
                                      type="text"
                                      maxLength="4"
                                      id="quant1"
                                      name="fQuantity0"
                                      value="1"
                                    />
                                    <button
                                      className="f-quantity__counter__btn js-quantity__btn"
                                      aria-label="Increase Quantity"
                                      data-add="true"
                                    >
                                      <span
                                        className="f-quantity__counter__btn__inner"
                                        tabIndex="-1"
                                      >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                          <title>Plus Icon</title>
                                          <path d="M0 7h16v2H0z" />
                                          <path d="M7 0h2v16H7z" />
                                        </svg>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <button className="e-btn" title="Button Title">
                                  <svg xmlns="http://www.w3.org/2000/svg">
                                    <use href="/images/icons-map.svg#btn-cart" />
                                  </svg>
                                  <span>Add to Cart</span>
                                </button>
                              </form>
                              <h5 className="u-txt-center">Or Buy From</h5>
                              <div className="c-productCard4__buyOptions__content__partnersCTA">
                                <div className="c-productCard4__buyOptions__content__partnersCTA__partners">
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div
                        className="c-productCard4 c-productCard4--dram c-productCard4--listView"
                        id="productCard4-1"
                      >
                        <div className="e-tag c-productCard4__kit">
                          <span className="c-productCard4__kit__lg"> Kit of 4</span>
                          <span className="c-productCard4__kit__sm">x 4</span>
                        </div>
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">
                              2GB Module - DDR3 1600MHz
                            </span>
                            <span className="e-tag">Sale</span>
                          </a>
                        </div>
                        <span className="c-productCard4__partNumber">
                          Part Number:{" "}
                          <span className="c-productCard4__partNumber"> KTH-PL426/16G</span>
                        </span>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/kingston/product/DDR4_ECC_Registered_VLP_DIMM_1-tn.png"
                                alt="2GB Module - DDR3 1600MHz"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li></li>
                                <li></li>
                                <li className="c-productCard4__details__content__longDesc__partNumber">
                                  Part Number: KTH-PL426/16G
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">
                            CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus
                          </p>
                        </div>
                        <div className="c-productCard4__footer">
                          <button
                            className="e-btn e-btn--alt2 c-productCard4__footer__buyBtn"
                            aria-expanded="false"
                          >
                            <span>Buy</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
                              <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                            </svg>
                          </button>
                        </div>
                        <div className="c-productCard4__buyOptions" aria-hidden="true">
                          <div className="c-productCard4__buyOptions__panel">
                            <div className="c-productCard4__buyOptions__content">
                              <div className="c-productCard4__buyOptions__content__partnersCTA">
                                <h5 className="u-txt-center">Buy From</h5>
                                <div className="c-productCard4__buyOptions__content__partnersCTA__partners">
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div
                        className="c-productCard4 c-productCard4--dram c-productCard4--listView"
                        id="productCard4-2"
                      >
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">
                              2GB Module - DDR3 1600MHz
                            </span>
                            <span className="e-tag">Sale</span>
                          </a>
                        </div>
                        <span className="c-productCard4__partNumber">
                          Part Number:{" "}
                          <span className="c-productCard4__partNumber"> KTH-PL426/16G</span>
                        </span>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/kingston/product/DDR4_Non-ECC_Unbuffered_DIMM_S6_1-tn.png"
                                alt="2GB Module - DDR3 1600MHz"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li></li>
                                <li></li>
                                <li className="c-productCard4__details__content__longDesc__partNumber">
                                  Part Number: KTH-PL426/16G
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">
                            CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus
                          </p>
                        </div>
                        <div className="c-productCard4__footer">
                          <button
                            className="e-btn e-btn--alt2 c-productCard4__footer__buyBtn"
                            aria-expanded="false"
                          >
                            <span>Buy</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
                              <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                            </svg>
                          </button>
                        </div>
                        <div className="c-productCard4__buyOptions" aria-hidden="true">
                          <div className="c-productCard4__buyOptions__panel">
                            <div className="c-productCard4__buyOptions__content">
                              <div className="c-productCard4__buyOptions__content__whereToBuy">
                                <a
                                  className="e-btn e-btn--alt2"
                                  target="_self"
                                  title="Button Title"
                                  href="#link"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg">
                                    <use href="../images/icons-map.svg#aae" />
                                  </svg>
                                  <span>Ask an Expert</span>
                                </a>
                                <a
                                  className="e-btn"
                                  target="_self"
                                  title="Button Title"
                                  href="#link"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg">
                                    <use href="/images/icons-map.svg#location" />
                                  </svg>
                                  <span>Where to Buy</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div
                        className="c-productCard4 c-productCard4--dram c-productCard4--listView"
                        id="productCard4-3"
                      >
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">
                              4GB Module - DDR4 2400MHz
                            </span>
                            <span className="e-tag">Sale</span>
                          </a>
                        </div>
                        <span className="c-productCard4__partNumber">
                          Part Number:{" "}
                          <span className="c-productCard4__partNumber"> KTH-PL426/16G</span>
                        </span>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/images/products/DDR4_Non-ECC_Unbuffered_DIMM_S6_1_tn.png"
                                alt="4GB Module - DDR4 2400MHz"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li></li>
                                <li></li>
                                <li className="c-productCard4__details__content__longDesc__partNumber">
                                  Part Number: KTH-PL426/16G
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">
                            CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus
                          </p>
                        </div>
                        <div className="c-productCard4__footer">
                          <div className="c-productCard4__footer__price">
                            <div className="c-productCard4__footer__price__container">
                              <span className="c-productCard4__footer__price__container__amount">
                                $66.98
                              </span>
                              <span className="u-txt-strike c-productCard4__footer__price__container__strike">
                                $122.54
                              </span>
                            </div>
                          </div>
                          <button
                            className="e-btn e-btn--alt2 c-productCard4__footer__buyBtn"
                            aria-expanded="false"
                          >
                            <span>Buy</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
                              <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                            </svg>
                          </button>
                        </div>
                        <div className="c-productCard4__buyOptions" aria-hidden="true">
                          <div className="c-productCard4__buyOptions__panel">
                            <div className="c-productCard4__buyOptions__content">
                              <form className="c-productCard4__buyOptions__content__qtyCTA">
                                <label className="f-quantity__label" htmlFor="quant1">
                                  Quantity
                                </label>
                                <div className="f-quantity js-quantity" id="jsQuantity0">
                                  <div className="f-quantity__counter">
                                    <button
                                      className="f-quantity__counter__btn js-quantity__btn "
                                      aria-label="Decrease Quantity"
                                      tabIndex="-1"
                                    >
                                      <span
                                        className="f-quantity__counter__btn__inner"
                                        tabIndex="-1"
                                      >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 2">
                                          <title>Minus Icon</title>
                                          <path d="M0 0h16v2H0z" />
                                        </svg>
                                      </span>
                                    </button>
                                    <input
                                      className="f-quantity__counter__input"
                                      type="text"
                                      maxLength="4"
                                      id="quant1"
                                      name="fQuantity0"
                                      value="1"
                                    />
                                    <button
                                      className="f-quantity__counter__btn js-quantity__btn"
                                      aria-label="Increase Quantity"
                                      data-add="true"
                                    >
                                      <span
                                        className="f-quantity__counter__btn__inner"
                                        tabIndex="-1"
                                      >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                          <title>Plus Icon</title>
                                          <path d="M0 7h16v2H0z" />
                                          <path d="M7 0h2v16H7z" />
                                        </svg>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <button className="e-btn" title="Button Title">
                                  <svg xmlns="http://www.w3.org/2000/svg">
                                    <use href="/images/icons-map.svg#btn-cart" />
                                  </svg>
                                  <span>Add to Cart</span>
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div
                        className="c-productCard4 c-productCard4--dram c-productCard4--singleCTA c-productCard4--listView"
                        id="productCard4-4"
                      >
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">
                              4GB Module - DDR4 2666MHz
                            </span>
                            <span className="e-tag">Sale</span>
                          </a>
                        </div>
                        <span className="c-productCard4__partNumber">
                          Part Number:{" "}
                          <span className="c-productCard4__partNumber"> KTH-PL426/16G</span>
                        </span>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/kingston/product/DDR4_ECC_Load_Reduced_DIMM_4R_x4_1-tn.png"
                                alt="4GB Module - DDR4 2666MHz"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li></li>
                                <li></li>
                                <li className="c-productCard4__details__content__longDesc__partNumber">
                                  Part Number: KTH-PL426/16G
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">
                            CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus
                          </p>
                        </div>
                        <div className="c-productCard4__footer"></div>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div
                        className="c-productCard4 c-productCard4--dram c-productCard4--listView"
                        id="productCard4-5"
                      >
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">DRAM</span>
                            <span className="e-tag">Sale</span>
                          </a>
                        </div>
                        <span className="c-productCard4__partNumber">
                          Part Number:{" "}
                          <span className="c-productCard4__partNumber"> KTH-PL426/16G</span>
                        </span>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img src="/images/fpo/general/200x52.png" alt="DRAM" />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li></li>
                                <li></li>
                                <li className="c-productCard4__details__content__longDesc__partNumber">
                                  Part Number: KTH-PL426/16G
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">
                            CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus
                          </p>
                        </div>
                        <div className="c-productCard4__footer">
                          <button
                            className="e-btn e-btn--alt2 c-productCard4__footer__buyBtn"
                            aria-expanded="false"
                          >
                            <span>Buy</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
                              <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                            </svg>
                          </button>
                        </div>
                        <div className="c-productCard4__buyOptions" aria-hidden="true">
                          <div className="c-productCard4__buyOptions__panel">
                            <div className="c-productCard4__buyOptions__content">
                              <div className="c-productCard4__buyOptions__content__partnersCTA">
                                <h5 className="u-txt-center">Buy From</h5>
                                <div className="c-productCard4__buyOptions__content__partnersCTA__partners">
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                  <a href="#" aria-label="Insight" title="Insight">
                                    <img
                                      src="https://media.kingston.com/images/vend/insight_add-to-cart_HyperX.png"
                                      alt="Insight"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div
                        className="c-productCard4 c-productCard4--dram c-productCard4--listView"
                        id="productCard4-6"
                      >
                        <div className="e-tag c-productCard4__kit">
                          <span className="c-productCard4__kit__lg"> Kit of 4</span>
                          <span className="c-productCard4__kit__sm">x 4</span>
                        </div>
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">DRAM</span>
                            <span className="e-tag">Sale</span>
                          </a>
                        </div>
                        <span className="c-productCard4__partNumber">
                          Part Number:{" "}
                          <span className="c-productCard4__partNumber"> KTH-PL426/16G</span>
                        </span>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img src="/images/fpo/general/200x52.png" alt="DRAM" />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li></li>
                                <li></li>
                                <li className="c-productCard4__details__content__longDesc__partNumber">
                                  Part Number: KTH-PL426/16G
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">
                            CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus
                          </p>
                        </div>
                        <div className="c-productCard4__footer">
                          <button
                            className="e-btn e-btn--alt2 c-productCard4__footer__buyBtn"
                            aria-expanded="false"
                          >
                            <span>Buy</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
                              <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                            </svg>
                          </button>
                        </div>
                        <div className="c-productCard4__buyOptions" aria-hidden="true">
                          <div className="c-productCard4__buyOptions__panel">
                            <div className="c-productCard4__buyOptions__content">
                              <div className="c-productCard4__buyOptions__content__whereToBuy">
                                <a
                                  className="e-btn e-btn--alt2"
                                  target="_self"
                                  title="Button Title"
                                  href="#link"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg">
                                    <use href="../images/icons-map.svg#aae" />
                                  </svg>
                                  <span>Ask an Expert</span>
                                </a>
                                <a
                                  className="e-btn"
                                  target="_self"
                                  title="Button Title"
                                  href="#link"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg">
                                    <use href="/images/icons-map.svg#location" />
                                  </svg>
                                  <span>Where to Buy</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div
                        className="c-productCard4 c-productCard4--dram c-productCard4--listView"
                        id="productCard4-7"
                      >
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">DRAM</span>
                            <span className="e-tag">Sale</span>
                          </a>
                        </div>
                        <span className="c-productCard4__partNumber">
                          Part Number:{" "}
                          <span className="c-productCard4__partNumber"> KTH-PL426/16G</span>
                        </span>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img src="/images/fpo/general/200x52.png" alt="DRAM" />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li></li>
                                <li></li>
                                <li className="c-productCard4__details__content__longDesc__partNumber">
                                  Part Number: KTH-PL426/16G
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">
                            CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus
                          </p>
                        </div>
                        <div className="c-productCard4__footer">
                          <div className="c-productCard4__footer__price">
                            <div className="c-productCard4__footer__price__container">
                              <span className="c-productCard4__footer__price__container__amount">
                                $66.98
                              </span>
                              <span className="u-txt-strike c-productCard4__footer__price__container__strike">
                                $122.54
                              </span>
                            </div>
                          </div>
                          <button
                            className="e-btn e-btn--alt2 c-productCard4__footer__buyBtn"
                            aria-expanded="false"
                          >
                            <span>Buy</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
                              <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                            </svg>
                          </button>
                        </div>
                        <div className="c-productCard4__buyOptions" aria-hidden="true">
                          <div className="c-productCard4__buyOptions__panel">
                            <div className="c-productCard4__buyOptions__content">
                              <form className="c-productCard4__buyOptions__content__qtyCTA">
                                <label className="f-quantity__label" htmlFor="quant1">
                                  Quantity
                                </label>
                                <div className="f-quantity js-quantity" id="jsQuantity0">
                                  <div className="f-quantity__counter">
                                    <button
                                      className="f-quantity__counter__btn js-quantity__btn "
                                      aria-label="Decrease Quantity"
                                      tabIndex="-1"
                                    >
                                      <span
                                        className="f-quantity__counter__btn__inner"
                                        tabIndex="-1"
                                      >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 2">
                                          <title>Minus Icon</title>
                                          <path d="M0 0h16v2H0z" />
                                        </svg>
                                      </span>
                                    </button>
                                    <input
                                      className="f-quantity__counter__input"
                                      type="text"
                                      maxLength="4"
                                      id="quant1"
                                      name="fQuantity0"
                                      value="1"
                                    />
                                    <button
                                      className="f-quantity__counter__btn js-quantity__btn"
                                      aria-label="Increase Quantity"
                                      data-add="true"
                                    >
                                      <span
                                        className="f-quantity__counter__btn__inner"
                                        tabIndex="-1"
                                      >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                          <title>Plus Icon</title>
                                          <path d="M0 7h16v2H0z" />
                                          <path d="M7 0h2v16H7z" />
                                        </svg>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <button className="e-btn" title="Button Title">
                                  <svg xmlns="http://www.w3.org/2000/svg">
                                    <use href="/images/icons-map.svg#btn-cart" />
                                  </svg>
                                  <span>Add to Cart</span>
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div
                        className="c-productCard4 c-productCard4--dram c-productCard4--singleCTA c-productCard4--listView"
                        id="productCard4-8"
                      >
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">
                              DRAM: Product Name Link
                            </span>
                            <span className="e-tag">Sale</span>
                          </a>
                        </div>
                        <span className="c-productCard4__partNumber">
                          Part Number:{" "}
                          <span className="c-productCard4__partNumber"> KTH-PL426/16G</span>
                        </span>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="/images/fpo/general/200x52.png"
                                alt="DRAM: Product Name Link"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li></li>
                                <li></li>
                                <li className="c-productCard4__details__content__longDesc__partNumber">
                                  Part Number: KTH-PL426/16G
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">
                            CL19 1Rx4 8Gbit 1.2V 288-pin Hynix D Rambus
                          </p>
                        </div>
                        <div className="c-productCard4__footer">
                          <span>
                            Replaced By{" "}
                            <a href="#link" title="KTH-PL426/16G">
                              KTH-PL426/16G
                            </a>
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="s-productGallery3__filterLayout__view__loadMore">
                    <a className="e-btn" target="_self" title="Button Title" href="#loadmore">
                      <span>Load More</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="l-tabView__panels__panel "
              role="tabpanel"
              aria-labelledby="tab0_3"
              id="tabContent0_3"
            >
              <div className="s-productGallery3__filterLayout">
                <div className="s-productGallery3__filterLayout__controls">
                  <div className="s-productGallery3__filterLayout__controls__section">
                    <h5 className="u-txt-uppercase">Search</h5>
                    <div className="c-accordion c-accordion--noOutline">
                      <span className="c-accordion__tab false">
                        <button aria-expanded="false">
                          System/Device
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden="true"
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                          </svg>
                        </button>
                      </span>
                      <div className="c-accordion__panel">
                        <div className="c-accordion__panel__content">
                          <div className="c-searchCard">
                            <div className="c-searchCard__body">
                              <p>
                                Simply enter the make and model number or system part number of the
                                computer system or digital device to find the memory you need.
                              </p>
                              <form className="c-searchCard__body__cta">
                                <div className="f-input f-input--outlined">
                                  <div className="f-input__string">
                                    <input
                                      type="text"
                                      name="makeModel"
                                      id="makeModel"
                                      required=""
                                      pattern="[a-zA-Z0-9]+"
                                    />
                                    <label htmlFor="makeModel">Make/Model</label>
                                  </div>
                                </div>
                                <a className="e-btn" href="#link" aria-label="Search">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.2 16.7">
                                    <path d="M9.8 0C5.7 0 2.4 3.3 2.4 7.4s3.3 7.4 7.4 7.4 7.4-3.3 7.4-7.4S13.9 0 9.8 0zm0 12.8c-3 0-5.3-2.4-5.3-5.3 0-3 2.4-5.3 5.3-5.3s5.3 2.4 5.3 5.3-2.4 5.3-5.3 5.3z" />
                                    <path d="M4.5 10.5L.4 14.6c-.5.5-.5 1.2 0 1.7s1.2.5 1.7 0l4.1-4.1" />
                                  </svg>
                                </a>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="c-accordion__tab false">
                        <button aria-controls="accPanel-m2vhwm" aria-expanded="false">
                          Memory Part Number
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="c-accordion__tab__icon"
                            viewBox="0 0 14 8"
                            aria-hidden="true"
                          >
                            <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z" />
                          </svg>
                        </button>
                      </span>
                      <div className="c-accordion__panel">
                        <div className="c-accordion__panel__content">
                          <div className="c-searchCard">
                            <div className="c-searchCard__body">
                              <p>
                                Search by either the Kingston part number, distributor part number
                                or manufacturer equivalent part number.
                              </p>
                              <form className="c-searchCard__body__cta">
                                <div className="f-input f-input--outlined">
                                  <div className="f-input__string">
                                    <input
                                      type="text"
                                      name="partNumber"
                                      id="partNumber"
                                      required=""
                                      pattern="[a-zA-Z0-9]+"
                                    />
                                    <label htmlFor="partNumber">Part Number</label>
                                  </div>
                                </div>
                                <a className="e-btn" href="#link" aria-label="Search">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.2 16.7">
                                    <path d="M9.8 0C5.7 0 2.4 3.3 2.4 7.4s3.3 7.4 7.4 7.4 7.4-3.3 7.4-7.4S13.9 0 9.8 0zm0 12.8c-3 0-5.3-2.4-5.3-5.3 0-3 2.4-5.3 5.3-5.3s5.3 2.4 5.3 5.3-2.4 5.3-5.3 5.3z" />
                                    <path d="M4.5 10.5L.4 14.6c-.5.5-.5 1.2 0 1.7s1.2.5 1.7 0l4.1-4.1" />
                                  </svg>
                                </a>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="s-productGallery3__filterLayout__controls__section">
                    <h5 className="u-txt-uppercase">Browse</h5>
                    <ul>
                      <li>
                        <a href="#serverLink" title="Anchor Title">
                          Server Memory
                        </a>
                      </li>
                      <li>
                        <a href="#desktopLink" title="Anchor Title">
                          Desktop/Laptop Memory
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="c-slideMenu s-productGallery3__filterLayout__slides"
                  id="slideMenu0"
                >
                  <div
                    className="c-slideMenu__slider c-filterCard s-productGallery3__filterLayout__slides__filter"
                    data-filterid="filterCard3"
                    data-dropdown="true"
                    id="sldr-i7aaln"
                  >
                    <button
                      className="c-slideMenu__slider__toggle"
                      id="sldrTgle-rzr3qi"
                      aria-controls="sldrPnl-c3nzmz"
                      aria-expanded="false"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <use href="/images/icons-map.svg#filter" />
                      </svg>
                      <span className="u-h5 u-txt-uppercase c-filterCard__title">Filter</span>
                    </button>
                    <div className="c-filterCard__results">
                      <span data-results="0">Results</span>
                    </div>
                    <form className="c-slideMenu__slider__panel c-filterCard__details">
                      <div className="c-slideMenu__slider__panel__content c-filterCard__details__content">
                        <ul className="u-list-unstyled">
                          <li>
                            <div className="f-input">
                              <div className="f-input__checkbox">
                                <input type="checkbox" name="fb0" id="fb00" value="" />
                                <label htmlFor="fb00" aria-checked="false">
                                  Checkbox Label
                                </label>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="f-input">
                              <div className="f-input__checkbox">
                                <input type="checkbox" name="fb1" id="fb10" value="val1" />
                                <label htmlFor="fb10" aria-checked="false">
                                  Checkbox Label
                                </label>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="f-input">
                              <div className="f-input__checkbox">
                                <input type="checkbox" name="fb2" id="fb20" value="val2" />
                                <label htmlFor="fb20" aria-checked="false">
                                  Checkbox Label
                                </label>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="f-input">
                              <div className="f-input__checkbox">
                                <input type="checkbox" name="fb3" id="fb30" value="val3" />
                                <label htmlFor="fb30" aria-checked="false">
                                  Checkbox Label
                                </label>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <div className="c-filterCard__details__content__info">
                          <a href="#anchor" title="anchor">
                            Search HTS/ECCN
                          </a>
                        </div>
                      </div>
                      <div className="c-slideMenu__slider__panel__cta c-filterCard__details__cta">
                        <button className="e-btn e-btn--alt2 c-filterCard__details__cta__reset">
                          Reset
                        </button>
                        <button className="e-btn c-slideMenu__slider__panel__cta__closeBtn c-filterCard__details__cta__close">
                          Close
                        </button>
                        <button className="e-btn c-filterCard__details__cta__apply">Apply</button>
                      </div>
                    </form>
                  </div>
                  <div className="c-slideMenu__slider c-slideMenu__slider--right s-productGallery3__filterLayout__slides__sort false">
                    <button className="c-slideMenu__slider__toggle" aria-expanded="false">
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <use href="../images/icons-map.svg#sort" />
                      </svg>
                      <span className="u-h5 u-txt-uppercase">Sort</span>
                    </button>
                    <form className="c-slideMenu__slider__panel s-productGallery3__filterLayout__slides__sort__details">
                      <div className="c-slideMenu__slider__panel__content s-productGallery3__filterLayout__slides__sort__details__content">
                        <ul className="u-list-unstyled">
                          <li>
                            <div className="f-input">
                              <div className="f-input__radio">
                                <input
                                  type="radio"
                                  name="radioName"
                                  id="radioName0"
                                  value="Featured"
                                />
                                <label htmlFor="radioName0" aria-checked="false">
                                  Featured
                                </label>
                              </div>
                              <div className="f-input__radio">
                                <input
                                  type="radio"
                                  name="radioName"
                                  id="radioName1"
                                  value="Newest"
                                />
                                <label htmlFor="radioName1" aria-checked="false">
                                  Newest
                                </label>
                              </div>
                              <div className="f-input__radio">
                                <input
                                  type="radio"
                                  name="radioName"
                                  id="radioName2"
                                  value="Oldest"
                                />
                                <label htmlFor="radioName2" aria-checked="false">
                                  Oldest
                                </label>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="c-slideMenu__slider__panel__cta">
                        <button
                          className="e-btn c-slideMenu__slider__panel__cta__closeBtn"
                          type="button"
                        >
                          Close
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="s-productGallery3__filterLayout__view">
                  <div className="s-productGallery3__filterLayout__view__sort">
                    <div
                      className="c-filterCard__dropDown s-productGallery3__filterLayout__view__sort__display s-productGallery3__filterLayout__view__sort__filter u-hide"
                      data-target="filterCard0"
                      data-id="filterCard0"
                    >
                      <div className="s-productGallery3__filterLayout__view__sort__display">
                        <div
                          className="c-dropDown c-dropDown--absolute false"
                          data-type="selection"
                          id="dropDown0"
                        >
                          <div
                            className="c-dropDown__toggler"
                            aria-expanded="false"
                            aria-controls="dropDownPanel0"
                          >
                            <div className="c-dropDown__toggler__selection">
                              <span className="c-dropDown__toggler__selection__value u-txt-bold"></span>
                              <span className="c-dropDown__toggler__selection">
                                <span className="u-txt-bold c-dropDown__toggler__selection__single">
                                  Filter
                                </span>
                                <span className="u-txt-bold c-dropDown__toggler__selection__plural">
                                  Filters
                                </span>
                              </span>
                              Applied
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="8"
                              viewBox="0 0 14 8"
                            >
                              <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                            </svg>
                          </div>
                          <div className="c-dropDown__panel" id="dropDownPanel0">
                            <button className="c-dropDown__panel__clearBtn">Clear All</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="s-productGallery3__filterLayout__view__sort__display s-productGallery3__filterLayout__view__sort__layout">
                      <div
                        className="c-dropDown c-dropDown--absolute false"
                        data-type="selection"
                        id="dropDown1"
                        role="button"
                        tabIndex="-1"
                      >
                        <div
                          className="c-dropDown__toggler"
                          aria-expanded="false"
                          aria-controls="dropDownPanel1"
                        >
                          <div className="c-dropDown__toggler__selection">
                            <span className="c-dropDown__toggler__selection">
                              Sort By{" "}
                              <span className="c-dropDown__toggler__selection__value u-txt-bold">
                                Grid
                              </span>
                            </span>
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                          </svg>
                        </div>
                        <div className="c-dropDown__panel" id="dropDownPanel1">
                          <ul>
                            <li className="c-dropDown__panel__item false">Grid</li>
                            <li className="c-dropDown__panel__item">List</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="s-productGallery3__filterLayout__view__sort__display s-productGallery3__filterLayout__view__sort__options">
                      <div className="c-dropDown c-dropDown--absolute false" id="dropDown2">
                        <div
                          className="c-dropDown__toggler"
                          aria-expanded="false"
                          aria-controls="dropDownPanel2"
                          role="button"
                          tabIndex="-1"
                        >
                          <div className="c-dropDown__toggler__selection">
                            <span className="c-dropDown__toggler__selection">
                              Sort By{" "}
                              <span className="c-dropDown__toggler__selection__value u-txt-bold">
                                Featured
                              </span>
                            </span>
                          </div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                          </svg>
                        </div>
                        <div className="c-dropDown__panel" id="dropDownPanel2">
                          <ul>
                            <li className="c-dropDown__panel__item">Oldest</li>
                            <li className="c-dropDown__panel__item">Newest</li>
                            <li className="c-dropDown__panel__item c-dropDown__panel__item--active">
                              Featured
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul
                    className="l-gridFlex s-productGallery3__grid l-gridFlex--list"
                    data-min="288"
                    data-max="480"
                  >
                    <li className="s-productGallery3__grid__cell">
                      <div
                        className="c-productCard4 c-productCard4--product c-productCard4--dram c-productCard4--listView"
                        id="productCard4-0"
                      >
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">Product Name</span>
                          </a>
                        </div>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/kingston/product/DDR4_Non-ECC_Unbuffered_SODIMM_4GB_1-tn.png"
                                alt="Product Name"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">Short Description</p>
                        </div>
                        <a
                          className="c-productCard4__arrow"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                          </svg>
                        </a>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div
                        className="c-productCard4 c-productCard4--product c-productCard4--dram c-productCard4--listView"
                        id="productCard4-1"
                      >
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">
                              DDR4 3200MHz ECC Registered DIMM
                            </span>
                          </a>
                        </div>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/kingston/product/DDR4_ECC_Registered_VLP_DIMM_1-tn.png"
                                alt="DDR4 3200MHz ECC Registered DIMM"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li>8GB, 16GB, 32GB, 64GB</li>
                                <li>1.2V 288-pin CL22</li>
                                <li>Server Premier</li>
                                <li>Up to 180MB/s read, 70MB/s write</li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">Short Description</p>
                        </div>
                        <a
                          className="c-productCard4__arrow"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                          </svg>
                        </a>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div
                        className="c-productCard4 c-productCard4--product c-productCard4--dram c-productCard4--listView"
                        id="productCard4-2"
                      >
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">
                              2GB Module - DDR3 1600MHz
                            </span>
                          </a>
                        </div>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/kingston/product/DDR4_Non-ECC_Unbuffered_DIMM_S6_1-tn.png"
                                alt="2GB Module - DDR3 1600MHz"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li>4GB, 8GB, 16GB, 32GB</li>
                                <li>1.2V CL19 288-pin</li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">Short Description</p>
                        </div>
                        <a
                          className="c-productCard4__arrow"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                          </svg>
                        </a>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div
                        className="c-productCard4 c-productCard4--product c-productCard4--dram c-productCard4--listView"
                        id="productCard4-3"
                      >
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">
                              4GB Module - DDR4 2400MHz
                            </span>
                          </a>
                        </div>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/images/products/DDR4_Non-ECC_Unbuffered_DIMM_S6_1_tn.png"
                                alt="4GB Module - DDR4 2400MHz"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__longDesc">
                              <p>HyperX Cloud Stinger Core Gaming Headset</p>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">Short Description</p>
                        </div>
                        <a
                          className="c-productCard4__arrow"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                          </svg>
                        </a>
                      </div>
                    </li>
                    <li className="s-productGallery3__grid__cell">
                      <div
                        className="c-productCard4 c-productCard4--product c-productCard4--dram c-productCard4--listView"
                        id="productCard4-4"
                      >
                        <div className="c-productCard4__header">
                          <a
                            className="c-productCard4__header__link"
                            title="Product Card Title"
                            aria-label="Product Card Title"
                            role="region"
                          >
                            <span className="c-productCard4__header__link__name">
                              4GB Module - DDR4 2666MHz
                            </span>
                          </a>
                        </div>
                        <a
                          className="c-productCard4__image"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <div className="container">
                            <div className="drop-container">
                              <img
                                src="https://media.kingston.com/kingston/product/DDR4_ECC_Load_Reduced_DIMM_4R_x4_1-tn.png"
                                alt="4GB Module - DDR4 2666MHz"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="c-productCard4__details">
                          <div className="c-productCard4__details__content">
                            <div className="c-productCard4__details__content__longDesc">
                              <ul>
                                <li>64GB</li>
                                <li>1.2V 288-pin CL21</li>
                                <li>Server Premier</li>
                              </ul>
                            </div>
                          </div>
                          <p className="c-productCard4__details__shortDesc">Short Description</p>
                        </div>
                        <a
                          className="c-productCard4__arrow"
                          href="#productCardLink"
                          title="Product Card Title"
                          aria-label="aria"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                          >
                            <path d="M13.02.02a.062.062 0 0 0-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 0 0-.09 0L.02.977a.062.062 0 0 0 0 .09l6.937 6.915a.066.066 0 0 0 .09 0l.962-.96 5.973-5.954a.065.065 0 0 0 0-.09L13.02.02z" />
                          </svg>
                        </a>
                      </div>
                    </li>
                  </ul>
                  <div className="s-productGallery3__filterLayout__view__loadMore">
                    <a className="e-btn" target="_self" title="Button Title" href="#loadmore">
                      <span>Load More</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div style={{ height: 1000 + "px", backgroundColor: "orange" }}></div>
      <div style={{ height: 1000 + "px", backgroundColor: "orange" }}></div>
      <button
        id="demo-addProductBtn"
        style={{
          position: "fixed",
          backgroundColor: "#c8102e",
          border: "1px solid #c8102e",
          color: "white",
          bottom: "10px",
          right: "10px",
        }}
      >
        Add Product
      </button>
    </>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "mega-menu-navigation",
  component: S_MegaMenuNavigation,
  navtxt: "Mega Menu Navigation",
  htmlName: "megaMenuNavigation",
  categoryType: ["web"],
};
