/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "../../../AppContext";
import ToastContext from "../../../ToastContext";
import Icon from "./Icon";
// import Ids from "./icons.json";
//Ids are created by running writeiconlist npm script when running buildiconsearch. Used for search.
import buttonIds from "./buttons.json";
import careersIds from "./careers.json";
import controlsIds from "./controls.json";
import configIds from "./configurator.json";
import deprecatedIds from "./deprecated.json";
import featureIds from "./feature.json";
import miscIds from "./misc.json";
import navIds from "./nav.json";
import reservedIds from "./reserved.json";
import socialIds from "./social.json";
// import Icons from "../../../../static/static/images/icons-map.svg";
import Fuse from "fuse.js";
import "./c_search.scss";
import "./iconMap.section.scss";
import "./pg_icons.scss";
import searchIcon from "./search.svg";

//TODO:  Search is broken? multiple 3.5mm plugs on search "up", some items stay with empty search box. decide what to do with raw folder vs loose icons. Make search button work

const Pg_Icons = () => {
  const { appState, setAppState } = useContext(AppContext);
  const [currIcon, setCurrIcon] = useState(null);
  const { toastOptions, setToastOptions } = useContext(ToastContext);
  const [modalOpen, setModalOpen] = useState(false);
  // const [download, setDownload] = useState("");
  //search states
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [matchActive, setMatchActive] = useState(false);

  const searchRef = useRef();
  const inputRef = useRef();

  //combined list for search
  let Ids = [];

  const modalStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#f7f9fa",
      padding: ".25em",
      borderRadius: "0.5em",
      paddingBottom: "1.5em",
    },
  };

  const handleIconClick = (name) => {
    setSearchActive(true);
    setModalOpen(true);
    setCurrIcon(name);
    // console.log("name: ", name);
    // console.log("currIcon: ", currIcon);
  };

  const handleTagClick = (e, txt) => {
    e.preventDefault();
    // console.log("txt: ", txt);
    let tag = "#" + txt;
    navigator.clipboard.writeText(tag).then(
      function () {
        setToastOptions({ text: "Icon '" + currIcon + "' copied to clipboard!" });
      },
      function (err) {
        setToastOptions({ text: "There was a problem copying." });
        console.error("Async: Could not copy text: ", err);
      },
    );
  };

  const notify = () => {
    toast(toastOptions.text);
    // reset context to empty obj
    // setToastOptions(null);
  };

  //search functions

  const searchOn = () => {
    setSearchActive(true);
    setMatchActive(true);
  };
  const searchOff = () => {
    inputRef.current.focus();
    //to clear search field
    inputRef.current.value = "";
    setMatchActive(false);
    setSearchActive(false);
  };

  const doSearch = (txt) => {
    searchOn();
    setSearchText(txt);
    let options = {
      threshold: 0.3,
    };
    console.log("searchData inside doSearch: ", searchData);
    const fuse = new Fuse(searchData, options);

    const result = fuse.search(txt);
    let matches = [];
    console.log("result: ", result);
    if (!result.length) {
      setSearchData([]);
      setMatchActive(false);
    } else {
      result.forEach(({ item }) => {
        matches.push(item);
      });
      setSearchData(matches);
      setMatchActive(true);
    }
  };

  const handleKeydown = (e) => {
    console.log("key: ", e.key);
    e.key === "Escape" && searchOff();
    e.key === "Enter" && doSearch(e.target.value);
    e.key === "Backspace" && setSearchData(Ids);
  };

  const handleClick = (event) => {
    let parent = event.target.parentNode;
    console.log("grand parent: ", parent.parentNode.className);
    console.log("event.target: ", event.target);
    //handle click inside input
    if (searchRef.current.contains(event.target)) {
      setSearchActive(true);
    }
    //handle click outside
    if (
      searchRef.current &&
      !searchRef.current.contains(event.target) &&
      !modalOpen &&
      parent.parentNode.className !== "s-IconCard" && //this prevents search results counting as clicking outside the search window
      parent.parentNode.className !== "c-IconCard" &&
      parent.parentNode.className !== "" &&
      event.target.id !== "c-search__input__button"
    ) {
      console.log("got click outside");
      setSearchData(Ids);
      // toggleActive();
      setSearchActive(false);
      inputRef.current.value = "";
      setMatchActive(false);
    }
  };

  const handleChange = (eText) => {
    console.log("got change text: ", eText);
    if (!eText || eText === "") {
      console.log("search data should be reset");
      setSearchData(Ids);
      return;
    }
    doSearch(eText);
  };

  //load needed app data
  useEffect(() => {
    setAppState({
      ...appState,
      currentPath: "/icon-guide",
      outputName: "",
      headerName: "Icon Guide",
      thumb: "",
      viewHeight: null,
      description: null,
    });
    Ids.push(
      ...buttonIds,
      ...careersIds,
      ...controlsIds,
      ...configIds,
      ...deprecatedIds,
      ...featureIds,
      ...miscIds,
      ...navIds,
      ...reservedIds,
      ...socialIds,
    );
    // console.log("Ids: ", Ids);
    setSearchData(Ids);
  }, []);
  //toast
  useEffect(() => {
    if (toastOptions && toastOptions.text) {
      notify();
    }
  }, [toastOptions]);

  //search
  useEffect(() => {
    document.addEventListener("keydown", handleKeydown, true);
    document.addEventListener("click", handleClick, true);
    //must clear even listeners when done
    return () => {
      document.removeEventListener("keydown", handleKeydown, true);
      document.removeEventListener("click", handleClick, true);
    };
  }, []);

  return (
    <>
      {/* <S_Headline
        classMods={"s-headline--alignLeft"}
        headline={appState.headerName}
        copy={"Click on an icon to view and/or copy the hashtag."}
        breadcrumbs={[
          {
            url: "/",
            text: "Home",
          },
        ]}
        // cookieClick={resetCookie}
      /> */}
      <div className={"s-headline"}>
        <div className="l-inner">
          <div className="u-animated u-animated--slow a-fadeIn u-animated--easeOut u-flex u-flexColumn">
            <div className="u-flex u-flexRow u-spaceBetween">
              <p className="s-headline__breadcrumbs">
                <span>
                  <Link to={""}>Home</Link>
                  <span className="s-headline__breadcrumbs__slash">/</span>
                </span>
              </p>
            </div>

            <div className="s-headline__title">
              <h1 className="s-headline__title__copy">Icons Guide</h1>
            </div>
            <p
              className="s-headline__description"
              style={{ alignItems: "flex-start", display: "flex" }}
            >
              Click on an icon to view and/or copy the hashtag.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          minHeight: "3.25em",
          height: "auto",
          display: "flex",
          flexFlow: "wrap",
          padding: ".5em 0",
          justifyContent: "space-around",
          lineHeight: "2em",
          borderTop: "1px solid #777",
        }}
      >
        <Link to={"#buttons"}>Buttons</Link>
        <Link to={"#controls"}>Controls</Link>
        <Link to={"#social"}>Social Media</Link>
        <Link to={"#feature"}>Feature</Link>
        <Link to={"#configurator"}>Configurator</Link>
        <Link to={"#careers"}>Careers</Link>
        <Link to={"#misc"}>Miscellaneous</Link>
        <Link to={"#reserved"}>Reserved Use</Link>
        <Link to={"#deprecated"}>Deprecated</Link>
      </div>
      <div className={`s-iconMap`} id="Icon-Guide">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "1.875em",
            backgroundColor: "#252525",
            maxWidth: "100%",
            margin: "0 0 2em 0",
          }}
        >
          {/* Search */}
          <div className={`c-search ${searchActive && "c-search--active"}`} ref={searchRef}>
            <input
              className="c-search__input"
              type="text"
              onChange={(e) => handleChange(e.target.value)}
              placeholder="Search Icons"
              ref={inputRef}
              style={{ maxWidth: "20em", marginRight: "-3px" }}
              name="iconsearchfield"
            />
            <button
              className="c-search__icon"
              aria-label="Search"
              onClick={(e) => doSearch(searchText)}
              tabIndex={0}
              style={{ border: "1px solid white" }}
              id="c-search__input__button"
            >
              <img
                src={searchIcon}
                alt="icon search"
                style={{ color: "white", minWidth: "1.25em" }}
              />
            </button>
          </div>
        </div>
        {/* Search Results */}
        {matchActive ? (
          // This lovely fade-in causes the tiles to lay over modal
          // <div
          //   className={`c-search__panel u-animated u-animated--delayFast a-fadeIn`}
          //   style={{ display: "block", top: "25em", color: "white" }}
          // >
          <div className="s-iconMap__layout">
            <h3>Click icon to view and copy/download.</h3>
            <ul className="s-iconMap__layout__grid s-iconMap__layout__grid--results">
              {searchData.length > 0 &&
                searchData.map((id, index) => (
                  <Icon
                    key={id}
                    name={id}
                    num={index + 1}
                    currIcon={currIcon}
                    icons={"/images/icons-map.svg"}
                    click={handleIconClick}
                  />
                ))}
            </ul>
          </div>
        ) : (
          // </div>
          //  Default layout
          <div className="s-iconMap__layout">
            <h4 id="#buttons">Buttons</h4>
            <ul
              className="s-iconMap__layout__grid"
              style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
            >
              {buttonIds.map((id, index) => (
                <Icon
                  key={id}
                  name={id}
                  num={index + 1}
                  currIcon={currIcon}
                  icons={"/images/icons-map.svg"}
                  click={handleIconClick}
                />
              ))}
            </ul>
            <h4 id="#controls">Controls</h4>
            <ul
              className="s-iconMap__layout__grid"
              style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
            >
              {controlsIds.map((id, index) => (
                <Icon
                  key={id}
                  name={id}
                  num={index + 1}
                  currIcon={currIcon}
                  icons={"/images/icons-map.svg"}
                  click={handleIconClick}
                />
              ))}
            </ul>
            <h4 id="social">Social Media</h4>
            <ul
              className="s-iconMap__layout__grid"
              style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
            >
              {socialIds.map((id, index) => (
                <Icon
                  key={id}
                  name={id}
                  num={index + 1}
                  currIcon={currIcon}
                  icons={"/images/icons-map.svg"}
                  click={handleIconClick}
                />
              ))}
            </ul>
            <h4 id="feature">Feature</h4>
            <ul
              className="s-iconMap__layout__grid"
              style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
            >
              {featureIds.map((id, index) => (
                <Icon
                  key={id}
                  name={id}
                  num={index + 1}
                  currIcon={currIcon}
                  icons={"/images/icons-map.svg"}
                  click={handleIconClick}
                />
              ))}
            </ul>
            <h4 id="configurator">Configurator</h4>
            <ul
              className="s-iconMap__layout__grid"
              style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
            >
              {configIds.map((id, index) => (
                <Icon
                  key={id}
                  name={id}
                  num={index + 1}
                  currIcon={currIcon}
                  icons={"/images/icons-map.svg"}
                  click={handleIconClick}
                />
              ))}
            </ul>
            <h4 id="careers">Careers</h4>
            <ul
              className="s-iconMap__layout__grid"
              style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
            >
              {careersIds.map((id, index) => (
                <Icon
                  key={id}
                  name={id}
                  num={index + 1}
                  currIcon={currIcon}
                  icons={"/images/icons-map.svg"}
                  click={handleIconClick}
                />
              ))}
            </ul>

            <h4 id="misc">Miscellaneous</h4>
            <ul
              className="s-iconMap__layout__grid"
              style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
            >
              {miscIds.map((id, index) => (
                <Icon
                  key={id}
                  name={id}
                  num={index + 1}
                  currIcon={currIcon}
                  icons={"/images/icons-map.svg"}
                  click={handleIconClick}
                />
              ))}
            </ul>
            <h4 id="reserved">Reserved Use</h4>
            <ul
              className="s-iconMap__layout__grid"
              style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
            >
              {reservedIds.map((id, index) => (
                <Icon
                  key={id}
                  name={id}
                  num={index + 1}
                  currIcon={currIcon}
                  icons={"/images/icons-map.svg"}
                  click={handleIconClick}
                />
              ))}
            </ul>
            <h4 id="deprecated">Deprecated</h4>
            <ul
              className="s-iconMap__layout__grid"
              style={{ border: "2px solid #ccc", borderRadius: "10px", backgroundColor: "#1a1a1a" }}
            >
              {deprecatedIds.map((id, index) => (
                <Icon
                  key={id}
                  name={id}
                  num={index + 1}
                  currIcon={currIcon}
                  icons={"/images/icons-map.svg"}
                  click={handleIconClick}
                />
              ))}
            </ul>
          </div>
        )}

        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />
        <Modal
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          style={modalStyles}
          contentLabel={currIcon}
          shouldCloseOnOverlayClick={true}
          ariaHideApp={false}
        >
          <div className="u-flex u-flexRow u-justifyEnd">
            <button onClick={() => setModalOpen(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <use href={`/images/icons-map.svg#close-round`} />
              </svg>
            </button>
          </div>
          <div className="u-flex u-flexRow u-justifyCenter u-mb1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
              <use href={`/images/icons-map.svg#${currIcon}`} />
            </svg>
          </div>
          <div className="u-flex u-flexRow u-justifyCenter u-mt1 u-mb1">
            <button className="tagBtn" onClick={(e) => handleTagClick(e, currIcon)}>
              <span>{`#${currIcon}`}</span>
            </button>
          </div>
          {/* <div className="u-flex u-flexRow u-justifyCenter u-mb1">
          <a href={download} download={`${currIcon}`} target="_blank" rel="noreferrer">
            Download SVG
          </a>
        </div> */}
        </Modal>
      </div>
    </>
  );
};

export default {
  path: "icon-guide",
  component: Pg_Icons,
  navtxt: "Icon Guide",
};
