/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { useState, useMemo } from "react";
import "animate.css/animate.min.css";
import CookieConsent from "react-cookie-consent";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "./AppContext.jsx";
import DimensionsContext from "./DimensionsContext.jsx";
import OptionsContext from "./OptionsContext.jsx";
import IframeWrapper from "./Styleguide/2_components/IframeWrapper/IframeWrapper.jsx";
import ToastContext from "./ToastContext.jsx";
import modules from "./workers/index.cjs";
// import Pg_Home from "./Styleguide/4_pages/Pg_Home/index.jsx";
import "./Styleguide/viewer.scss";
// Viewer is the only css/scss that is pulled directly into the app.
import C_aside from "./Styleguide/2_components/aside/C_aside";
import S_Footer from "./Styleguide/3_sections/footer/S_Footer";
import S_Masthead from "./Styleguide/3_sections/masthead/S_Masthead.jsx";

const App = () => {
  const location = useLocation();

  const AppProvider = ({ children }) => {
    const initialState = {
      currentPath: "/",
      navVisible: false,
      outputName: null,
      headerName: null,
      tags: null,
      description: "Welcome to Kingston's Interactive Guidelines...",
      related: null,
      htmlSaved: false,
      scripts: [],
      currentTheme: "t-pearl",
      specs: null,
      cis: null,
    };

    const [appState, setAppState] = useState(initialState);
    // console.log("initial appstate");
    const value = { appState, setAppState };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
  };

  const DimensionsProvider = ({ children }) => {
    const initialState = {
      width: 1152,
      hardcodeHeight: false,
      viewHeight: null,
    };
    const [dimensions, setDimensions] = useState(initialState);

    const value = { dimensions, setDimensions };

    return <DimensionsContext.Provider value={value}>{children}</DimensionsContext.Provider>;
  };

  const OptionsProvider = ({ children }) => {
    const [contentOptions, setContentOptions] = useState([]);

    // export const OptionsProvider = ({ children }: { children: ReactElement }) => {
    //   const [contentOptions, setContentOptions] = useState<{ [key: string]: any }>(initialState);
    // console.log("initial appstate");
    const value = { contentOptions, setContentOptions };

    return <OptionsContext.Provider value={value}>{children}</OptionsContext.Provider>;
  };

  //some Toast Context settings. Actual creation function is in buttons or icon guide where it's used
  const [toastOptions, setToastOptions] = useState(null);

  const toastValue = useMemo(
    () => ({
      toastOptions,
      setToastOptions,
    }),
    [toastOptions],
  );

  const notify = () => toast(toastValue);

  const NoMatch = () => {
    return (
      <div className="s-content">
        <h3>
          Sorry, we couldn&apos;t find a page for <code>{location.pathname}</code>
        </h3>
      </div>
    );
  };

  return (
    <AppProvider>
      <CookieConsent expires={365} acceptOnScroll={true}>
        <span>This website uses strictly necessary cookies to enhance the user experience.</span>
      </CookieConsent>
      <ToastContext.Provider value={toastValue}>
        <S_Masthead />
        <C_aside />
        <OptionsProvider>
          <DimensionsProvider>
            <IframeWrapper>
              {/* <Routes_Test /> */}
              <Routes>
                {modules &&
                  modules.map((module) => (
                    <Route path={module.path} element={<module.component />} key={module.navtxt} />
                  ))}
                <Route
                  path="/brand/elements"
                  element={<Navigate replace={true} to="/brand/elements/logo" />}
                  key={"logo"}
                />
                <Route
                  path="/brand/fury"
                  element={<Navigate replace={true} to="/brand/fury/logo" />}
                  key={"fury"}
                />
                <Route
                  path="/brand/photography"
                  element={<Navigate replace={true} to="/brand/photography/overview" />}
                  key={"photography"}
                />
                <Route
                  path="/brand/product-category-logos"
                  element={<Navigate replace={true} to="/brand/product-category-logos/iron-key" />}
                  key={"iron-key"}
                />
                <Route
                  path="/brand/illustrations-and-backgrounds"
                  element={
                    <Navigate replace={true} to="/brand/illustrations-and-backgrounds/vision" />
                  }
                  key={"bg"}
                />
                <Route
                  path="/brand/video"
                  element={<Navigate replace={true} to="/brand/video/name1" />}
                  key={"video"}
                />
                <Route
                  path="/brand/kiwy"
                  element={<Navigate replace={true} to="/brand/kiwy/vision" />}
                  key={"kiwy"}
                />
                <Route path="*" element={NoMatch()} />
              </Routes>
            </IframeWrapper>
          </DimensionsProvider>
        </OptionsProvider>
        <S_Footer />
      </ToastContext.Provider>
    </AppProvider>
  );
};

export default App;
