/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import E_ArrowCTA from "../../1_elements/arrowCTA/E_ArrowCTA";
import featureOpts from "./featureOpts";

const S_featureBox = () => {
  // These are needed for all sections
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    // currentPath is so user still gets correct/no iframe wrapper if they use browser navigation.
    // viewHeight is used to autoadjust the height in DimensionsContext
    // viewWidth is set and changed outside the web components, but preserved by DimensionsContext
    // outputName is used by IframeWrapper to preload html if it's done.
    // htmlSaved is used to determine if there is html available to load; can't read local file directories from browser
    // Specs are what's loaded in the specs tab
    // contentOptions are the fields that are editable in that tab
    let fbVars = {
      ...appState,
      currentPath: "/ui/feature-box",
      outputName: "featureBox",
      headerName: "Feature Box",
      tags: ["Landing Page Approved"],
      description:
        "The Feature Box section displays a feature of a product with the name of the feature, an image that’s a badge or icon and a text description below.",
      related: null,
      htmlSaved: true,
      currentTheme: "t-white",
      scripts: [],
      specs: [
        [
          "Images (mobile, tablet, desktop)",
          ["*1 image required", "Small, Medium, and Large: Any square ratio (1:1)"],
        ],
        ["Image Type", ["SVG"]],
        ["Title", ["Defaults to an <h5> string text. Recommend: Max 2 lines"]],
        ["Copy", ["Default - Recommended Character Limit: 150"]],
        [
          "",
          [
            "Long Description - Recommended Character Limit: 300",
            "Note: Translatioins should not exceed 750 characters.",
          ],
        ],
        ["CTA", ["String text within <span>"]],
      ],
      cis: {
        cmsDoc:
          "https://docs.google.com/document/d/1UcaLkdro7Qf89zGfo_AZzBYeNZ0z9FcjPSpfAKhFSFs/edit?usp=sharing",
        comments: null,
      },
    };
    setAppState(fbVars);
    // checkbox null makes the field always appear - making that content non-optional. No field, but with a checkbox makes the content optional but not editable. An array in the field value makes it a dropdown.
    setContentOptions(featureOpts);
    setDimensions({ ...dimensions, hardcodeHeight: false, viewHeight: "168" });
  }, []);

  if (!contentOptions) return "...Loading Feature Boxes";
  return (
    <section
      className={
        `s-featureBox ${
          contentOptions.featureType && contentOptions.featureType.selected == "Long Description"
            ? "s-featureBox--longDesc"
            : ""
        } ${
          contentOptions.modSmallIcons && contentOptions.modSmallIcons.checkbox
            ? "s-featureBox--sm"
            : ""
        } ${
          contentOptions.featureType &&
          contentOptions.featureType.selected == "Long Description" &&
          contentOptions.numFeatures &&
          contentOptions.numFeatures.selected == "1"
            ? "s-featureBox--1FeatureItem"
            : contentOptions.featureType &&
                contentOptions.featureType.selected == "Long Description" &&
                contentOptions.numFeatures &&
                contentOptions.numFeatures.selected == "2"
              ? "s-featureBox--2FeatureItem"
              : ""
        } ` + appState.currentTheme
      }
    >
      <ul className="s-featureBox__list l-inner u-list-unstyled">
        <li className="s-featureBox__list__item">
          <div className="s-featureBox__list__item__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <title>Insert Title/Alt Here</title>
              <path d="M21.738 17.34c.124 0 .228.092.255.215l.007.064v1.915C22 22.002 17.523 24 12 24c-5.416 0-9.827-1.922-9.995-4.324L2 19.534V17.62c0-.064.021-.128.06-.178a.253.253 0 0 1 .368-.04c2.039 1.762 5.887 2.692 9.572 2.692 3.685 0 7.532-.93 9.57-2.691a.253.253 0 0 1 .168-.062Zm-19.31-4.96c2.04 1.76 5.887 2.69 9.572 2.69 3.684 0 7.532-.93 9.57-2.69a.254.254 0 0 1 .167-.064c.125 0 .228.093.256.216l.006.064v1.916c0 2.467-4.477 4.464-9.999 4.464-5.416 0-9.828-1.92-9.995-4.323L2 14.512v-1.916c-.001-.065.02-.128.06-.178a.252.252 0 0 1 .369-.039Zm0-5.025C4.467 9.117 8.315 10.046 12 10.046c3.685 0 7.53-.928 9.57-2.689a.25.25 0 0 1 .167-.062c.124 0 .228.091.256.214l.007.064v1.916c0 2.466-4.477 4.464-10 4.464-5.416 0-9.827-1.922-9.995-4.323L2 9.49V7.572c0-.065.021-.128.06-.178a.251.251 0 0 1 .368-.039ZM12 0c5.523 0 10 2 10 4.466 0 2.465-4.477 4.463-10 4.463S2 6.931 2 4.466C2 1.999 6.477 0 12 0Z" />
            </svg>
          </div>
          {contentOptions.optNoTitles && contentOptions.optNoTitles.checkbox ? (
            contentOptions.titletype && contentOptions.titletype.selected == "H2" ? (
              <h2 className="s-featureBox__list__item__title u-h5">
                {contentOptions.feature1[0] &&
                  contentOptions.feature1[0].field[contentOptions.currentLang]}
              </h2>
            ) : contentOptions.titletype && contentOptions.titletype.selected == "H3" ? (
              <h3 className="s-featureBox__list__item__title u-h5">
                {contentOptions.feature1[0] &&
                  contentOptions.feature1[0].field[contentOptions.currentLang]}
              </h3>
            ) : contentOptions.titletype && contentOptions.titletype.selected == "H4" ? (
              <h4 className="s-featureBox__list__item__title u-h5">
                {contentOptions.feature1[0] &&
                  contentOptions.feature1[0].field[contentOptions.currentLang]}
              </h4>
            ) : contentOptions.titletype && contentOptions.titletype.selected == "H5" ? (
              <h5 className="s-featureBox__list__item__title u-h5">
                {contentOptions.feature1[0] &&
                  contentOptions.feature1[0].field[contentOptions.currentLang]}
              </h5>
            ) : contentOptions.titletype && contentOptions.titletype.selected == "H6" ? (
              <h6 className="s-featureBox__list__item__title u-h5">
                {contentOptions.feature1[0] &&
                  contentOptions.feature1[0].field[contentOptions.currentLang]}
              </h6>
            ) : (
              <div className="s-featureBox__list__item__title">
                {contentOptions.feature1[0] &&
                  contentOptions.feature1[0].field[contentOptions.currentLang]}
              </div>
            )
          ) : (
            ""
          )}
          {contentOptions.optNoCopy && contentOptions.optNoCopy.checkbox ? (
            <p className="s-featureBox__list__item__copy">
              {contentOptions.feature1[1] &&
                contentOptions.feature1[1].field[contentOptions.currentLang]}
            </p>
          ) : (
            ""
          )}
          {contentOptions.optNoCTA && contentOptions.optNoCTA.checkbox ? (
            <div className="s-featureBox__list__item__cta">
              <E_ArrowCTA
                copy={
                  contentOptions.feature1[2] &&
                  contentOptions.feature1[2].field[contentOptions.currentLang]
                }
              />
            </div>
          ) : (
            ""
          )}
        </li>
        {(contentOptions.numFeatures && contentOptions.numFeatures.selected == "2") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "3") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "4") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "5") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "6") ? (
          <li className="s-featureBox__list__item">
            <div className="s-featureBox__list__item__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <title>Insert Title/Alt Here</title>
                <path d="M4.523 0v1.663h1.39V0h1.275v1.663h1.393V0h1.506v1.663h1.392V0h1.275v1.663h1.39V0h1.276v1.663h1.392V0h1.275v1.663h1.391V0h1.277v1.663h1.622V3.09H24v1.426h-1.623V5.94H24v1.307h-1.623v1.425H24V9.98h-1.623v1.426H24v1.426h-1.623v1.425H24v1.307h-1.623v1.426H24v1.307h-1.623v1.426H24v1.188h-1.855v1.544h-1.623V24h-1.276v-1.545h-1.39V24H16.58v-1.545h-1.392V24h-1.275v-1.545h-1.391V24h-1.276v-1.545h-1.39V24H8.58v-1.545H7.188V24H5.913v-1.545h-1.39V24H3.246v-1.545H1.624v-1.544H0v-1.307h1.624v-1.426H0v-1.307h1.624v-1.425H0v-1.307h1.624v-1.426H0v-1.307h1.624V9.98H0V8.673h1.624V7.248H0V5.94h1.624V4.515H0V3.208h1.624V1.663h1.622V0h1.277Zm15.883 3.446H3.363V20.91h17.043V3.446ZM19.13 4.633c.231 0 .463.238.463.476v14.376c0 .238-.232.475-.463.475H5.1c-.231 0-.463-.237-.463-.475V5.109c0-.238.116-.476.464-.476Z" />
              </svg>
            </div>
            {contentOptions.optNoTitles && contentOptions.optNoTitles.checkbox ? (
              contentOptions.titletype && contentOptions.titletype.selected == "H2" ? (
                <h2 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature2[0] &&
                    contentOptions.feature2[0].field[contentOptions.currentLang]}
                </h2>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H3" ? (
                <h3 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature2[0] &&
                    contentOptions.feature2[0].field[contentOptions.currentLang]}
                </h3>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H4" ? (
                <h4 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature2[0] &&
                    contentOptions.feature2[0].field[contentOptions.currentLang]}
                </h4>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H5" ? (
                <h5 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature2[0] &&
                    contentOptions.feature2[0].field[contentOptions.currentLang]}
                </h5>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H6" ? (
                <h6 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature2[0] &&
                    contentOptions.feature2[0].field[contentOptions.currentLang]}
                </h6>
              ) : (
                <div className="s-featureBox__list__item__title">
                  {contentOptions.feature2[0] &&
                    contentOptions.feature2[0].field[contentOptions.currentLang]}
                </div>
              )
            ) : (
              ""
            )}
            {contentOptions.optNoCopy && contentOptions.optNoCopy.checkbox ? (
              <p className="s-featureBox__list__item__copy">
                {contentOptions.feature2[1] &&
                  contentOptions.feature2[1].field[contentOptions.currentLang]}
              </p>
            ) : (
              ""
            )}
            {contentOptions.optNoCTA && contentOptions.optNoCTA.checkbox ? (
              <div className="s-featureBox__list__item__cta">
                <E_ArrowCTA
                  copy={
                    contentOptions.feature2[2] &&
                    contentOptions.feature2[2].field[contentOptions.currentLang]
                  }
                />
              </div>
            ) : (
              ""
            )}
          </li>
        ) : (
          ""
        )}
        {(contentOptions.numFeatures && contentOptions.numFeatures.selected == "3") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "4") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "5") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "6") ? (
          <li className="s-featureBox__list__item">
            <div className="s-featureBox__list__item__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <title>Insert Title/Alt Here</title>
                <path d="M9.603 0c.525 0 2.836 2.252 6.092 2.436 2.624.148 3.169-.092 3.282-.19l.017-.019a.065.065 0 0 0 .01-.017l.001-.003c.012.112.138 1.417.183 3.233l.005.24 2.738-1.818L24 6.805l-4.935 3.27-.405.268.405-.267v.006l-2.798 1.858.001-.011h.001l-7.324 4.855L4.769 9.6l3.17-1.74 2.26 3.796 6.319-4.2 2.311-1.535-2.311 1.535a34.395 34.395 0 0 0-.135-2.51l-.001-.007c-.015.037-.186.27-2.15.177l-.236-.012c-2.348-.132-4.015-1.757-4.393-1.757-.38 0-2.046 1.625-4.393 1.757-2.35.133-2.387-.166-2.387-.166-.026.268-.685 7.054 1.06 10.079 1.698 2.94 5.358 5.396 5.695 5.618l.025.017s3.939-2.552 5.719-5.635c.046-.08.083-.171.125-.255l3.31-2.315c-.255 1.413-.64 2.728-1.223 3.737C15.066 20.46 9.603 24 9.603 24S4.14 20.46 1.67 16.184C-.797 11.908.2 2.206.2 2.206s.053.414 3.31.23C6.767 2.252 9.078 0 9.604 0Z" />
              </svg>
            </div>
            {contentOptions.optNoTitles && contentOptions.optNoTitles.checkbox ? (
              contentOptions.titletype && contentOptions.titletype.selected == "H2" ? (
                <h2 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature3[0] &&
                    contentOptions.feature3[0].field[contentOptions.currentLang]}
                </h2>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H3" ? (
                <h3 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature3[0] &&
                    contentOptions.feature3[0].field[contentOptions.currentLang]}
                </h3>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H4" ? (
                <h4 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature3[0] &&
                    contentOptions.feature3[0].field[contentOptions.currentLang]}
                </h4>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H5" ? (
                <h5 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature3[0] &&
                    contentOptions.feature3[0].field[contentOptions.currentLang]}
                </h5>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H6" ? (
                <h6 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature3[0] &&
                    contentOptions.feature3[0].field[contentOptions.currentLang]}
                </h6>
              ) : (
                <div className="s-featureBox__list__item__title">
                  {contentOptions.feature3[0] &&
                    contentOptions.feature3[0].field[contentOptions.currentLang]}
                </div>
              )
            ) : (
              ""
            )}
            {contentOptions.optNoCopy && contentOptions.optNoCopy.checkbox ? (
              <p className="s-featureBox__list__item__copy">
                {contentOptions.feature3[1] &&
                  contentOptions.feature3[1].field[contentOptions.currentLang]}
              </p>
            ) : (
              ""
            )}
            {contentOptions.optNoCTA && contentOptions.optNoCTA.checkbox ? (
              <div className="s-featureBox__list__item__cta">
                <E_ArrowCTA
                  copy={
                    contentOptions.feature3[2] &&
                    contentOptions.feature3[2].field[contentOptions.currentLang]
                  }
                />
              </div>
            ) : (
              ""
            )}
          </li>
        ) : (
          ""
        )}
        {(contentOptions.numFeatures && contentOptions.numFeatures.selected == "4") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "5") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "6") ? (
          <li className="s-featureBox__list__item">
            <div className="s-featureBox__list__item__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <title>Insert Title/Alt Here</title>
                <path d="M9.603 0c.525 0 2.836 2.252 6.092 2.436 2.624.148 3.169-.092 3.282-.19l.017-.019a.065.065 0 0 0 .01-.017l.001-.003c.012.112.138 1.417.183 3.233l.005.24 2.738-1.818L24 6.805l-4.935 3.27-.405.268.405-.267v.006l-2.798 1.858.001-.011h.001l-7.324 4.855L4.769 9.6l3.17-1.74 2.26 3.796 6.319-4.2 2.311-1.535-2.311 1.535a34.395 34.395 0 0 0-.135-2.51l-.001-.007c-.015.037-.186.27-2.15.177l-.236-.012c-2.348-.132-4.015-1.757-4.393-1.757-.38 0-2.046 1.625-4.393 1.757-2.35.133-2.387-.166-2.387-.166-.026.268-.685 7.054 1.06 10.079 1.698 2.94 5.358 5.396 5.695 5.618l.025.017s3.939-2.552 5.719-5.635c.046-.08.083-.171.125-.255l3.31-2.315c-.255 1.413-.64 2.728-1.223 3.737C15.066 20.46 9.603 24 9.603 24S4.14 20.46 1.67 16.184C-.797 11.908.2 2.206.2 2.206s.053.414 3.31.23C6.767 2.252 9.078 0 9.604 0Z" />
              </svg>
            </div>
            {contentOptions.optNoTitles && contentOptions.optNoTitles.checkbox ? (
              contentOptions.titletype && contentOptions.titletype.selected == "H2" ? (
                <h2 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature4[0] &&
                    contentOptions.feature4[0].field[contentOptions.currentLang]}
                </h2>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H3" ? (
                <h3 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature4[0] &&
                    contentOptions.feature4[0].field[contentOptions.currentLang]}
                </h3>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H4" ? (
                <h4 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature4[0] &&
                    contentOptions.feature4[0].field[contentOptions.currentLang]}
                </h4>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H5" ? (
                <h5 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature4[0] &&
                    contentOptions.feature4[0].field[contentOptions.currentLang]}
                </h5>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H6" ? (
                <h6 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature4[0] &&
                    contentOptions.feature4[0].field[contentOptions.currentLang]}
                </h6>
              ) : (
                <div className="s-featureBox__list__item__title">
                  {contentOptions.feature4[0] &&
                    contentOptions.feature4[0].field[contentOptions.currentLang]}
                </div>
              )
            ) : (
              ""
            )}
            {contentOptions.optNoCopy && contentOptions.optNoCopy.checkbox ? (
              <p className="s-featureBox__list__item__copy">
                {contentOptions.feature4[1] &&
                  contentOptions.feature4[1].field[contentOptions.currentLang]}
              </p>
            ) : (
              ""
            )}
            {contentOptions.optNoCTA && contentOptions.optNoCTA.checkbox ? (
              <div className="s-featureBox__list__item__cta">
                <E_ArrowCTA
                  copy={
                    contentOptions.feature4[2] &&
                    contentOptions.feature4[2].field[contentOptions.currentLang]
                  }
                />
              </div>
            ) : (
              ""
            )}
          </li>
        ) : (
          ""
        )}
        {(contentOptions.numFeatures && contentOptions.numFeatures.selected == "5") ||
        (contentOptions.numFeatures && contentOptions.numFeatures.selected == "6") ? (
          <li className="s-featureBox__list__item">
            <div className="s-featureBox__list__item__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <title>Insert Title/Alt Here</title>
                <path d="M21.738 17.34c.124 0 .228.092.255.215l.007.064v1.915C22 22.002 17.523 24 12 24c-5.416 0-9.827-1.922-9.995-4.324L2 19.534V17.62c0-.064.021-.128.06-.178a.253.253 0 0 1 .368-.04c2.039 1.762 5.887 2.692 9.572 2.692 3.685 0 7.532-.93 9.57-2.691a.253.253 0 0 1 .168-.062Zm-19.31-4.96c2.04 1.76 5.887 2.69 9.572 2.69 3.684 0 7.532-.93 9.57-2.69a.254.254 0 0 1 .167-.064c.125 0 .228.093.256.216l.006.064v1.916c0 2.467-4.477 4.464-9.999 4.464-5.416 0-9.828-1.92-9.995-4.323L2 14.512v-1.916c-.001-.065.02-.128.06-.178a.252.252 0 0 1 .369-.039Zm0-5.025C4.467 9.117 8.315 10.046 12 10.046c3.685 0 7.53-.928 9.57-2.689a.25.25 0 0 1 .167-.062c.124 0 .228.091.256.214l.007.064v1.916c0 2.466-4.477 4.464-10 4.464-5.416 0-9.827-1.922-9.995-4.323L2 9.49V7.572c0-.065.021-.128.06-.178a.251.251 0 0 1 .368-.039ZM12 0c5.523 0 10 2 10 4.466 0 2.465-4.477 4.463-10 4.463S2 6.931 2 4.466C2 1.999 6.477 0 12 0Z" />
              </svg>
            </div>
            {contentOptions.optNoTitles && contentOptions.optNoTitles.checkbox ? (
              contentOptions.titletype && contentOptions.titletype.selected == "H2" ? (
                <h2 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature5[0] &&
                    contentOptions.feature5[0].field[contentOptions.currentLang]}
                </h2>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H3" ? (
                <h3 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature5[0] &&
                    contentOptions.feature5[0].field[contentOptions.currentLang]}
                </h3>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H4" ? (
                <h4 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature5[0] &&
                    contentOptions.feature5[0].field[contentOptions.currentLang]}
                </h4>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H5" ? (
                <h5 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature5[0] &&
                    contentOptions.feature5[0].field[contentOptions.currentLang]}
                </h5>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H6" ? (
                <h6 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature5[0] &&
                    contentOptions.feature5[0].field[contentOptions.currentLang]}
                </h6>
              ) : (
                <div className="s-featureBox__list__item__title">
                  {contentOptions.feature5[0] &&
                    contentOptions.feature5[0].field[contentOptions.currentLang]}
                </div>
              )
            ) : (
              ""
            )}
            {contentOptions.optNoCopy && contentOptions.optNoCopy.checkbox ? (
              <p className="s-featureBox__list__item__copy">
                {contentOptions.feature5[1] &&
                  contentOptions.feature5[1].field[contentOptions.currentLang]}
              </p>
            ) : (
              ""
            )}
            {contentOptions.optNoCTA && contentOptions.optNoCTA.checkbox ? (
              <div className="s-featureBox__list__item__cta">
                <E_ArrowCTA
                  copy={
                    contentOptions.feature5[2] &&
                    contentOptions.feature5[2].field[contentOptions.currentLang]
                  }
                />
              </div>
            ) : (
              ""
            )}
          </li>
        ) : (
          ""
        )}
        {contentOptions.numFeatures && contentOptions.numFeatures.selected == "6" ? (
          <li className="s-featureBox__list__item">
            <div className="s-featureBox__list__item__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <title>Insert Title/Alt Here</title>
                <path d="M4.523 0v1.663h1.39V0h1.275v1.663h1.393V0h1.506v1.663h1.392V0h1.275v1.663h1.39V0h1.276v1.663h1.392V0h1.275v1.663h1.391V0h1.277v1.663h1.622V3.09H24v1.426h-1.623V5.94H24v1.307h-1.623v1.425H24V9.98h-1.623v1.426H24v1.426h-1.623v1.425H24v1.307h-1.623v1.426H24v1.307h-1.623v1.426H24v1.188h-1.855v1.544h-1.623V24h-1.276v-1.545h-1.39V24H16.58v-1.545h-1.392V24h-1.275v-1.545h-1.391V24h-1.276v-1.545h-1.39V24H8.58v-1.545H7.188V24H5.913v-1.545h-1.39V24H3.246v-1.545H1.624v-1.544H0v-1.307h1.624v-1.426H0v-1.307h1.624v-1.425H0v-1.307h1.624v-1.426H0v-1.307h1.624V9.98H0V8.673h1.624V7.248H0V5.94h1.624V4.515H0V3.208h1.624V1.663h1.622V0h1.277Zm15.883 3.446H3.363V20.91h17.043V3.446ZM19.13 4.633c.231 0 .463.238.463.476v14.376c0 .238-.232.475-.463.475H5.1c-.231 0-.463-.237-.463-.475V5.109c0-.238.116-.476.464-.476Z" />
              </svg>
            </div>
            {contentOptions.optNoTitles && contentOptions.optNoTitles.checkbox ? (
              contentOptions.titletype && contentOptions.titletype.selected == "H2" ? (
                <h2 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature6[0] &&
                    contentOptions.feature6[0].field[contentOptions.currentLang]}
                </h2>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H3" ? (
                <h3 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature6[0] &&
                    contentOptions.feature6[0].field[contentOptions.currentLang]}
                </h3>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H4" ? (
                <h4 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature6[0] &&
                    contentOptions.feature6[0].field[contentOptions.currentLang]}
                </h4>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H5" ? (
                <h5 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature6[0] &&
                    contentOptions.feature6[0].field[contentOptions.currentLang]}
                </h5>
              ) : contentOptions.titletype && contentOptions.titletype.selected == "H6" ? (
                <h6 className="s-featureBox__list__item__title u-h5">
                  {contentOptions.feature6[0] &&
                    contentOptions.feature6[0].field[contentOptions.currentLang]}
                </h6>
              ) : (
                <div className="s-featureBox__list__item__title">
                  {contentOptions.feature6[0] &&
                    contentOptions.feature6[0].field[contentOptions.currentLang]}
                </div>
              )
            ) : (
              ""
            )}
            {contentOptions.optNoCopy && contentOptions.optNoCopy.checkbox ? (
              <p className="s-featureBox__list__item__copy">
                {contentOptions.feature6[1] &&
                  contentOptions.feature6[1].field[contentOptions.currentLang]}
              </p>
            ) : (
              ""
            )}
            {contentOptions.optNoCTA && contentOptions.optNoCTA.checkbox ? (
              <div className="s-featureBox__list__item__cta">
                <E_ArrowCTA
                  copy={
                    contentOptions.feature6[2] &&
                    contentOptions.feature6[2].field[contentOptions.currentLang]
                  }
                />
              </div>
            ) : (
              ""
            )}
          </li>
        ) : (
          ""
        )}
      </ul>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "feature-box",
  component: S_featureBox,
  navtxt: "Feature Box",
  htmlName: "featureBox",
  categoryType: ["landing page", "web"],
};
